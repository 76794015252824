import { useQuery } from '@apollo/react-hooks';
import { CLIENT_NAME } from './config';

const useCCEQuery: any = (query: any, options: any) => {
  const response = useQuery(query, {
    ...options,
    context: { clientName: CLIENT_NAME, ...options?.context },
  });

  return response;
};

export default useCCEQuery;
