import React from 'react';
import { Block, ExtendCSS } from 'vcc-ui';
import { ComponentRendering } from '@vcc-www/api/sitecore9/SitecoreTypes';

const WarningComponent: React.FC<ComponentRendering> = (props) => (
  <Block as="pre" extend={containerCSS}>
    {JSON.stringify(props, null, 2)}
  </Block>
);

export default WarningComponent;

const containerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  border: '1px solid red',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-all',
  padding: baselineGrid,
  margin: `${baselineGrid}px auto`,
  maxWidth: 900,
});
