import React from 'react';
import SVG from './svg';

type Props = {
  strokeWidth?: number;
};

const Tick: React.FC<React.PropsWithChildren<Props>> = ({
  strokeWidth = 2,
}) => (
  <SVG viewBox="0 0 26 28">
    <path
      d="M4.04457 16.11L8.95738 21.6123"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="square"
    />
    <path
      d="M8.95729 21.6125L21.5992 7.45354"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="square"
    />
  </SVG>
);

export default Tick;
