import { useClientConfigurationQuery } from './useClientConfigurationQuery';
import {
  useStartingPointQuery,
  PriceConfigInput,
} from './useStartingPointQuery';

type Props = {
  routeModelId?: string;
  cmsModelId?: string;
  market?: string;
  onlineSalesEnabled: string;
  isMy23?: boolean;
};

export const useCceBaseData = ({
  routeModelId = '',
  cmsModelId = '',
  market,
  onlineSalesEnabled,
  isMy23,
}: Props) => {
  const { locale, salesModelSettings } = useClientConfigurationQuery({
    market,
  });

  const policy = getPolicyFromSalesModelSettings(
    salesModelSettings,
    onlineSalesEnabled,
  );

  const shouldHavePrices = market !== 'intl';

  const priceConfigInput = shouldHavePrices
    ? (getPriceConfigInput(onlineSalesEnabled) as PriceConfigInput)
    : null;

  const { startingPoint, loading: loadingStartingPoint } =
    useStartingPointQuery({
      locale,
      policy,
      ...parseModelId(routeModelId, cmsModelId, isMy23),
      priceConfigInput,
    });

  const modelsWithVersionFamilies = startingPoint?.children?.[0]?.model.filter(
    (model: any) => model.salesVersionFamilies.length > 0,
  );
  const model =
    modelsWithVersionFamilies?.find(
      (model: any) => model.id === routeModelId,
    ) ||
    modelsWithVersionFamilies?.[0] ||
    {};

  return { model, locale, shouldHavePrices, loading: loadingStartingPoint };
};

export const getPolicyFromSalesModelSettings = (
  salesModelSettings: any[],
  onlineSalesEnabled: string,
) => {
  const onlineSalesEnabledToSalesModelSettingsMap = {
    none: 'default',
    cbv: 'sub',
    cbvFixed: 'sub_fixed',
    sts: 'cash',
  };
  const { policy } =
    salesModelSettings?.find(
      ({ salesModel }) =>
        salesModel ===
        onlineSalesEnabledToSalesModelSettingsMap[
          onlineSalesEnabled as keyof typeof onlineSalesEnabledToSalesModelSettingsMap
        ],
    ) ||
    salesModelSettings?.find(
      (salesModelSetting) => salesModelSetting.salesModel === 'default',
    ) ||
    {};

  return policy;
};

// TODO: We should be sharing this with the car-config app
const MODEL_VARIANTS = {
  url: {
    hybrid: 'hybrid',
    electric: 'electric',
  },
  cce: {
    hybrid: 'recharge',
    phev: 'phev',
    electric: 'recharge-pure-electric',
    hybridElectric: 'hybrid-pure-electric',
  },
  special: {
    crossCountry: 'cross-country',
    polestarEngineered: 'polestar-engineered',
  },
};

export const parseModelId = (
  routeModelId: string,
  cmsModelId: string,
  isMy23?: boolean,
) => {
  if (!routeModelId) {
    return {
      modelFamily: null,
      modelId: null,
      salesVersion: null,
    };
  }
  const [modelFamily, ...modelVersionParts] = routeModelId.split('-');
  const modelVersion = modelVersionParts.join('-');
  let parsedModelId = null as string | null;
  let salesVersion = null as string | null;
  switch (modelVersion) {
    case '':
      parsedModelId = `${modelFamily}`;
      break;
    case MODEL_VARIANTS.url.hybrid:
      parsedModelId = isMy23
        ? `${modelFamily}-${MODEL_VARIANTS.cce.phev}`
        : `${modelFamily}-${MODEL_VARIANTS.cce.hybrid}`;
      break;
    case MODEL_VARIANTS.url.electric:
      salesVersion = `${modelFamily}-${MODEL_VARIANTS.cce.electric}`;
      break;
    default:
      salesVersion = `${modelFamily}-${modelVersion}`;
      break;
  }

  // In order to get the data for MY23 we need to remove the salesVersion filter - see CEP-944 (https://volvocars.atlassian.net/browse/CEP-944)
  if (isMy23) {
    salesVersion = null;
  }

  return {
    modelFamily,
    modelId: cmsModelId || parsedModelId,
    salesVersion,
  };
};

export const getPriceConfigInput = (onlineSalesEnabled: string) => {
  if (onlineSalesEnabled === 'none') {
    return {
      name: 'default',
      variableReferences: [],
    };
  }

  if (onlineSalesEnabled === 'cbv') {
    return {
      name: 'sub',
      variableReferences: [
        {
          name: 'i',
          value: '',
        },
        { name: 'customerType', value: 'b2c' },
      ],
    };
  }

  return {
    name: 'cash',
    variableReferences: [
      {
        name: 'i',
        value: '',
      },
      {
        name: 'contractLength',
        value: '',
      },
    ],
  };
};
