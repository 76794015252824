import React from 'react';
import SVG from './svg';

const ChargingDcJp: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SVG viewBox="0 0 48 48" fill="none">
      <rect width="47.9999" height="48" fill="none" />
      <path
        d="M24.1842 42.672C34.3109 42.672 42.5202 34.4627 42.5202 24.336C42.5202 14.2093 34.3109 6 24.1842 6C14.0575 6 5.84814 14.2093 5.84814 24.336C5.84814 34.4627 14.0575 42.672 24.1842 42.672Z"
        stroke="#707070"
      />
      <path
        d="M24.3195 39.1986C32.5359 39.1986 39.1966 32.5379 39.1966 24.3215C39.1966 16.105 32.5359 9.44434 24.3195 9.44434C16.1031 9.44434 9.44238 16.105 9.44238 24.3215C9.44238 32.5379 16.1031 39.1986 24.3195 39.1986Z"
        stroke="#707070"
      />
      <path
        d="M17.9853 27.8781C19.9741 27.8781 21.5864 26.2659 21.5864 24.2771C21.5864 22.2883 19.9741 20.676 17.9853 20.676C15.9965 20.676 14.3843 22.2883 14.3843 24.2771C14.3843 26.2659 15.9965 27.8781 17.9853 27.8781Z"
        stroke="#707070"
      />
      <path
        d="M30.5298 27.8786C32.5186 27.8786 34.1308 26.2663 34.1308 24.2775C34.1308 22.2887 32.5186 20.6765 30.5298 20.6765C28.541 20.6765 26.9287 22.2887 26.9287 24.2775C26.9287 26.2663 28.541 27.8786 30.5298 27.8786Z"
        stroke="#707070"
      />
      <line
        x1="22.5254"
        y1="29.9699"
        x2="26.4435"
        y2="33.888"
        stroke="#707070"
      />
      <line
        y1="-0.5"
        x2="5.54094"
        y2="-0.5"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 26.3892 30.323)"
        stroke="#707070"
      />
      <line
        x1="22.5274"
        y1="14.0329"
        x2="26.4454"
        y2="17.951"
        stroke="#707070"
      />
      <line
        y1="-0.5"
        x2="5.54094"
        y2="-0.5"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 26.3901 14.386)"
        stroke="#707070"
      />
    </SVG>
  );
};

export default ChargingDcJp;
