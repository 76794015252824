import { Agent } from 'https';
import { HttpLink } from 'apollo-link-http';
import { SiteSlug, isValidSiteSlug } from '@volvo-cars/market-sites';
import { getSitecoreLanguage } from './getSitecoreLanguage';

export function createSitecore9Link({
  endpoint,
  siteSlug,
  operationId,
  headers,
  fetchOptions,
}: {
  endpoint?: URL;
  siteSlug?: SiteSlug;
  operationId: string;
  headers?: Record<string, string | undefined>;
  fetchOptions?: { agent?: Agent };
}) {
  if (!endpoint) {
    return new HttpLink({
      uri:
        typeof window !== 'undefined'
          ? window.location.origin
          : 'http://localhost',
      fetch: () =>
        Promise.reject(new Error(`No Sitecore 9 endpoint configured`)),
    });
  }

  const url = new URL(endpoint.href);
  if (isValidSiteSlug(siteSlug)) {
    const sitecoreLanguage = getSitecoreLanguage(siteSlug);
    url.searchParams.set('sc_site', siteSlug);
    url.searchParams.set('sc_lang', sitecoreLanguage);
    if (typeof window !== 'undefined') {
      url.searchParams.set('origin', window.location.origin);
    }
  }
  return new HttpLink({
    uri: url.href,
    credentials: 'include',
    headers: {
      'VCC-Api-OperationId': operationId,
      ...headers,
    },
    ...(fetchOptions && { fetchOptions }),
    fetch: url.searchParams.has('sc_site')
      ? fetch
      : () =>
          Promise.reject(
            new TypeError(`Invalid Sitecore GraphQL endpoint ${url.href}`),
          ),
  });
}
