import React from 'react';
import SVG from './svg';

const Chat: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24">
    <path
      d="M13 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM17 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM9 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      fill="currentColor"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 16.951v3.845L9.34 17H18a5 5 0 0 0 5-5V8a5 5 0 0 0-5-5H6a5 5 0 0 0-5 5v4a5.003 5.003 0 0 0 3.333 4.716L5 16.95ZM4 23v-5.341A6.002 6.002 0 0 1 0 12V8a6 6 0 0 1 6-6h12a6 6 0 0 1 6 6v4a6 6 0 0 1-6 6H9.714L4 23Z"
    />
  </SVG>
);

export default Chat;
