import React from 'react';
import SVG from './svg';

const Check: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 12 10">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.35067 8.56536L1.316 4.76536L1 5.0067L4.34667 9.20403L10.696 1.0467L10.384 0.79603L4.35067 8.56536Z"
      stroke="currentColor"
    />
  </SVG>
);

export default Check;
