import React from 'react';
import SVG from './svg';

const Wechat: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18,13.67a.8.8,0,1,1,.81-.8.8.8,0,0,1-.81.8m-4,0a.8.8,0,1,1,.8-.8.8.8,0,0,1-.8.8m6,4.6A4.72,4.72,0,0,0,22,14.48c0-2.78-2.7-5-6-5s-6,2.24-6,5,2.7,5,6,5a7,7,0,0,0,2-.28.57.57,0,0,1,.18,0,.63.63,0,0,1,.32.1l1.32.76a.21.21,0,0,0,.11,0,.2.2,0,0,0,.2-.21.89.89,0,0,0,0-.14l-.27-1a.69.69,0,0,1,0-.13.4.4,0,0,1,.17-.33"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.64,9a1,1,0,1,1,1-1,1,1,0,0,1-1,1M6.82,9a1,1,0,1,1,1-1,1,1,0,0,1-1,1M9.23,3.91c-4,0-7.23,2.7-7.23,6a5.65,5.65,0,0,0,2.5,4.55.49.49,0,0,1,.2.39.41.41,0,0,1,0,.16l-.32,1.21a.58.58,0,0,0,0,.18.24.24,0,0,0,.24.24.24.24,0,0,0,.14-.05l1.58-.91a.75.75,0,0,1,.6-.08A8.62,8.62,0,0,0,9.23,16h.4a4.54,4.54,0,0,1-.25-1.47c0-3,2.95-5.5,6.6-5.5h.39c-.55-2.88-3.53-5.08-7.14-5.08"
      clipRule="evenodd"
    />
  </SVG>
);

export default Wechat;
