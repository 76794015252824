import React from 'react';
import SVG from './svg';

type Props = {
  strokeWidth?: number;
};

const Minus: React.FC<React.PropsWithChildren<Props>> = ({
  strokeWidth = 2,
}) => (
  <SVG viewBox="0 0 24 24">
    <line
      x1="0"
      y1="12"
      x2="24"
      y2="12"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
    />
  </SVG>
);

export default Minus;
