'use client';

import { useEffect, useState, useRef, useCallback } from 'react';
import { usePrevious } from '@volvo-cars/react-utils';

/**
 * Returns a bool whether or not the element has been scrolled down or not
 *
 *  - `true`
 *  - `false`
 */
export function useHasScrolledDown({
  disabled,
  offsetThreshold = 0,
  scrollElement,
}: {
  disabled?: boolean;
  offsetThreshold?: number;
  scrollElement: HTMLDivElement | null;
}) {
  const [hasScrolledDown, setHasScrolledDown] = useState<boolean>(false);
  const prevScrollElement = usePrevious(scrollElement);
  const scrollTicking = useRef(false);

  const updateScrollDirection = useCallback(() => {
    scrollTicking.current = false;
    if (!scrollElement) {
      return;
    }
    const scrollY = scrollElement.scrollTop;

    if (scrollY < offsetThreshold) {
      setHasScrolledDown(false);
      return;
    }

    setHasScrolledDown(true);
  }, [offsetThreshold, scrollElement]);

  const handleScroll = useCallback(() => {
    if (!scrollTicking.current) {
      scrollTicking.current = true;
      window.requestAnimationFrame(updateScrollDirection);
    }
  }, [updateScrollDirection]);

  useEffect(() => {
    if (disabled) return;

    if (scrollElement) {
      prevScrollElement &&
        //@ts-ignore
        prevScrollElement.removeEventListener('scroll', handleScroll);

      scrollElement.removeEventListener('scroll', handleScroll);
      scrollElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      scrollElement &&
        scrollElement.removeEventListener('scroll', handleScroll);
    };
  }, [disabled, scrollElement, handleScroll]); // eslint-disable-line

  // Reset scroll state if hook gets disabled
  useEffect(() => {
    if (disabled) {
      setHasScrolledDown(false);
    }
  }, [disabled]);

  return hasScrolledDown;
}
