import { useMemo } from 'react';
import gql from 'graphql-tag';
import useQuery from './useQuery';
import { getCceUrl } from './config';

// If you edit this query, run `yarn apollo-codegen` to update types
export const CLIENT_CONFIGURATION_QUERY = gql`
  query ClientConfigurationQuery($url: String) {
    clientConfigurationQuery(PARAMETERS: [{ key: "url", value: $url }]) {
      basePath
      configuration {
        locale
        policy
        salesModelSettings {
          salesModel
          policy
        }
        priceConfigs {
          name
          priceConfigInput {
            name
            variableReferences {
              name
              value
            }
          }
        }
      }
    }
  }
`;

export const useClientConfigurationQuery = ({
  market,
}: {
  market?: string;
}) => {
  const { data, loading, error } = useQuery(CLIENT_CONFIGURATION_QUERY, {
    variables: {
      url: `${getCceUrl()}/${market}/all`,
    },
    skip: !market,
    fetchPolicy: 'no-cache', // to force reload in client
  });

  const { configuration } = data?.clientConfigurationQuery || {};

  return useMemo(
    () => ({
      ...configuration,
      loading,
      error,
    }),
    [configuration, loading, error],
  );
};
