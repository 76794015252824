import React from 'react';
import SVG from './svg';

const Youtube: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.522 15.635V8.844l6.484 3.407-6.484 3.384zM23.76 7.667s-.235-1.665-.954-2.399c-.913-.963-1.936-.967-2.405-1.023C17.043 4 12.005 4 12.005 4h-.01s-5.038 0-8.396.245c-.47.056-1.492.06-2.406 1.023-.719.734-.953 2.4-.953 2.4S0 9.622 0 11.577v1.835c0 1.956.24 3.912.24 3.912s.234 1.665.953 2.399c.914.962 2.113.932 2.647 1.033C5.76 20.943 12 21 12 21s5.043-.008 8.401-.252c.47-.057 1.492-.062 2.405-1.024.72-.734.954-2.4.954-2.4s.24-1.955.24-3.911v-1.834c0-1.956-.24-3.912-.24-3.912z"
      clipRule="evenodd"
    />
  </SVG>
);

export default Youtube;
