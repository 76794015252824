import React from 'react';
import { ExtendCSS, Inline } from 'vcc-ui';

const styles: { svg: (rotate?: number) => ExtendCSS } = {
  svg: (rotate) => {
    return {
      speak: `none`,
      display: `inline-block`,
      height: `inherit`,
      width: `inherit`,
      transform: `rotate(${rotate || 0}deg)`,
      transformOrigin: `center`,
    };
  },
};

type Props = {
  viewBox?: string;
  fill?: string;
  rotate?: number;
  preserveAspectRatio?: string;
};

const SVG: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  viewBox,
  rotate,
  fill,
  preserveAspectRatio,
}) => (
  <Inline
    as="svg"
    fill={fill}
    extend={styles.svg(rotate)}
    viewBox={viewBox}
    preserveAspectRatio={preserveAspectRatio}
  >
    {children}
  </Inline>
);

export default SVG;
