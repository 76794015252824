import React from 'react';
import SVG from './svg';

type Props = {
  strokeWidth?: number;
};

const Close: React.FC<React.PropsWithChildren<Props>> = ({
  strokeWidth = 2,
}) => (
  <SVG viewBox="0 0 38 38">
    <circle
      cx="19"
      cy="19"
      r="18"
      fill="white"
      stroke="currentColor"
      strokeWidth={strokeWidth}
    />
    <path
      d="M12.6665 12.667L25.3332 25.3337"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
    />
    <path
      d="M12.6665 25.333L25.3332 12.6663"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
    />
  </SVG>
);

export default Close;
