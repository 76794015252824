import React from 'react';
import SVG from './svg';

const ChargingDcNa: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SVG viewBox="0 0 48 48" fill="none">
      <rect width="47.9999" height="48" fill="none" />
      <path
        d="M16.4502 32.3018H32.37C35.8063 32.3018 38.5898 35.0852 38.5898 38.5215C38.5898 41.9578 35.8063 44.7412 32.37 44.7412H16.4502C13.0139 44.7412 10.2305 41.9578 10.2305 38.5215C10.2345 35.0852 13.0179 32.3018 16.4502 32.3018Z"
        stroke="#707070"
      />
      <path
        d="M17.0135 41.6679C18.7374 41.6679 20.1349 40.2704 20.1349 38.5465C20.1349 36.8226 18.7374 35.425 17.0135 35.425C15.2896 35.425 13.8921 36.8226 13.8921 38.5465C13.8921 40.2704 15.2896 41.6679 17.0135 41.6679Z"
        stroke="#707070"
      />
      <path
        d="M31.8739 41.6676C33.5978 41.6676 34.9953 40.2701 34.9953 38.5462C34.9953 36.8223 33.5978 35.4248 31.8739 35.4248C30.1499 35.4248 28.7524 36.8223 28.7524 38.5462C28.7524 40.2701 30.1499 41.6676 31.8739 41.6676Z"
        stroke="#707070"
      />
      <path
        d="M24.3736 24.8817C30.2815 24.8817 35.0709 20.0923 35.0709 14.1844C35.0709 8.27641 30.2815 3.48706 24.3736 3.48706C18.4656 3.48706 13.6763 8.27641 13.6763 14.1844C13.6763 20.0923 18.4656 24.8817 24.3736 24.8817Z"
        stroke="#707070"
      />
      <path
        d="M19.5101 11.9873C20.6503 11.9873 21.5745 11.0631 21.5745 9.92298C21.5745 8.78288 20.6503 7.85864 19.5101 7.85864C18.37 7.85864 17.4458 8.78288 17.4458 9.92298C17.4458 11.0631 18.37 11.9873 19.5101 11.9873Z"
        stroke="#707070"
      />
      <path
        d="M29.0222 11.987C30.1622 11.987 31.0864 11.0629 31.0864 9.92284C31.0864 8.78281 30.1622 7.85864 29.0222 7.85864C27.8822 7.85864 26.958 8.78281 26.958 9.92284C26.958 11.0629 27.8822 11.987 29.0222 11.987Z"
        stroke="#707070"
      />
      <path
        d="M24.3552 22.4321C25.4952 22.4321 26.4194 21.5079 26.4194 20.3679C26.4194 19.2279 25.4952 18.3037 24.3552 18.3037C23.2152 18.3037 22.291 19.2279 22.291 20.3679C22.291 21.5079 23.2152 22.4321 24.3552 22.4321Z"
        stroke="#707070"
      />
      <path
        d="M18.0961 18.3133C18.8123 18.3133 19.3928 17.7327 19.3928 17.0165C19.3928 16.3003 18.8123 15.7197 18.0961 15.7197C17.3799 15.7197 16.7993 16.3003 16.7993 17.0165C16.7993 17.7327 17.3799 18.3133 18.0961 18.3133Z"
        stroke="#707070"
      />
      <path
        d="M30.5961 18.3128C31.3123 18.3128 31.8928 17.7322 31.8928 17.016C31.8928 16.2998 31.3123 15.7192 30.5961 15.7192C29.8799 15.7192 29.2993 16.2998 29.2993 17.016C29.2993 17.7322 29.8799 18.3128 30.5961 18.3128Z"
        stroke="#707070"
      />
      <path
        d="M26.7053 27.1651L26.6178 26.6728L26.2053 26.7461V27.1651H26.7053ZM22.0751 27.1674H22.5751V26.7479L22.1621 26.675L22.0751 27.1674ZM22.0751 29.8251V30.3251H22.5751V29.8251H22.0751ZM26.7053 29.8251H26.2053V30.3251H26.7053V29.8251ZM26.7927 27.6574C33.2017 26.5189 38.0682 20.9205 38.0682 14.1844H37.0682C37.0682 20.4272 32.5578 25.6176 26.6178 26.6728L26.7927 27.6574ZM38.0682 14.1844C38.0682 6.62672 31.9415 0.5 24.3838 0.5V1.5C31.3892 1.5 37.0682 7.179 37.0682 14.1844H38.0682ZM24.3838 0.5C16.8261 0.5 10.6994 6.62672 10.6994 14.1844H11.6994C11.6994 7.179 17.3784 1.5 24.3838 1.5V0.5ZM10.6994 14.1844C10.6994 20.9251 15.5726 26.5266 21.9881 27.6597L22.1621 26.675C16.2161 25.6248 11.6994 20.4315 11.6994 14.1844H10.6994ZM21.5751 27.1674V29.8251H22.5751V27.1674H21.5751ZM22.0751 29.3251H15.722V30.3251H22.0751V29.3251ZM15.722 29.3251C10.6288 29.3251 6.5 33.4539 6.5 38.547H7.5C7.5 34.0062 11.1811 30.3251 15.722 30.3251V29.3251ZM6.5 38.547C6.5 43.6402 10.6288 47.769 15.722 47.769V46.769C11.1811 46.769 7.5 43.0879 7.5 38.547H6.5ZM15.722 47.769H33.0582V46.769H15.722V47.769ZM33.0582 47.769C38.1514 47.769 42.2802 43.6402 42.2802 38.547H41.2802C41.2802 43.0879 37.5991 46.769 33.0582 46.769V47.769ZM42.2802 38.547C42.2802 33.4539 38.1514 29.3251 33.0583 29.3251V30.3251C37.5991 30.3251 41.2802 34.0062 41.2802 38.547H42.2802ZM33.0583 29.3251H26.7053V30.3251H33.0583V29.3251ZM27.2053 29.8251V27.1651H26.2053V29.8251H27.2053Z"
        fill="#707070"
      />
    </SVG>
  );
};

export default ChargingDcNa;
