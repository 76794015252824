import React from 'react';
import { Flex, ExtendCSS, Block, useTheme } from 'vcc-ui';
import {
  getMediaQueryValues,
  ResponsiveValue,
} from '@vcc-www/utils/getMediaQueryValues';
import { getMaxWidth } from './GridHelper.utils';

type Props = {
  /**
   * Number of columns the GridHelper should span, see examples below for usage.
   *
   * Simple one value usage, will always span these many columns.
   * ```
   * size={8}
   * ```
   *
   * An array of 1 - 4 values, where the index represents breakpoints:
   * `[default, fromM, fromL, fromXL]`.
   * The `default` value is required.
   * ```
   * size={[6, 8, 10, 12]}
   * ```
   *
   * An object where we can add whatever breakpoints from VCC-UI we want to.
   * The `default` value is required.
   * ```
   * size={{ default: 6, untilM: 4, fromL: 12 }}
   * ```
   *
   * Max width is always 100%.
   */
  size: ResponsiveValue<number>;

  /**
   * Puts a baselineGrid * 3 margin on the sides in mobile.
   *
   * Default: `true`
   */
  marginsOnMobile?: boolean;
};

const GridHelper: React.FC<React.PropsWithChildren<Props>> = ({
  size,
  marginsOnMobile = true,
  children,
}) => {
  const { baselineGrid } = useTheme();
  const breakpoitedMaxWidthCSS = getMediaQueryValues(size, (size) => ({
    maxWidth: getMaxWidth(size, baselineGrid),
  }));

  return (
    <Flex>
      <Block extend={[rootCSS(marginsOnMobile), ...breakpoitedMaxWidthCSS]}>
        {children}
      </Block>
    </Flex>
  );
};

export default GridHelper;

const rootCSS =
  (marginsOnMobile: boolean): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    margin: '0 auto',
    width: '100%',
    ...(marginsOnMobile && {
      untilM: {
        paddingLeft: baselineGrid * 3,
        paddingRight: baselineGrid * 3,
      },
    }),
  });
