import React from 'react';
import SVG from './svg';

const City: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SVG viewBox="0 0 32 32" fill="none">
      <path
        d="M30.4004 4.8V27.2H31.2004V4H18.4004V14.4H19.2004V4.8H30.4004Z"
        fill="#707070"
      />
      <path
        d="M12.7998 12.4L7.1998 8.19995L1.5998 12.4V27.2H0.799805V12L7.1998 7.19995L13.5998 12V27.2H12.7998V12.4Z"
        fill="#707070"
      />
      <path
        d="M15.2002 16.8V16H24.0002V27.2H23.2002V16.8H15.2002Z"
        fill="#707070"
      />
    </SVG>
  );
};

export default City;
