import React from 'react';
import SVG from './svg';

type Props = {
  strokeWidth: number;
  rotate: number;
};

const Chevron: React.FC<React.PropsWithChildren<Props>> = ({
  strokeWidth,
  rotate,
}) => {
  return (
    <SVG
      rotate={rotate}
      fill="none"
      viewBox="0 0 24 24"
      preserveAspectRatio="xMinYMid"
    >
      <path
        d="M5 9l7 7 7 -7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        data-autoid={`chevron:${rotate || 0}`}
        strokeWidth={strokeWidth}
      />
    </SVG>
  );
};

export default Chevron;
