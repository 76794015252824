import React from 'react';
import SVG from './svg';

const Charging: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SVG viewBox="0 0 9 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.24011 14.9734L4.69011 9.37544H1.27344L5.14011 1.02344L4.69011 6.87074L8.47344 6.87074L4.24011 14.9734Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

export default Charging;
