import React from 'react';
import SVG from './svg';

type Props = {
  rotate: number;
};

const FuelTypeElectric: React.FC<React.PropsWithChildren<Props>> = ({
  rotate,
}) => (
  <SVG viewBox="0 0 24 24" rotate={rotate}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.8457H9V6.8457H10V3.8457ZM8 3.8457V6.8457H7H6V7.8457V10.8457C6 13.0548 7.79086 14.8457 10 14.8457V15.8457V16.8457H11V23.8457H12V16.8457H13V23.8457H14V16.8457H15V15.8457V14.8457C17.2091 14.8457 19 13.0548 19 10.8457V7.8457V6.8457H18H17V3.8457C17 3.29342 16.5523 2.8457 16 2.8457H15C14.4477 2.8457 14 3.29342 14 3.8457V6.8457H11V3.8457C11 3.29342 10.5523 2.8457 10 2.8457H9C8.44772 2.8457 8 3.29342 8 3.8457ZM11 15.8457H11.5H13.5H14V14.8457H11V15.8457ZM11 13.8457H10C8.34315 13.8457 7 12.5026 7 10.8457V7.8457H8H9H10H11H14H15H16H17H18V10.8457C18 12.5026 16.6569 13.8457 15 13.8457H14H11ZM15 6.8457H16V3.8457H15V6.8457Z"
      fill="currentColor"
    />
  </SVG>
);

export default FuelTypeElectric;
