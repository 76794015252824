import React from 'react';
import SVG from './svg';

const CarFront: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9999 8.66759H12.9999V8.00092H10.9999V8.66759ZM2.99992 8.66759H4.99992V8.00092H2.99992V8.66759ZM14.6666 5.66759H13.3333V6.26092L12.2999 4.26092C11.9447 3.67841 11.3088 3.32625 10.6266 3.33425H5.38659C4.70841 3.31518 4.07118 3.65791 3.71325 4.23425L2.66659 6.11425V5.66759H1.33325V6.33425H2.53992L2.30659 6.76092L2.20659 6.94759C2.0181 7.40865 1.90554 7.8972 1.87325 8.39425C1.87325 8.55425 1.33992 8.96759 1.33992 9.10092V13.3343H3.99992V12.1609L5.66659 12.0009H10.2533L12.0066 12.2143L11.9999 13.3343H14.6666V9.10092C14.6666 8.96759 14.1866 8.56092 14.1599 8.43425C14.0901 7.92598 13.9369 7.43269 13.7066 6.97425L13.6066 6.78759L13.3933 6.36092H14.6666V5.66759ZM13.4799 8.50092C13.5438 8.77568 13.6932 9.0231 13.9066 9.20759L13.9799 9.28759V12.6676H12.6666V11.6676L10.3399 11.3343H5.57992L3.33325 11.6476V12.6676H1.99992V9.29425L2.07992 9.21425C2.3056 9.0302 2.46483 8.77732 2.53325 8.49425L2.56659 8.26759C2.59287 7.92562 2.66693 7.58901 2.78659 7.26759L2.89325 7.08092L4.29325 4.55425C4.53743 4.19308 4.95096 3.98379 5.38659 4.00092H10.6266C11.0611 3.98884 11.4723 4.19697 11.7199 4.55425L12.9933 7.06092L13.1066 7.26759C13.2975 7.65556 13.4236 8.07218 13.4799 8.50092ZM10.3066 8.00092C10.1666 8.00092 8.55992 8.00092 8.07992 8.00092H7.99992C7.52659 8.00092 5.83325 8.00092 5.69325 8.00092C5.55325 8.00092 5.33325 8.00092 5.33325 8.30759C5.33325 8.61425 5.39992 9.06759 5.39992 9.06759C5.39992 9.06759 5.39992 9.52092 5.81325 9.55425C6.22659 9.58759 7.20659 9.66759 7.89992 9.66759H8.23325C8.89992 9.66759 9.82659 9.58092 10.2333 9.55425C10.6399 9.52759 10.6466 9.06759 10.6466 9.06759C10.6466 9.06759 10.7066 8.62759 10.7133 8.30759C10.7199 7.98759 10.4866 8.00092 10.3066 8.00092Z"
      fill="currentColor"
    />
  </SVG>
);

export default CarFront;
