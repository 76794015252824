import React from 'react';
import SVG from './svg';

const Linkedin: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.52,2h-17A1.45,1.45,0,0,0,2,3.43V20.57A1.45,1.45,0,0,0,3.48,22h17A1.45,1.45,0,0,0,22,20.57V3.43A1.45,1.45,0,0,0,20.52,2ZM5.09,18.74v-9h3v9ZM6.58,8.5h0a1.56,1.56,0,1,1,0-3.11,1.56,1.56,0,1,1,0,3.11ZM18.87,18.74h-3V13.92c0-1.21-.44-2-1.52-2A1.62,1.62,0,0,0,12.83,13a2,2,0,0,0-.1.73v5h-3s0-8.16,0-9h3V11a3,3,0,0,1,2.69-1.49c2,0,3.45,1.29,3.45,4.05Z"
      clipRule="evenodd"
    />
  </SVG>
);

export default Linkedin;
