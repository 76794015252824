import React from 'react';
import SVG from './svg';

const Close: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24">
    <path
      stroke="currentColor"
      d="M4.222 4.222l15.556 15.556M4.222 19.778L19.778 4.222"
    />
  </SVG>
);

export default Close;
