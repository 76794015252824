import React from 'react';
import { ExtendCSS, Block, ThemePicker } from 'vcc-ui';
import { maxContentWidth12Columns } from '@vcc-www/constants/layout';
import { calculateSpacing, DesktopSpacings } from './calculateSpacing';
import { Params, backgroundColors } from './params';

type Props = Params &
  DesktopSpacings & {
    extend?: ExtendCSS;
    children: React.ReactNode;
    sectionId?: string; // This will override defult values of id, data-component and data-autoid attributes byt the provided one
  };

type BackgroundColors = (typeof backgroundColors)[number];

const themeMap: Record<BackgroundColors, 'light' | 'dark'> = {
  white: 'light',
  light: 'light',
  dark: 'dark',
  black: 'dark',
};

const SectionContained = React.forwardRef<HTMLElement, Props>(
  (
    {
      topOuterSpacing = 'none',
      bottomOuterSpacing = 'none',
      topInnerSpacing = 'medium',
      bottomInnerSpacing = 'medium',
      topInnerSpacingDesktop,
      topOuterSpacingDesktop,
      bottomInnerSpacingDesktop,
      bottomOuterSpacingDesktop,
      background = 'white',
      marginsOnMobile = false,
      children,
      extend,
      sectionId,
      ...props
    },
    ref,
  ) => {
    const dataAttributes = sectionId
      ? { id: sectionId, 'data-component': sectionId, 'data-autoid': sectionId }
      : {};
    return (
      <ThemePicker variant={themeMap[background]}>
        <Block
          as="section"
          extend={[
            rootCSS({
              topOuterSpacing,
              bottomOuterSpacing,
              topInnerSpacing,
              bottomInnerSpacing,
              topInnerSpacingDesktop,
              topOuterSpacingDesktop,
              bottomInnerSpacingDesktop,
              bottomOuterSpacingDesktop,
              background,
              // I don't get a proper bool from the data, i get a string of "0 | 1 | true | false"
              // so to test that I do the following
              marginsOnMobile: /(1|true)/.test(marginsOnMobile + ''),
            }),
            extend,
          ]}
          {...props}
          {...dataAttributes}
          ref={ref}
        >
          {children}
        </Block>
      </ThemePicker>
    );
  },
);

SectionContained.displayName = 'SectionContained';

export default SectionContained;
const rootCSS =
  ({
    background,
    marginsOnMobile,
    ...params
  }: Required<Params> & DesktopSpacings): ExtendCSS =>
  ({ theme: { baselineGrid, color } }) => ({
    backgroundColor: {
      // We automatically switch to dark theme if background is `black` or `dark`
      white: color.background.primary,
      black: color.background.primary,
      light: color.background.secondary,
      dark: color.background.secondary,
      transparent: 'transparent',
    }[background],
    ...calculateSpacing({ baselineGrid, params }).untilM,

    maxWidth: maxContentWidth12Columns,

    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    extend: {
      condition: marginsOnMobile,
      style: {
        width: `calc(100% - ${baselineGrid * 3 * 2}px)`,
        marginLeft: baselineGrid * 3,
        marginRight: baselineGrid * 3,
      },
    },

    fromM: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: `calc(100% - ${baselineGrid * 6}px)`,
      ...calculateSpacing({ baselineGrid, params }).fromM,
    },
  });
