import React from 'react';
import SVG from './svg';

const MagnifyingGlass = () => {
  return (
    <SVG viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 5C7.91015 5 5 7.91015 5 11.5C5 15.0899 7.91015 18 11.5 18C15.0899 18 18 15.0899 18 11.5C18 7.91015 15.0899 5 11.5 5ZM4 11.5C4 7.35786 7.35786 4 11.5 4C15.6421 4 19 7.35786 19 11.5C19 13.1354 18.4766 14.6485 17.5881 15.881L20.8536 19.1464L20.1464 19.8536L16.9477 16.6548C15.5807 18.0991 13.6456 19 11.5 19C7.35786 19 4 15.6421 4 11.5Z"
        fill="currentColor"
      />
    </SVG>
  );
};
export default MagnifyingGlass;
