import React from 'react';
import escape from 'lodash/escape';
import memoize from 'lodash/memoize';
import { cssJoin } from '@volvo-cars/css/utils';
import styles from './markdown.module.css';
import { marked, Tokenizer } from 'marked';
import { TrackLinks, GA3Strict } from '@vcc-www/rich-text';

const ruleList = [
  'code',
  'blockquote',
  'html',
  'heading',
  'hr',
  'list',
  'listitem',
  'paragraph',
  'table',
  'tablerow',
  'tablecell',
  'strong',
  'em',
  'codespan',
  'br',
  'del',
  'link',
  'image',
  'text',
  'checkbox',
];

const markdownWhiteList = [
  'text',
  'em',
  'strong',
  'link',
  'code',
  'codespan',
  'table',
  'tablerow',
  'tablecell',
  'html',
  'heading',
  'blockquote',
];
const htmlWhiteList = /^(<sup>|<\/sup>|<sub>|<\/sub>|<br>|<\/br>)$/;

const originalRenderer = new marked.Renderer();
const originalLinkRenderer = originalRenderer.link;

const createTokenizer = memoize(
  function createTokenizer(extraWhiteList: string[] = []) {
    const blacklist = ruleList.filter(
      (item) => ![...markdownWhiteList, ...extraWhiteList].includes(item),
    );

    return blacklist.reduce((acc, rule) => {
      acc[rule as keyof typeof acc] = () => undefined;

      return acc;
    }, new Tokenizer());
  },
  (extraWhiteList) => JSON.stringify(extraWhiteList || ''),
);
const createRenderer = memoize(
  function createRenderer(
    internalLinkDomains: string[] = [
      'www.volvocars.com',
      'authoring.volvocars.com',
    ],
  ) {
    const renderer = new marked.Renderer();
    renderer.html = (html: string) => {
      if (htmlWhiteList.test(html)) {
        return html;
      }
      return escape(html);
    };

    renderer.link = (href: string, title: string, text: string) => {
      const aTag = originalLinkRenderer.call(
        originalRenderer,
        href,
        title,
        text,
      );
      try {
        const url = new URL(href);
        if (
          internalLinkDomains.some((internalLinkDomain) =>
            url.origin.endsWith(internalLinkDomain),
          )
        ) {
          return aTag;
        } else {
          return aTag.replace(/^<a /, '<a target="_blank" ');
        }
      } catch (error) {
        return aTag;
      }
    };
    return renderer;
  },
  (internalLinkDomains) => JSON.stringify(internalLinkDomains || ''),
);

const listRules = ['list', 'listitem', 'hr'];

export const parseMarkdown = memoize(
  (markdown: string, internalLinkDomains?: string[], enableLists?: boolean) => {
    const renderer = createRenderer(internalLinkDomains);

    const tokenizer = createTokenizer(enableLists ? listRules : []);

    marked.setOptions({ renderer, tokenizer, mangle: false, headerIds: false });

    return marked(markdown);
  },
  (markdown, internalLinkDomains, enableLists) =>
    JSON.stringify({
      a: markdown,
      b: internalLinkDomains || '',
      c: enableLists || false,
    }),
);
type Props = {
  markdown: string;
  className?: string;
  internalLinkDomains?: string[];
  enableLists?: boolean;
  enableTracking?: boolean;
  trackEventLabel?: ((linkText: string, linkUrl: string) => string) | string;
  trackEventAction?: string;
  trackEventCategory?: ((linkText: string, linkUrl: string) => string) | string;
  trackGA3?: { [name: string]: string | number | null } | GA3Strict;
};

const Markdown: React.FC<React.PropsWithChildren<Props>> = ({
  markdown,
  className,
  internalLinkDomains,
  enableTracking,
  trackEventLabel,
  trackEventAction,
  trackEventCategory,
  trackGA3,
  enableLists,
  ...props
}) => {
  const html = parseMarkdown(
    markdown,
    internalLinkDomains,
    enableLists,
  ).replace(/\\n/gm, '<br>');
  return enableTracking && html ? (
    <TrackLinks
      trackEventLabel={trackEventLabel}
      trackEventAction={trackEventAction}
      trackEventCategory={trackEventCategory}
      trackGA3={trackGA3}
    >
      <div
        className={cssJoin(styles.markdown, className)}
        dangerouslySetInnerHTML={{ __html: html }}
        {...props}
      />
    </TrackLinks>
  ) : (
    <div
      className={cssJoin(styles.markdown, className)}
      dangerouslySetInnerHTML={{ __html: html }}
      {...props}
    />
  );
};

export default Markdown;
