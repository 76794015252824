import React from 'react';
import SVG from './svg';

type Props = {
  strokeWidth?: number;
};

const EmailIcon: React.FC<React.PropsWithChildren<Props>> = ({
  strokeWidth = 1,
}) => (
  <SVG viewBox="0 0 27 20">
    <g stroke="none" strokeWidth={strokeWidth} fill="none" fillRule="evenodd">
      <g
        transform="translate(-298.000000, -15.000000)"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      >
        <g transform="translate(295.000000, 9.000000)">
          <path d="M26.1779262,24.0632088 L6.0724827,24.0632088 C5.03690128,24.0632088 4.19734062,23.2236482 4.19734062,22.1884366 L4.19734062,9.72706341 C4.19734062,8.69148199 5.03690128,7.85229118 6.0724827,7.85229118 L26.1779262,7.85229118 C27.2135076,7.85229118 28.0526984,8.69148199 28.0526984,9.72706341 L28.0526984,22.1884366 C28.0526984,23.2236482 27.2135076,24.0632088 26.1779262,24.0632088 Z" />
          <path d="M4.38237683,10.0926606 L14.7400403,18.5470734 C15.569615,19.2242697 16.6055663,19.2242697 17.435141,18.5470734 L28.0528094,9.82932708" />
          <path d="M22.1352012,16.6049884 L28.0528094,22.481913" />
          <path d="M10.299985,16.6049884 L4.38237683,22.481913" />
        </g>
      </g>
    </g>
  </SVG>
);

export default EmailIcon;
