import { CAR_PRICE_VARIANT } from '../car-price.constants';
import { mapPrices } from './car-price.utils.map-prices';
import {
  getContentByVariant,
  getLabelAndSourcesByCarPriceVariant,
  getSavingLabelAndSources,
  getVATLabelAndSources,
  normaliseVariant,
} from './car-price.utils';
import { TranslatePricesProps } from '../car-price.types';
import { getCarPriceElement } from './car-price.utils.car-price-string';

export const getTranslatedPrices = ({
  settings,
  prices,
  edition,
  modelYear,
  variant: variantInput = CAR_PRICE_VARIANT.MONTHLY_FROM_PURCHASE_FROM,
  monthlySalesModelOverride,
  purchaseSalesModelOverride,
  content,
  hideDiscountPricingSaveLabel,
  ...props
}: TranslatePricesProps) => {
  const {
    monthlyPrice,
    monthlyPriceSource,
    monthlyWasPrice,
    monthlyWasPriceSource,
    monthlySavingAmount,
    monthlySavingAmountSource,
    purchasePrice,
    purchasePriceSource,
    purchaseWasPrice,
    purchaseWasPriceSource,
    purchaseSavingAmount,
    purchaseSavingAmountSource,
    monthlyPriceSalesModel,
    purchasePriceSalesModel,
    includesTax,
  } = mapPrices({
    prices,
    edition,
    modelYear,
    monthlySalesModelOverride,
    purchaseSalesModelOverride,
    defaultMonthlySalesModel: settings?.defaultMonthlySalesModel ?? 'sub_fixed',
    ...props,
  });

  const priceAndSourcesByCarPriceVariant = getLabelAndSourcesByCarPriceVariant({
    content,
    settings,
    monthlyPriceSalesModel,
  });
  const variant = normaliseVariant(variantInput, monthlyPrice, purchasePrice);
  const hasMonthly = priceAndSourcesByCarPriceVariant?.[variant]?.hasMonthly;
  const hasPurchase = priceAndSourcesByCarPriceVariant?.[variant]?.hasPurchase;
  const hasMonthlySavingAmount = hasMonthly && !!monthlySavingAmount;
  const hasPurchaseSavingAmount = hasPurchase && !!purchaseSavingAmount;
  const shouldShowSavingsElement =
    !hideDiscountPricingSaveLabel &&
    (hasMonthlySavingAmount || hasPurchaseSavingAmount);

  const showVATLabel = settings?.showVATLabel ?? !includesTax;
  const { label: vatLabel, sources: vatLabelSources } = getVATLabelAndSources({
    showVATLabel,
    includesTax,
    content,
  });
  const { label: savingsLabel, sources: savingsLabelSources } =
    getSavingLabelAndSources({
      shouldShowSavingsElement,
      content,
      hasMonthlySavingAmount,
      hasPurchaseSavingAmount,
      monthlySavingAmountSource,
      purchaseSavingAmountSource,
    });
  const contentByVariant = getContentByVariant({
    priceAndSourcesByCarPriceVariant,
    monthlyPrice,
    monthlyPriceSource,
    monthlyWasPrice,
    monthlyWasPriceSource,
    purchasePrice,
    purchasePriceSource,
    purchaseWasPrice,
    purchaseWasPriceSource,
    vatLabelSources,
    savingsLabelSources,
  });

  const carPrice = getCarPriceElement({
    label: contentByVariant[variant]?.translation,
    settings,
    hasMonthly,
    monthlyPrice,
    monthlyWasPrice,
    hasPurchase,
    purchasePrice,
    purchaseWasPrice,
    showVATLabel,
    vatLabel,
    hideDiscountPricingSaveLabel,
    savingsLabel,
    monthlySavingAmount,
    purchaseSavingAmount,
    shouldShowSavingsElement,
  });
  const isValidCarPrice = !!carPrice;
  const carPriceSources = contentByVariant[variant]?.translationSources;
  const label = contentByVariant[variant]?.label;
  const labelSource = contentByVariant[variant]?.labelSources;

  return {
    carPrice,
    carPriceSources,
    isValidCarPrice,
    label,
    labelSource,
    monthlyPrice,
    monthlyPriceSalesModel,
    monthlyPriceSource,
    purchasePrice,
    purchasePriceSalesModel,
    purchasePriceSource,
    monthlySavingAmountSource,
    monthlyWasPriceSource,
    purchaseSavingAmountSource,
    purchaseWasPriceSource,
    variant,
  };
};
