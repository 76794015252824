import React from 'react';
import SVG from './svg';

const SuggestionPin: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 11 15" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 0C8.53757 0 11 2.46243 11 5.5C11 6.63052 10.6589 7.68137 10.074 8.55526H10.078L5.50083 14.9719L1.06617 8.75502C1.03467 8.71219 1.00379 8.6689 0.973519 8.62514L0.923672 8.55526H0.92597C0.341089 7.68137 0 6.63052 0 5.5C0 2.46243 2.46243 0 5.5 0Z"
      fill="#284E80"
    />
    <circle cx="5.50167" cy="5.49972" r="2.35714" fill="white" />
  </SVG>
);

export default SuggestionPin;
