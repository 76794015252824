import React from 'react';
import SVG from './svg';

const Highway: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SVG viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4677 8.99221V1.7583H15.2714V8.99221H14.4677ZM16.8791 1.7583V8.99221H17.6829V1.7583H16.8791ZM19.2904 1.7583L21.8436 8.99221H22.6927L20.1396 1.7583H19.2904ZM12.003 1.7583L9.44982 8.99221H10.307L12.8601 1.7583H12.003ZM6.90282 18.6374L3.21494 29.0864H14.4677V15.4224H15.2714V29.0864V29.8902H14.4677H3.21494H2.08163L2.45136 28.8212L6.04563 18.6374H6.90282ZM7.47018 17.0299L8.6049 13.8148H14.4677H15.2714H16.8791H17.6829H23.5102H23.5457L24.6804 17.0299H25.0615H25.5296H28.1319H28.9356V16.2261V13.8148H32.1507V13.011H28.9356H28.1319H28.1315V13.8148H28.1319V16.2261H25.2459L24.113 13.0164V13.011H24.1111H24.0725H23.5102H23.262H17.6829H16.8791H15.2714H14.4677H8.88863H8.03768H8.03141L6.89667 16.2261H4.01884V13.8148V13.011H3.21507H0V13.8148H3.21507V16.2261V17.0299H4.01884H6.61299H7.23391H7.47018ZM32.1507 10.5997H23.2601H22.411H17.6829H16.8791H0V11.4035H16.8791H17.6829H22.6946H23.5438H32.1507V10.5997ZM17.6829 15.4224H16.8791V29.0864V29.8902H17.6829H28.9356H30.0689L29.6912 28.8212L26.0969 18.6374H25.2478L28.9356 29.0864H17.6829V15.4224Z"
          fill="#707070"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </SVG>
  );
};

export default Highway;
