import React from 'react';
import SVG from './svg';

const getLocation = () => {
  return (
    <SVG viewBox="0 0 25 25" fill="none">
      <path
        d="M18.5 12C18.5 15.5899 15.5899 18.5 12 18.5C8.41015 18.5 5.5 15.5899 5.5 12C5.5 8.41015 8.41015 5.5 12 5.5C15.5899 5.5 18.5 8.41015 18.5 12Z"
        stroke="#707070"
      />
      <mask
        id="mask0_10972_49990"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="25"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 0H0V25H25V0ZM16 8H8V16H16V8Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_10972_49990)">
        <path d="M12 0.521484V23.478" stroke="#707070" />
        <path d="M23.4785 11.9995L0.521994 11.9995" stroke="#707070" />
      </g>
    </SVG>
  );
};
export default getLocation;
