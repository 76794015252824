import React from 'react';

export const setFocus = (
  containerRef: React.MutableRefObject<HTMLDivElement | null>,
) => {
  if (!containerRef || !containerRef.current) {
    return;
  }

  const firstFocusableEl = containerRef.current.querySelector<HTMLDivElement>(
    'a[href], button:not([disabled]), [tabindex="0"]',
  );

  if (firstFocusableEl) {
    firstFocusableEl.focus({ preventScroll: true });
  }
};
