'use client';
import { cssMerge } from '@volvo-cars/css/utils';
import React, { useState, useEffect } from 'react';

export const IconWrapper: React.FC<{
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  viewBox?: string;
  iconName?: string;
}> = ({ viewBox, iconName, className, style, ...props }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => setLoaded(true), []);

  return (
    <svg
      data-autoid="IconTextList:icon"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      data-icon={iconName}
      viewBox={viewBox}
      preserveAspectRatio="xMinYMin meet"
      className={cssMerge(
        'block max-w-full max-h-full h-full text-secondary',
        className,
      )}
      style={{
        backfaceVisibility: 'hidden',
        transform: 'translateZ(0)',
        opacity: loaded ? 1 : 0,
        transition: 'opacity 300ms',
        ...style,
      }}
      {...props}
    />
  );
};
