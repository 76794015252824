import React, {
  ComponentType,
  useMemo,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import toString from 'lodash/toString';
import { Block, ExtendCSS, BlockProps } from 'vcc-ui';
import { Property } from 'csstype';
import { Click } from '@vcc-www/buttons';
import { AspectRatio } from '@vcc-www/utils/getAspectRatioCSS';
import { Sources } from '@vcc-www/utils/getMediaSources';
import { getMediaQueryValues } from '@vcc-www/utils/getMediaQueryValues';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries/DictionariesProvider';
import { useVideoDuration } from '@vcc-www/video';
import { Icon, IconType } from './Video.icons';
import { Interaction, VideoRef } from './Video';
import { usePosterReady } from './VideoLazyContainer';
/**
 * Used to define the style variation on the blue (outline by default, but
 * with an option to include blue).
 */
export type IconVariant = 'outline' | 'blue' | 'grey';
export type LoadingType = 'eager' | 'lazy';

export type Props = {
  videoRef: VideoRef;
  inViewRef?: React.MutableRefObject<HTMLElement | null>;
  responsiveSrcWebM?: string;
  responsiveSrcMp4?: string;
  poster?: string | Sources;
  shouldAutoplay?: boolean;
  loop?: boolean;
  lazyLoadPoster?: boolean;
  extend?: ExtendCSS;
  inView: boolean;
  actionText: IconType;
  videoDescription?: string;
  aspectRatio?: AspectRatio;
  subtitleTrack?: string;
  /**
   * disabled - disable the controls, but still keep them if autoplaying to be able to pause etc.
   * enabled - show simple controls, just play/pause icon
   * extended - show native browser controls
   * none - don't show controls at all, used if you need to programatically control the playback
   * forceDisable - remove controls completely
   */
  controls?: 'disabled' | 'enabled' | 'extended' | 'none' | 'forceDisable';
  largePlayIcon?: boolean;
  centeredPlayIcon?: boolean;
  autoplayIsEnabled?: boolean;
  iconVariant?: IconVariant;
  trackLabel?: string;
  ga3TrackLabel?: string;
  fullscreenFit?: Property.ObjectFit;
  interactedState?: Interaction;
  togglePaused: () => void;
  handlePosterClick: () => void;
  localizedLanguageName: string;
  contentType?: object;
  locale: string;
  onTimeUpdate?: (currentVideoTime: number) => void;
  onPlay?: () => void;
  onEnded?: () => void;
  muted?: boolean;
  currentTime?: number;
  forceLoad?: boolean;
  trackEventAction?: string;
  trackEventLabel?: string;
  background?: string;
  loading?: LoadingType;
  offsetYVideoControl?: number;
  offsetXVideoControl?: number;
  withBlackVideoIcons?: boolean;
  setVideoReload?: number;
  customizePlayIconSIze?: number;
  playPauseButtonTabIndex?: number;
  fetchPriority?: 'high' | 'low' | 'auto';
  resetVideo?: boolean;
  videoCtaAutoId?: string;
  hideControlsOnPause?: boolean;
  shouldPause?: boolean;
} & Pick<JSX.IntrinsicElements['video'], 'onLoadedMetadata' | 'preload'>;

type TVideoBlock = ComponentType<
  React.PropsWithChildren<
    BlockProps<'video'> & {
      playsInline?: boolean;
      poster?: string | Sources;
      controlsList?: string;
    }
  >
>;

const VideoBlock = Block as TVideoBlock;

const VideoSource: React.FC<
  React.PropsWithChildren<{ webM?: string; mp4?: string }>
> = ({ webM, mp4 }) => (
  <>
    {webM && <source src={webM} type="video/webm" />}
    {mp4 && <source src={mp4} type="video/mp4" />}
  </>
);

const VideoCore: React.FC<React.PropsWithChildren<Props>> = ({
  videoRef,
  extend,
  loop,
  inView,
  inViewRef,
  controls: controlFromProps,
  aspectRatio,
  fullscreenFit,
  handlePosterClick,
  autoplayIsEnabled,
  poster,
  interactedState,
  subtitleTrack,
  responsiveSrcWebM,
  responsiveSrcMp4,
  videoDescription,
  togglePaused,
  largePlayIcon,
  centeredPlayIcon,
  iconVariant = 'outline',
  actionText,
  trackLabel,
  ga3TrackLabel,
  localizedLanguageName,
  locale,
  shouldAutoplay,
  lazyLoadPoster,
  contentType,
  onTimeUpdate,
  onPlay,
  onEnded,
  muted,
  currentTime,
  /**
   * Safari is the new IE.
   * `currentTime` can't be set before loading,
   * so need to call `load` with the ref first.
   */
  forceLoad,
  loading = 'lazy',
  offsetYVideoControl,
  offsetXVideoControl,
  withBlackVideoIcons,
  setVideoReload,
  customizePlayIconSIze,
  playPauseButtonTabIndex = 0,
  fetchPriority,
  resetVideo,
  children,
  videoCtaAutoId,
  preload,
  hideControlsOnPause,
  shouldPause,
  ...props
}) => {
  const { duration, loadDuration } = useVideoDuration();
  const [controls, setControls] = useState(controlFromProps);
  const [hasBeenInView, setHasBeenInView] = useState(false);
  const posterReady = usePosterReady();
  const translate = useSharedComponentsTranslate();
  const backgroundPosters = useMemo(
    () =>
      getMediaQueryValues(poster, (url) => ({
        backgroundImage: posterReady ? `url(${url})` : 'transparent',
      })),
    [poster, posterReady],
  );

  let showSimpleControls =
    controls === 'forceDisable'
      ? false
      : controls === 'enabled' ||
        (controls === 'disabled' &&
          (hideControlsOnPause ? false : !interactedState) &&
          !autoplayIsEnabled) ||
        (controls === 'extended' && !autoplayIsEnabled && !interactedState);

  showSimpleControls = controls !== 'none' && showSimpleControls;

  const showExtendedControls =
    controls === 'extended' && (autoplayIsEnabled || !!interactedState);

  const isMuted = typeof muted !== 'undefined' ? muted : shouldAutoplay || loop;

  // Video requires controls if longer than 5 seconds (https://www.w3.org/WAI/WCAG21/Understanding/pause-stop-hide)
  const videoRequiresControls = !!duration && duration > 5;

  useEffect(() => {
    setControls(controlFromProps);
  }, [controlFromProps]);

  useEffect(() => {
    if (
      videoRequiresControls &&
      (controls === 'disabled' || controls === 'none')
    ) {
      setControls('enabled');
    }
  }, [videoRequiresControls, controls]);

  useEffect(() => {
    if (videoRef.current && typeof currentTime === 'number') {
      videoRef.current.currentTime = currentTime;
    }
  }, [currentTime, videoRef]);

  useEffect(() => {
    if (videoRef.current && forceLoad) {
      videoRef.current.load();
    }
    if (videoRef.current && setVideoReload !== undefined) {
      videoRef.current.load();
    }
  }, [forceLoad, videoRef, setVideoReload]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current?.load();
    }
  }, [videoRef, responsiveSrcWebM, responsiveSrcMp4, hasBeenInView]);

  useEffect(() => {
    if (!hasBeenInView && inView) {
      setHasBeenInView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  useEffect(() => {
    if (videoRef.current && resetVideo) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [resetVideo, videoRef]);

  useEffect(() => {
    if (videoRef.current && shouldPause && !videoRef.current.paused) {
      videoRef.current.pause();
    }
  });

  const playButtonAriaLabel = translate('VideoPlayer.controls.play');
  const pauseButtonAriaLabel = translate('VideoPlayer.controls.pause');

  const ariaLabel =
    actionText === 'play' ? playButtonAriaLabel : pauseButtonAriaLabel;

  return (
    <>
      <VideoBlock
        {...props}
        as="video"
        ref={videoRef}
        title={videoDescription}
        preload={preload || (forceLoad ? 'metadata' : 'auto')}
        {...((controls === 'enabled' || controls === 'extended') && {
          onClick: handlePosterClick,
        })}
        {...(fetchPriority ? { fetchPriority } : {})}
        playsInline
        autoPlay={autoplayIsEnabled}
        /**
         * Transparent image is used as poster. This enables backgroundImage to be seen which is used as the "real" poster.
         * Based on this demo https://codepen.io/chris3000/pen/wXBwyr.
         */
        poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
        extend={[
          videoCSS(fullscreenFit, aspectRatio),
          backgroundPosters,
          extend,
        ]}
        loop={loop}
        controls={showExtendedControls}
        controlsList="nodownload"
        muted={isMuted}
        onPlay={onPlay}
        onEnded={onEnded}
        onTimeUpdate={(event: SyntheticEvent<HTMLVideoElement>) => {
          if (typeof onTimeUpdate === 'function') {
            onTimeUpdate(event.currentTarget.currentTime);
          }
        }}
        onLoadedMetadata={(e) => {
          props.onLoadedMetadata?.(e);
          loadDuration(e);
        }}
      >
        {hasBeenInView && loading === 'lazy' && (
          <VideoSource webM={responsiveSrcWebM} mp4={responsiveSrcMp4} />
        )}
        {loading === 'eager' && (
          <VideoSource webM={responsiveSrcWebM} mp4={responsiveSrcMp4} />
        )}
        {subtitleTrack && (
          <Block
            as="track"
            label={localizedLanguageName}
            src={subtitleTrack}
            srcLang={locale}
            kind="subtitles"
            default
          />
        )}
      </VideoBlock>
      {children}
      {showSimpleControls && (
        <Click
          onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            e.stopPropagation();
            togglePaused();
          }}
          extend={buttonCSS(
            largePlayIcon,
            customizePlayIconSIze,
            centeredPlayIcon,
            iconVariant,
            offsetYVideoControl,
            offsetXVideoControl,
          )}
          aria-label={ariaLabel}
          trackEventLabel={trackLabel}
          // @ts-ignore Check if this is correct value to track for ga4
          trackEventAction={`media|${actionText}`}
          trackGA3={{
            eventAction: actionText,
            eventLabel: ga3TrackLabel,
          }}
          tabIndex={playPauseButtonTabIndex}
          data-autoid={videoCtaAutoId}
        >
          <Icon
            type={
              withBlackVideoIcons
                ? actionText === 'play'
                  ? 'play_black'
                  : 'pause_black'
                : actionText
            }
            id={`${toString(responsiveSrcMp4)}${toString(
              responsiveSrcWebM,
            )}${toString(trackLabel)}${toString(inView)}
            `}
          />
        </Click>
      )}
    </>
  );
};

const videoCSS = (
  fullscreenFit?: Property.ObjectFit,
  aspectRatio?: AspectRatio,
): ExtendCSS => ({
  width: '100%',
  objectFit: 'cover',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  ...(aspectRatio && {
    objectFit: fullscreenFit,
    position: 'absolute',
    height: '100%',
    top: 0,
  }),
  ':fullscreen': {
    objectFit: fullscreenFit,
  },
});

// NOTE - here we want to support providing a variant (blue)
const buttonCSS =
  (
    largePlayIcon?: boolean,
    customizePlayIconSIze?: number,
    centeredPlayIcon?: boolean,
    iconVariant?: string,
    offsetYVideoControl?: number,
    offsetXVideoControl?: number,
  ): ExtendCSS =>
  ({ theme: { color } }) => ({
    cursor: 'pointer',
    position: 'absolute',
    background: 'transparent',
    bottom: centeredPlayIcon ? '50%' : 20 + (offsetYVideoControl || 0),
    right: centeredPlayIcon ? '50%' : 20 + (offsetXVideoControl || 0),
    width: largePlayIcon ? 50 : customizePlayIconSIze || 40,
    height: largePlayIcon ? 50 : customizePlayIconSIze || 40,
    padding: 0,
    margin: 0,
    transition: offsetYVideoControl
      ? 'all 500ms ease-out'
      : 'opacity 100ms ease-out',

    ...(centeredPlayIcon && {
      transform: 'translate(50%, 50%)',
    }),

    '&:hover': {
      opacity: 0.7,
    },
    ...(iconVariant === 'blue' && {
      '> svg circle': {
        fill: '#1C6BBA',
        stroke: '#1C6BBA',
        // Get the play/pause icon paths to be filled in as white
      },
      // The pause bars
      '> svg rect': {
        fill: 'white',
        stroke: 'transparent',
        // Get the play/pause icon paths to be filled in as white
      },
      // The play triangle
      '> svg > path': {
        stroke: 'white',
        strokeWidth: 20,
      },
    }),
    ...(iconVariant === 'grey' && {
      '> svg circle': {
        fill: color.ornament.border,
      },
    }),
  });

export default VideoCore;
