import React from 'react';
import SVG from './svg';

const Exit: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 16 17">
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.99986 2.72656H6.99986V3.72656L2.99986 3.72656L2.99986 13.7266H12.9999V10.7266H13.9999V14.7266H12.9999H2.99986H1.99986V13.7266V3.72656V2.72656ZM8.99986 2.72656H13.9999V3.72656V7.72656H12.9999V4.49078L6.4859 11.0047L5.7788 10.2976L12.3499 3.72656H8.99986V2.72656Z"
    />
  </SVG>
);

export default Exit;
