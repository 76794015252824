'use client';

/**
 * Simplified version of https://github.com/hadeeb/react-lazy-hydration/blob/master/src/index.tsx
 */
import React, { useEffect, useState, useRef } from 'react';
import { useLayoutEffect } from '@vcc-www/hooks';

const isServer = typeof window === 'undefined';

const event = 'vcc:hydrate';
const io =
  typeof window !== 'undefined' && typeof IntersectionObserver === 'function'
    ? new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting || entry.intersectionRatio > 0) {
              entry.target.dispatchEvent(new CustomEvent(event));
            }
          });
        },
        {
          rootMargin: '150px',
        },
      )
    : null;

const HydrateInView: React.FC<
  React.PropsWithChildren<
    React.HTMLAttributes<HTMLDivElement> & {
      rootMargin?: string;
      whenIdle?: boolean;
    }
  >
> = ({ children, whenIdle = true, ...props }) => {
  const [hydrated, setHydrated] = useState(isServer);
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (!ref.current?.hasChildNodes()) {
      setHydrated(true);
    }
  }, []);

  useEffect(() => {
    if (hydrated) return;

    const cleanupFns: VoidFunction[] = [];
    function cleanup() {
      cleanupFns.forEach((fn) => fn());
    }
    function hydrateHandler() {
      setHydrated(true);
    }
    const events: string[] = [];
    if (io && ref.current?.childElementCount) {
      const element = ref.current;
      io.observe(element);
      events.push(event);
      cleanupFns.push(() => {
        io.unobserve(element);
      });
    }

    if (!io && ref.current?.childElementCount) {
      hydrateHandler();
    }

    events.forEach((event) => {
      let idleCallbackId: string;
      ref.current?.addEventListener(
        event,
        () => {
          if (whenIdle && 'requestIdleCallback' in window) {
            idleCallbackId = (window as any).requestIdleCallback(
              hydrateHandler,
            );
          } else {
            hydrateHandler();
          }
        },
        {
          once: true,
          capture: true,
          passive: true,
        },
      );
      cleanupFns.push(() => {
        if (whenIdle && 'requestIdleCallback' in window) {
          (window as any).cancelIdleCallback(idleCallbackId);
        }
        ref.current?.removeEventListener(event, hydrateHandler, {
          capture: true,
        });
      });
    });
    return cleanup;
  }, [hydrated, whenIdle]);

  return hydrated ? (
    <div {...props} ref={ref}>
      {children}
    </div>
  ) : (
    <div
      ref={ref}
      data-rendering="hydration-supressed"
      suppressHydrationWarning
      className="contents"
      {...props}
      dangerouslySetInnerHTML={{ __html: '' }}
    />
  );
};

export default HydrateInView;
