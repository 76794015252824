import React from 'react';
import { Text, ExtendCSS } from 'vcc-ui';
import { useSpringCarousel } from './SpringCarousel.context';

type Props = {
  testId?: string;
  extend?: ExtendCSS;
};

export const SpringCarouselCount: React.FC<React.PropsWithChildren<Props>> = ({
  testId,
  extend,
}) => {
  const { total, current } = useSpringCarousel();

  return (
    <Text
      variant="columbus"
      extend={[rootCSS, extend]}
      data-autoid={testId ? `${testId}:count` : ''}
    >
      {current} / {total}
    </Text>
  );
};

const rootCSS: ExtendCSS = ({ theme: { color } }) => ({
  textAlign: 'right',
  color: color.foreground.primary,
});
