import React from 'react';
import SVG from './svg';

const Link: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9253 3.07539C18.8365 0.986588 15.4498 0.986589 13.361 3.07539L9.93248 6.50395L10.6396 7.21106L14.0681 3.7825C15.7664 2.08422 18.5199 2.08422 20.2182 3.7825C21.9164 5.48078 21.9164 8.23423 20.2182 9.93251L16.8762 13.2745C15.1173 15.0122 12.2828 15.0056 10.532 13.2549L10.2104 12.9333L9.50327 13.6404L9.82487 13.962C11.9372 16.0743 15.3403 16.1093 17.4953 14.0668L17.4967 14.0682L17.5812 13.9837L17.603 13.962L18.3532 13.2118L18.3531 13.2117L20.9253 10.6396C23.0141 8.55081 23.0141 5.16419 20.9253 3.07539ZM3.0749 20.9254C5.16371 23.0142 8.55032 23.0142 10.6391 20.9254L14.0677 17.4968L13.3606 16.7897L9.93202 20.2183C8.23374 21.9166 5.48029 21.9166 3.78201 20.2183C2.08373 18.52 2.08373 15.7665 3.78201 14.0683L6.35339 11.4969L6.35358 11.4971L7.10375 10.7469C8.8611 8.98954 11.7103 8.98954 13.4677 10.7469L13.7893 11.0685L14.4964 10.3614L14.1748 10.0398C12.0627 7.92769 8.66019 7.8925 6.50509 9.93422L6.50346 9.9326L3.0749 13.3612C0.9861 15.45 0.9861 18.8366 3.0749 20.9254Z"
      fill="currentColor"
    />
  </SVG>
);

export default Link;
