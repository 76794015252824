import React from 'react';
import SVG from './svg';

const Facebook: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.614 21h-8.62c-.55 0-.994-.445-.994-.994V3.993C3 3.445 3.445 3 3.993 3h16.014c.548 0 .993.445.993.993v16.014c0 .548-.445.993-.993.993H15.42v-6.97h2.34l.35-2.717h-2.69V9.578c0-.786.218-1.322 1.346-1.322h1.439v-2.43c-.25-.033-1.103-.107-2.097-.107-2.074 0-3.494 1.266-3.494 3.59v2.004H10.27v2.716h2.345V21z"
      clipRule="evenodd"
    />
  </SVG>
);

export default Facebook;
