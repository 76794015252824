import { HttpLink } from 'apollo-link-http';

export function createBuildAndPriceLink({
  endpoint,
  operationId,
}: {
  endpoint?: URL;
  operationId?: string;
}) {
  if (!endpoint) {
    return new HttpLink({
      uri:
        typeof window !== 'undefined'
          ? window.location.origin
          : 'http://localhost',
      fetch: () =>
        Promise.reject(new Error(`No Build and Price endpoint configured`)),
    });
  }

  return new HttpLink({
    uri: endpoint.href,
    credentials: 'same-origin',
    headers: {
      'VCC-Api-OperationId': operationId,
    },
    fetch,
  });
}
