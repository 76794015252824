import React from 'react';
import SVG from './svg';

const List = () => {
  return (
    <SVG viewBox="0 0 16 16" fill="none">
      <path
        d="M15.5 2.5H0.5V6.5H15.5V2.5Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M0.5 10.5H15.5"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M0.5 14.5H15.5"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </SVG>
  );
};
export default List;
