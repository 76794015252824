import React from 'react';
import SVG from './svg';

type Props = {
  strokeWidth?: number;
};

const PhoneIcon: React.FC<React.PropsWithChildren<Props>> = ({
  strokeWidth = 1,
}) => (
  <SVG viewBox="0 0 24 24">
    <path
      d="M2.79988 1.60475C4.35119 0.41677 5.12657 1.20885 5.95637 2.02146L8.42289 4.7728C9.77994 6.15888 9.55134 7.37935 8.88454 8.06027L7.95175 9.01311L7.90095 9.06499L7.9043 9.06841C7.21098 9.9212 8.01846 11.9942 10.075 14.0942C12.132 16.1946 14.1793 17.0368 15.015 16.3297L15.0183 16.3331L15.0691 16.2812L16.0019 15.3287C16.669 14.6475 17.7081 14.5266 18.5078 15.0371L21.9158 17.9227C22.965 18.633 23.5466 19.6229 22.2744 21.1398C16.9305 27.0124 7.14651 17.1035 7.14651 17.1035C7.14651 17.1035 -3.02325 6.90051 2.79988 1.60475Z"
      stroke="currentColor"
      fill="none"
      strokeWidth={strokeWidth}
    />
  </SVG>
);

export default PhoneIcon;
