import { CarPriceVariantType } from './car-price.types';

// Public-facing
export const CAR_PRICE_VARIANT = {
  FROM_PURCHASE_PRICE: 'FROM_PURCHASE_PRICE',
  FROM_MONTHLY_PRICE: 'FROM_MONTHLY_PRICE',
  FROM_MONTHLY_OR_PURCHASE_PRICE: 'FROM_MONTHLY_OR_PURCHASE_PRICE',
  PURCHASE_FROM: 'PURCHASE_FROM',
  MONTHLY_FROM: 'MONTHLY_FROM',
  MONTHLY_FROM_PURCHASE_FROM: 'MONTHLY_FROM_PURCHASE_FROM',
} as const;

// Public-facing
export const CAR_PRICE_LAYOUT = {
  BOLD: 'BOLD',
  COMPACT: 'COMPACT',
} as const;

export const MONTHLY_SKELETON_VARIANTS: CarPriceVariantType[] = [
  'MONTHLY_FROM',
  'MONTHLY_FROM_PURCHASE_FROM',
  'FROM_MONTHLY_OR_PURCHASE_PRICE',
  'FROM_MONTHLY_PRICE',
];

export const PURCHASE_SKELETON_VARIANTS: CarPriceVariantType[] = [
  'PURCHASE_FROM',
  'MONTHLY_FROM_PURCHASE_FROM',
  'FROM_MONTHLY_OR_PURCHASE_PRICE',
  'FROM_PURCHASE_PRICE',
];

export const VAT_DISPLAY_TEMPLATE = '({vat})';

export const TAX_TERRITORY = {
  'AU-ACT': 'AU-ACT', // Australia - Australian Capital Territory
  'AU-NSW': 'AU-NSW', // Australia - New South Wales
  'AU-NT': 'AU-NT', // Australia - Northern Territory
  'AU-QLD': 'AU-QLD', // Australia - Queensland
  'AU-SA': 'AU-SA', // Australia - South Australia
  'AU-TAS': 'AU-TAS', // Australia - Tasmania
  'AU-VIC': 'AU-VIC', // Australia - Victoria
  'AU-WA': 'AU-WA', // Australia - Western Australia

  'CA-AB': 'CA-AB', // Canada - Alberta
  'CA-BC': 'CA-BC', // Canada - British Columbia
  'CA-MB': 'CA-MB', // Canada - Manitoba
  'CA-NB': 'CA-NB', // Canada - New Brunswick
  'CA-NL': 'CA-NL', // Canada - Newfoundland and Labrador
  'CA-NS': 'CA-NS', // Canada - Nova Scotia
  'CA-NT': 'CA-NT', // Canada - Northwest Territories
  'CA-NU': 'CA-NU', // Canada - Nunavut
  'CA-ON': 'CA-ON', // Canada - Ontario
  'CA-PE': 'CA-PE', // Canada - Prince Edward Island
  'CA-QC': 'CA-QC', // Canada - Quebec
  'CA-SK': 'CA-SK', // Canada - Saskatchewan
  'CA-YT': 'CA-YT', // Canada - Yukon

  'JP-01': 'JP-01', // Japan - Hokkaido
  'JP-02': 'JP-02', // Japan - Aomori
  'JP-03': 'JP-03', // Japan - Iwate
  'JP-04': 'JP-04', // Japan - Miyagi
  'JP-05': 'JP-05', // Japan - Akita
  'JP-06': 'JP-06', // Japan - Yamagata
  'JP-07': 'JP-07', // Japan - Fukushima
  'JP-08': 'JP-08', // Japan - Ibaraki
  'JP-09': 'JP-09', // Japan - Tochigi
  'JP-10': 'JP-10', // Japan - Gunma
  'JP-11': 'JP-11', // Japan - Saitama
  'JP-12': 'JP-12', // Japan - Chiba
  'JP-13': 'JP-13', // Japan - Tokyo
  'JP-14': 'JP-14', // Japan - Kanagawa
  'JP-15': 'JP-15', // Japan - Niigata
  'JP-16': 'JP-16', // Japan - Toyama
  'JP-17': 'JP-17', // Japan - Ishikawa
  'JP-18': 'JP-18', // Japan - Fukui
  'JP-19': 'JP-19', // Japan - Yamanashi
  'JP-20': 'JP-20', // Japan - Nagano
  'JP-21': 'JP-21', // Japan - Gifu
  'JP-22': 'JP-22', // Japan - Shizuoka
  'JP-23': 'JP-23', // Japan - Aichi
  'JP-24': 'JP-24', // Japan - Mie
  'JP-25': 'JP-25', // Japan - Shiga
  'JP-26': 'JP-26', // Japan - Kyoto
  'JP-27': 'JP-27', // Japan - Osaka
  'JP-28': 'JP-28', // Japan - Hyogo
  'JP-29': 'JP-29', // Japan - Nara
  'JP-30': 'JP-30', // Japan - Wakayama
  'JP-31': 'JP-31', // Japan - Tottori
  'JP-32': 'JP-32', // Japan - Shimane
  'JP-33': 'JP-33', // Japan - Okayama
  'JP-34': 'JP-34', // Japan - Hiroshima
  'JP-35': 'JP-35', // Japan - Yamaguchi
  'JP-36': 'JP-36', // Japan - Tokushima
  'JP-37': 'JP-37', // Japan - Kagawa
  'JP-38': 'JP-38', // Japan - Ehime
  'JP-39': 'JP-39', // Japan - Kochi
  'JP-40': 'JP-40', // Japan - Fukuoka
  'JP-41': 'JP-41', // Japan - Saga
  'JP-42': 'JP-42', // Japan - Nagasaki
  'JP-43': 'JP-43', // Japan - Kumamoto
  'JP-44': 'JP-44', // Japan - Oita
  'JP-45': 'JP-45', // Japan - Miyazaki
  'JP-46': 'JP-46', // Japan - Kagoshima
  'JP-47': 'JP-47', // Japan - Okinawa

  'US-AL': 'US-AL', // United States - Alabama
  'US-AK': 'US-AK', // United States - Alaska
  'US-AZ': 'US-AZ', // United States - Arizona
  'US-AR': 'US-AR', // United States - Arkansas
  'US-CA': 'US-CA', // United States - California
  'US-CO': 'US-CO', // United States - Colorado
  'US-CT': 'US-CT', // United States - Connecticut
  'US-DE': 'US-DE', // United States - Delaware
  'US-FL': 'US-FL', // United States - Florida
  'US-GA': 'US-GA', // United States - Georgia
  'US-HI': 'US-HI', // United States - Hawaii
  'US-ID': 'US-ID', // United States - Idaho
  'US-IL': 'US-IL', // United States - Illinois
  'US-IN': 'US-IN', // United States - Indiana
  'US-IA': 'US-IA', // United States - Iowa
  'US-KS': 'US-KS', // United States - Kansas
  'US-KY': 'US-KY', // United States - Kentucky
  'US-LA': 'US-LA', // United States - Louisiana
  'US-ME': 'US-ME', // United States - Maine
  'US-MD': 'US-MD', // United States - Maryland
  'US-MA': 'US-MA', // United States - Massachusetts
  'US-MI': 'US-MI', // United States - Michigan
  'US-MN': 'US-MN', // United States - Minnesota
  'US-MS': 'US-MS', // United States - Mississippi
  'US-MO': 'US-MO', // United States - Missouri
  'US-MT': 'US-MT', // United States - Montana
  'US-NE': 'US-NE', // United States - Nebraska
  'US-NV': 'US-NV', // United States - Nevada
  'US-NH': 'US-NH', // United States - New Hampshire
  'US-NJ': 'US-NJ', // United States - New Jersey
  'US-NM': 'US-NM', // United States - New Mexico
  'US-NY': 'US-NY', // United States - New York
  'US-NC': 'US-NC', // United States - North Carolina
  'US-ND': 'US-ND', // United States - North Dakota
  'US-OH': 'US-OH', // United States - Ohio
  'US-OK': 'US-OK', // United States - Oklahoma
  'US-OR': 'US-OR', // United States - Oregon
  'US-PA': 'US-PA', // United States - Pennsylvania
  'US-RI': 'US-RI', // United States - Rhode Island
  'US-SC': 'US-SC', // United States - South Carolina
  'US-SD': 'US-SD', // United States - South Dakota
  'US-TN': 'US-TN', // United States - Tennessee
  'US-TX': 'US-TX', // United States - Texas
  'US-UT': 'US-UT', // United States - Utah
  'US-VT': 'US-VT', // United States - Vermont
  'US-VA': 'US-VA', // United States - Virginia
  'US-WA': 'US-WA', // United States - Washington
  'US-WV': 'US-WV', // United States - West Virginia
  'US-WI': 'US-WI', // United States - Wisconsin
  'US-WY': 'US-WY', // United States - Wyoming
} as const;
