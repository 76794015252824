import React from 'react';
import SVG from './svg';

const FuelTypeFuel: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7071 3.29289C13.8881 3.47386 14 3.72386 14 4V10H5V4C5 3.98274 5.00044 3.96558 5.0013 3.94854C5.01426 3.69288 5.12324 3.46255 5.29289 3.29289C5.47386 3.11193 5.72386 3 6 3H13C13.2761 3 13.5261 3.11193 13.7071 3.29289ZM15 10.5315V10V4C15 2.89543 14.1046 2 13 2H6C4.89543 2 4 2.89543 4 4V10V11V20V21H3V22H4H5H14H15H16V21H15V20V11.5417C15.6988 11.674 16 12.128 16 13V18C16 19.2761 16.7239 20 18 20C19.2761 20 20 19.2761 20 18V11C20 10.7567 20.0501 10.5863 20.2889 9.94166C20.6768 8.89434 20.7702 8.22952 20.4743 7.34189C19.9784 5.85395 17.992 3.68696 16.7236 3.05279L16.2764 3.94721C17.0371 4.32758 18.1904 5.56725 18.9383 6.55477C19.3033 7.03681 19.125 9.31836 19.0381 10.4296C19.0161 10.7106 19 10.9167 19 11V18C19 18.7239 18.7239 19 18 19C17.2761 19 17 18.7239 17 18V13C17 11.5658 16.2929 10.7025 15 10.5315ZM5 20V11H14V20V21H13H6H5V20ZM7 5H12V6H7V5ZM7 7H12V8H7V7Z"
      fill="currentColor"
    />
  </SVG>
);

export default FuelTypeFuel;
