import React from 'react';
import SVG from './svg';

const StarOutlined: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24">
    <path
      stroke="currentColor"
      fill="none"
      d="M15.7374 8.30938L22.9627 9.29356L17.7845 14.7247L17.6058 14.9122L17.6557 15.1662L19.1975 23.0067L12.5457 19.1036L12.2927 18.9552L12.0396 19.1036L5.38784 23.0067L6.92963 15.1662L6.98254 14.8972L6.78406 14.7079L1.10574 9.29368L8.84354 8.30996L9.12162 8.27461L9.23654 8.01894L12.2927 1.21961L15.3488 8.01894L15.4625 8.27194L15.7374 8.30938Z"
      clipRule="evenodd"
    />
  </SVG>
);

export default StarOutlined;
