import React from 'react';
import SVG from './svg';

const BatteryCharge: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SVG viewBox="0 2 24 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8.99854L20 7.99854C20 6.89397 19.1046 5.99854 18 5.99853L4 5.99853C2.89543 5.99853 2 6.89396 2 7.99853L2 14.9985C2 16.1031 2.89543 16.9985 4 16.9985L18 16.9985C19.1046 16.9985 20 16.1031 20 14.9985L20 13.9985L20.5 13.9985C21.3284 13.9985 22 13.327 22 12.4985L22 10.4985C22 9.67011 21.3284 8.99854 20.5 8.99854L20 8.99854ZM20 9.99854L20 12.9985L20.5 12.9985C20.7761 12.9985 21 12.7747 21 12.4985L21 10.4985C21 10.2224 20.7761 9.99854 20.5 9.99854L20 9.99854ZM19 7.99854L19 14.9985C19 15.5508 18.5523 15.9985 18 15.9985L4 15.9985C3.44772 15.9985 3 15.5508 3 14.9985L3 7.99853C3 7.44625 3.44772 6.99853 4 6.99853L18 6.99853C18.5523 6.99854 19 7.44625 19 7.99854Z"
        fill="#2A609D"
      />
    </SVG>
  );
};

export default BatteryCharge;
