import React from 'react';
import SVG from './svg';

const ChargingAcCh: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SVG viewBox="0 0 48 48" fill="none">
      <rect width="47.9999" height="48" fill="none" />
      <path
        d="M19.1512 34.4112C20.953 34.4112 22.4137 32.9506 22.4137 31.1487C22.4137 29.3469 20.953 27.8862 19.1512 27.8862C17.3493 27.8862 15.8887 29.3469 15.8887 31.1487C15.8887 32.9506 17.3493 34.4112 19.1512 34.4112Z"
        stroke="#707070"
      />
      <path
        d="M13.9388 25.004C15.7406 25.004 17.2013 23.5433 17.2013 21.7415C17.2013 19.9397 15.7406 18.479 13.9388 18.479C12.1369 18.479 10.6763 19.9397 10.6763 21.7415C10.6763 23.5433 12.1369 25.004 13.9388 25.004Z"
        stroke="#707070"
      />
      <path
        d="M24.3836 25.004C26.1854 25.004 27.6461 23.5433 27.6461 21.7415C27.6461 19.9397 26.1854 18.479 24.3836 18.479C22.5818 18.479 21.1211 19.9397 21.1211 21.7415C21.1211 23.5433 22.5818 25.004 24.3836 25.004Z"
        stroke="#707070"
      />
      <path
        d="M34.8289 25.004C36.6307 25.004 38.0914 23.5433 38.0914 21.7415C38.0914 19.9397 36.6307 18.479 34.8289 18.479C33.0271 18.479 31.5664 19.9397 31.5664 21.7415C31.5664 23.5433 33.0271 25.004 34.8289 25.004Z"
        stroke="#707070"
      />
      <path
        d="M29.66 34.4112C31.4618 34.4112 32.9225 32.9506 32.9225 31.1487C32.9225 29.3469 31.4618 27.8862 29.66 27.8862C27.8581 27.8862 26.3975 29.3469 26.3975 31.1487C26.3975 32.9506 27.8581 34.4112 29.66 34.4112Z"
        stroke="#707070"
      />
      <path
        d="M18.9868 16.041C19.949 16.041 20.729 15.261 20.729 14.2988C20.729 13.3366 19.949 12.5566 18.9868 12.5566C18.0246 12.5566 17.2446 13.3366 17.2446 14.2988C17.2446 15.261 18.0246 16.041 18.9868 16.041Z"
        stroke="#707070"
      />
      <path
        d="M29.8618 16.041C30.824 16.041 31.604 15.261 31.604 14.2988C31.604 13.3366 30.824 12.5566 29.8618 12.5566C28.8996 12.5566 28.1196 13.3366 28.1196 14.2988C28.1196 15.261 28.8996 16.041 29.8618 16.041Z"
        stroke="#707070"
      />
      <path
        d="M11.5053 6V5.5H11.3271L11.189 5.61277L11.5053 6ZM37.3049 6L37.6212 5.61277L37.4831 5.5H37.3049V6ZM4.5 21.8111C4.5 15.5914 7.35208 10.0382 11.8216 6.38723L11.189 5.61277C6.49694 9.44549 3.5 15.2785 3.5 21.8111H4.5ZM24.4051 41.7162C13.4118 41.7162 4.5 32.8044 4.5 21.8111H3.5C3.5 33.3566 12.8595 42.7162 24.4051 42.7162V41.7162ZM44.3102 21.8111C44.3102 32.8044 35.3984 41.7162 24.4051 41.7162V42.7162C35.9507 42.7162 45.3102 33.3566 45.3102 21.8111H44.3102ZM36.9886 6.38723C41.4581 10.0382 44.3102 15.5914 44.3102 21.8111H45.3102C45.3102 15.2785 42.3133 9.44549 37.6212 5.61277L36.9886 6.38723ZM37.3049 5.5H11.5053V6.5H37.3049V5.5Z"
        fill="#707070"
      />
      <path
        d="M13.5309 8.88428V8.38428H13.3526L13.2146 8.49704L13.5309 8.88428ZM35.2776 8.88428L35.5939 8.49704L35.4558 8.38428H35.2776V8.88428ZM7.7041 22.2122C7.7041 16.9938 10.097 12.3347 13.8472 9.27152L13.2146 8.49704C9.24182 11.742 6.7041 16.6809 6.7041 22.2122H7.7041ZM24.4042 38.9123C15.181 38.9123 7.7041 31.4354 7.7041 22.2122H6.7041C6.7041 31.9877 14.6287 39.9123 24.4042 39.9123V38.9123ZM41.1043 22.2122C41.1043 31.4354 33.6274 38.9123 24.4042 38.9123V39.9123C34.1797 39.9123 42.1043 31.9877 42.1043 22.2122H41.1043ZM34.9613 9.27152C38.7115 12.3347 41.1043 16.9938 41.1043 22.2122H42.1043C42.1043 16.6809 39.5666 11.742 35.5939 8.49704L34.9613 9.27152ZM35.2776 8.38428H13.5309V9.38428H35.2776V8.38428Z"
        fill="#707070"
      />
    </SVG>
  );
};

export default ChargingAcCh;
