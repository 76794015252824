import React from 'react';
import SVG from './svg';

const Twitter: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23 5.13c-.772.362-1.602.607-2.474.716.89-.562 1.573-1.452 1.895-2.513-.834.52-1.754.898-2.738 1.101C18.899 3.551 17.779 3 16.539 3c-2.378 0-4.307 2.035-4.307 4.544 0 .356.037.702.11 1.035-3.58-.19-6.754-1.997-8.88-4.75-.371.674-.583 1.455-.583 2.287 0 1.576.76 2.967 1.917 3.782-.705-.022-1.371-.228-1.953-.566v.055c0 2.203 1.485 4.04 3.458 4.456-.362.107-.742.16-1.136.16-.277 0-.548-.027-.81-.08.548 1.804 2.138 3.118 4.024 3.153-1.474 1.22-3.333 1.947-5.351 1.947-.348 0-.692-.021-1.028-.063C3.907 20.247 6.172 21 8.604 21c7.926 0 12.259-6.923 12.259-12.928 0-.198-.004-.396-.011-.59.841-.64 1.572-1.44 2.148-2.351z"
      clipRule="evenodd"
    />
  </SVG>
);

export default Twitter;
