// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-unused-vars */
import React from 'react';
import { ExtendCSS, Icon } from 'vcc-ui';
import { Click } from '@vcc-www/buttons';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { useSpringCarousel } from './SpringCarousel.context';

interface SpringCarouselArrowProps {
  supportsRtl?: boolean;
  direction: 'right' | 'left';
  extend?: ExtendCSS;
  trackEventLabel?: Function;
}

export const SpringCarouselArrow: React.FC<
  React.PropsWithChildren<SpringCarouselArrowProps>
> = (props) => {
  const {
    supportsRtl = true,
    direction,
    extend,
    trackEventLabel,
    children,
  } = props;
  const { current, total, setCurrent, disabled, emitEvent } =
    useSpringCarousel();
  const isRight = direction === 'right';
  const isDisabled =
    (current === 1 && !isRight) || (current === total && isRight);
  const onArrowClick = () => {
    isRight ? emitEvent('click-arrow-next') : emitEvent('click-arrow-previous');
    setCurrent(current + (isRight ? 1 : -1));
  };

  const translate = useSharedComponentsTranslate();
  const nextButtonAriaLabel = translate(
    'SpringCarousel.springCarousel.nextButtonLabel',
  );
  const previousButtonAriaLabel = translate(
    'SpringCarousel.springCarousel.previousButtonLabel',
  );

  const ariaLabel =
    direction === 'left' ? previousButtonAriaLabel : nextButtonAriaLabel;
  const nextOrPrev = direction === 'right' ? 'next' : 'previous';

  return (
    <Click
      onClick={onArrowClick}
      trackEventLabel={
        trackEventLabel
          ? trackEventLabel({ current, direction })
          : `${nextOrPrev}`
      }
      // @ts-ignore Check if this is correct value to track for ga4
      trackEventAction={`arrow|${nextOrPrev}`}
      trackGA3={{
        eventLabel: `${
          trackEventLabel
            ? trackEventLabel({ current, direction })
            : `${direction} arrow`
        }`,
        eventAction: 'click',
      }}
      aria-label={ariaLabel}
      extend={[
        arrowCSS(supportsRtl),
        isRight ? arrowRightCSS : arrowLeftCSS,
        extend,
      ]}
      disabled={isDisabled || disabled}
      data-autoid={`SpringCarouselArrow:${direction}`}
    >
      {children ? (
        children
      ) : (
        <Icon
          type={
            `mediacircled-${
              direction === 'left' ? 'previous' : 'next'
            }-40` as const
          }
        />
      )}
    </Click>
  );
};

const arrowCSS: (supportsRtl: boolean) => ExtendCSS =
  () =>
  ({ theme: { color } }) => ({
    position: 'absolute',
    top: '50%',
    color: color.foreground.primary,
    marginTop: -19,
    // prevent double-tap zoom with touch-action
    touchAction: 'manipulation',

    '&[disabled]': {
      cursor: 'auto',
      opacity: 0,
    },
  });

const arrowLeftCSS: ExtendCSS = {
  left: '1.5625rem',
};

const arrowRightCSS: ExtendCSS = {
  right: '1.5625rem',
};
