import React from 'react';
import SVG from './svg';

const Blogger: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 22.29A1.72 1.72 0 0122.29 24H1.72A1.72 1.72 0 010 22.29V1.71A1.72 1.72 0 011.71 0h20.57A1.71 1.71 0 0124 1.71v20.58z"
    />
    <path
      d="M22.28 23.86H1.71A1.72 1.72 0 010 22.14v.15A1.72 1.72 0 001.71 24h20.57A1.71 1.71 0 0024 22.29v-.15a1.71 1.71 0 01-1.72 1.72z"
      fillOpacity=".2"
      fill="#3e2723"
    />
    <path
      d="M22.28 0H1.71A1.72 1.72 0 000 1.71v.15A1.71 1.71 0 011.71.15h20.57A1.7 1.7 0 0124 1.86v-.14A1.71 1.71 0 0022.28 0z"
      fill="#fff"
      fillOpacity=".2"
    />
    <path
      d="M18 10.29h-.57a.87.87 0 01-.86-.86 4.28 4.28 0 00-4.28-4.28H9.43a4.28 4.28 0 00-4.28 4.28v5.15a4.28 4.28 0 004.28 4.28h5.15a4.28 4.28 0 004.28-4.28v-3.43a.85.85 0 00-.86-.86zM9.43 8.57h3.43a.86.86 0 01.85.86.85.85 0 01-.85.85H9.43a.86.86 0 110-1.71zm5.14 6.86H9.42a.86.86 0 01-.85-.86.85.85 0 01.85-.85h5.15a.86.86 0 110 1.71z"
      fill="#f1f1f1"
    />
    <path
      d="M9.43 13.86h5.15a.86.86 0 01.85.78v-.07a.86.86 0 00-.86-.85H9.44a.85.85 0 00-.86.85v.07a.83.83 0 01.85-.78zm0-5.14h3.43a.86.86 0 01.85.78v-.07a.85.85 0 00-.86-.85H9.44a.85.85 0 00-.86.85v.07a.83.83 0 01.85-.78zm5.14 10.14H9.42a4.28 4.28 0 01-4.28-4.28v.14A4.29 4.29 0 009.42 19h5.15a4.29 4.29 0 004.28-4.29v-.14a4.27 4.27 0 01-4.28 4.29z"
      fillOpacity=".1"
      fill="#3e2723"
    />
    <path
      d="M14.57 15.43H9.42a.86.86 0 01-.85-.79v.08a.86.86 0 00.86.85h5.14a.85.85 0 00.86-.85v-.08a.84.84 0 01-.86.79zM18 10.29h-.57a.87.87 0 01-.86-.86 4.28 4.28 0 00-4.28-4.28H9.43a4.28 4.28 0 00-4.28 4.28v.15a4.29 4.29 0 014.28-4.29h2.86a4.29 4.29 0 014.28 4.29.86.86 0 00.86.85H18a.85.85 0 01.85.86v-.15a.84.84 0 00-.85-.85zm-8.57.14h3.43a.85.85 0 00.85-.85.22.22 0 000-.08.85.85 0 01-.85.79H9.42a.86.86 0 01-.85-.79v.08a.87.87 0 00.86.85z"
      fillOpacity=".4"
      fill="#fff"
    />
  </SVG>
);

export default Blogger;
