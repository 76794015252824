import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import { deployEnv } from '@vcc-www/constants/config';

export const shouldExclude = (
  searchParams: URLSearchParams,
  componentName?: string,
) => {
  return (
    deployEnv !== 'prod' &&
    componentName &&
    searchParams
      .get('perf')
      ?.toLowerCase()
      .includes(componentName.toLowerCase())
  );
};

const useExcludeComponent = (componentName?: string) => {
  const { searchParams } = useVolvoCarsUrl();
  return shouldExclude(searchParams, componentName);
};
export default useExcludeComponent;
