import React from 'react';
import { ExtendCSS } from 'vcc-ui';
import {
  ComponentRendering,
  HtmlElementRendering,
} from '@vcc-www/api/sitecore9/SitecoreTypes';
import * as config from '@vcc-www/constants/config';
import { useComponentIndexStore } from './useComponentIndex';
import EditorialComponentWrapper from './EditorialComponentWrapper';
import componentResolver from './jss-components/componentResolver';
import WarningComponent from './WarningComponent';
import useExcludeComponent from './useExcludeComponent';
type Props = {
  extend?: ExtendCSS;
  withStickyChildren?: boolean;
  renderings?: (ComponentRendering | HtmlElementRendering)[];
  customCategoryFn?: (componentName: string, index: number) => string;
};

const JSSPlaceholderRenderer: React.FC<Props> = (props) => {
  const { renderings = [], extend } = props;
  const { customCategoryFn, withStickyChildren } = props;
  const getRenderingComponentIndex = useComponentIndexStore();
  const componentName = (renderings[0] as ComponentRendering)?.componentName;
  const shouldNotRender = useExcludeComponent(componentName);
  if (shouldNotRender) return null;
  const components = renderings
    .filter((rendering): rendering is ComponentRendering => 'uid' in rendering)
    .map((rendering, idx) => {
      const ResolvedComponent = componentResolver(rendering.componentName);
      if (!ResolvedComponent) {
        if (config.deployEnv === 'dev' || config.deployEnv === 'test') {
          return <WarningComponent key={rendering.uid ?? idx} {...rendering} />;
        }
        return null;
      }
      const componentIndex = getRenderingComponentIndex(rendering);

      return (
        <EditorialComponentWrapper
          key={rendering.uid}
          componentName={rendering.componentName}
          componentIndex={componentIndex}
          customCategoryFn={customCategoryFn}
          withStickyChildren={withStickyChildren}
          extend={extend}
        >
          <ResolvedComponent
            fields={rendering.fields}
            params={rendering.params}
          />
        </EditorialComponentWrapper>
      );
    });
  return <>{components}</>;
};

export default JSSPlaceholderRenderer;
