import React from 'react';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries/DictionariesProvider';
import Badge from '../Badge';

export const AvailableOnline: React.FC<{
  children?: React.ReactNode | ((element: JSX.Element) => JSX.Element);
}> = ({ children, ...props }) => {
  const translate = useSharedComponentsTranslate();
  const label = translate('CarBadges.badgeLabels.availableOnline');
  if (!label) return null;
  const BadgeWithLabel = <Badge label={label} {...props} />;
  if (!children) return BadgeWithLabel;
  if (typeof children === 'function') {
    return children(BadgeWithLabel);
  }
  throw Error(
    'A function is the only allowed child for AvailableOnline component',
  );
};
