// THIS FILE IS AUTO-GENERATED
// See scripts/codegen/generateComponentResolver.ts to modify the generation of this file.
import React from "react";
import loadable from "@loadable/component";
import { SitecoreProps } from "@vcc-www/api/sitecore9/SitecoreTypes";

const Loading = () => <div />; // eslint-disable-line @typescript-eslint/no-unused-vars

const ARButton = loadable(
  () => import(/* webpackChunkName: "ARButton" */ "./JSSARButton"),
);
const AdvancedFaq = loadable(
  () => import(/* webpackChunkName: "AdvancedFaq" */ "./JSSAdvancedFaq"),
);
const AnimatedHero = loadable(
  () => import(/* webpackChunkName: "AnimatedHero" */ "./JSSAnimatedHero"),
);
const AnimatedStatement = loadable(
  () =>
    import(
      /* webpackChunkName: "AnimatedStatement" */ "./JSSAnimatedStatement"
    ),
);
const AppDownload = loadable(
  () => import(/* webpackChunkName: "AppDownload" */ "./JSSAppDownload"),
);
const AppDownloadSection = loadable(
  () =>
    import(
      /* webpackChunkName: "AppDownloadSection" */ "./JSSAppDownloadSection"
    ),
);
const AppList = loadable(
  () => import(/* webpackChunkName: "AppList" */ "./JSSAppList"),
);
const ArticleSection = loadable(
  () => import(/* webpackChunkName: "ArticleSection" */ "./JSSArticleSection"),
);
const ArticleTeaser = loadable(
  () => import(/* webpackChunkName: "ArticleTeaser" */ "./JSSArticleTeaser"),
);
const CBVFooter = loadable(
  () => import(/* webpackChunkName: "CBVFooter" */ "./JSSCBVFooter"),
);
const CTALink = loadable(
  () => import(/* webpackChunkName: "CTALink" */ "./JSSCTALink"),
);
const Callouts = loadable(
  () => import(/* webpackChunkName: "Callouts" */ "./JSSCallouts"),
);
const CalloutsVideo = loadable(
  () => import(/* webpackChunkName: "CalloutsVideo" */ "./JSSCalloutsVideo"),
);
const Campaign = loadable(
  () => import(/* webpackChunkName: "Campaign" */ "./JSSCampaign"),
);
const CarComparisonCards = loadable(
  () =>
    import(
      /* webpackChunkName: "CarComparisonCards" */ "./JSSCarComparisonCards"
    ),
);
const CarouselTimeline = loadable(
  () =>
    import(/* webpackChunkName: "CarouselTimeline" */ "./JSSCarouselTimeline"),
);
const ChargeCalculator = loadable(
  () =>
    import(/* webpackChunkName: "ChargeCalculator" */ "./JSSChargeCalculator"),
);
const ChargingCost = loadable(
  () => import(/* webpackChunkName: "ChargingCost" */ "./JSSChargingCost"),
);
const ChargingTypeComparison = loadable(
  () =>
    import(
      /* webpackChunkName: "ChargingTypeComparison" */ "./JSSChargingTypeComparison"
    ),
);
const ChinaTestDriveForm = loadable(
  () =>
    import(
      /* webpackChunkName: "ChinaTestDriveForm" */ "./JSSChinaTestDriveForm"
    ),
);
const ChinaTestDriveFormFooter = loadable(
  () =>
    import(
      /* webpackChunkName: "ChinaTestDriveFormFooter" */ "./JSSChinaTestDriveFormFooter"
    ),
);
const ChinaTestDriveSectionCover = loadable(
  () =>
    import(
      /* webpackChunkName: "ChinaTestDriveSectionCover" */ "./JSSChinaTestDriveSectionCover"
    ),
);
const ClientCarousel = loadable(
  () => import(/* webpackChunkName: "ClientCarousel" */ "./JSSClientCarousel"),
);
const ContactModalAndOverlay = loadable(
  () =>
    import(
      /* webpackChunkName: "ContactModalAndOverlay" */ "./JSSContactModalAndOverlay"
    ),
);
const Disclaimer = loadable(
  () => import(/* webpackChunkName: "Disclaimer" */ "./JSSDisclaimer"),
);
const DocumentDownload = loadable(
  () =>
    import(/* webpackChunkName: "DocumentDownload" */ "./JSSDocumentDownload"),
);
const DocumentList = loadable(
  () => import(/* webpackChunkName: "DocumentList" */ "./JSSDocumentList"),
);
const EnergyEfficiencyLabel = loadable(
  () =>
    import(
      /* webpackChunkName: "EnergyEfficiencyLabel" */ "./JSSEnergyEfficiencyLabel"
    ),
);
const ExperienceOverlay = loadable(
  () =>
    import(
      /* webpackChunkName: "ExperienceOverlay" */ "./JSSExperienceOverlay"
    ),
);
const ExteriorColorSelector = loadable(
  () =>
    import(
      /* webpackChunkName: "ExteriorColorSelector" */ "./JSSExteriorColorSelector"
    ),
);
const ExteriorColorSelectorCce = loadable(
  () =>
    import(
      /* webpackChunkName: "ExteriorColorSelectorCce" */ "./JSSExteriorColorSelectorCce"
    ),
);
const Faqs = loadable(() => import(/* webpackChunkName: "Faqs" */ "./JSSFaqs"));
const FlatCardList = loadable(
  () => import(/* webpackChunkName: "FlatCardList" */ "./JSSFlatCardList"),
);
const FullscreenMessage = loadable(
  () =>
    import(
      /* webpackChunkName: "FullscreenMessage" */ "./JSSFullscreenMessage"
    ),
);
const GalleryGrid = loadable(
  () => import(/* webpackChunkName: "GalleryGrid" */ "./JSSGalleryGrid"),
);
const GeoRedirect = loadable(
  () => import(/* webpackChunkName: "GeoRedirect" */ "./JSSGeoRedirect"),
);
const Hero = loadable(() => import(/* webpackChunkName: "Hero" */ "./JSSHero"));
const HighlightFeatures = loadable(
  () =>
    import(
      /* webpackChunkName: "HighlightFeatures" */ "./JSSHighlightFeatures"
    ),
);
const HighlightsPartnerLogos = loadable(
  () =>
    import(
      /* webpackChunkName: "HighlightsPartnerLogos" */ "./JSSHighlightsPartnerLogos"
    ),
);
const ILSafetyAndPollution = loadable(
  () =>
    import(
      /* webpackChunkName: "ILSafetyAndPollution" */ "./JSSILSafetyAndPollution"
    ),
);
const IconCallouts = loadable(
  () => import(/* webpackChunkName: "IconCallouts" */ "./JSSIconCallouts"),
);
const IconCalloutsWithTabs = loadable(
  () =>
    import(
      /* webpackChunkName: "IconCalloutsWithTabs" */ "./JSSIconCalloutsWithTabs"
    ),
);
const IconStatement = loadable(
  () => import(/* webpackChunkName: "IconStatement" */ "./JSSIconStatement"),
);
const ImageWithText = loadable(
  () => import(/* webpackChunkName: "ImageWithText" */ "./JSSImageWithText"),
);
const ImageWithTextAndIconCallouts = loadable(
  () =>
    import(
      /* webpackChunkName: "ImageWithTextAndIconCallouts" */ "./JSSImageWithTextAndIconCallouts"
    ),
);
const ImageWithTextAndMarketingLinks = loadable(
  () =>
    import(
      /* webpackChunkName: "ImageWithTextAndMarketingLinks" */ "./JSSImageWithTextAndMarketingLinks"
    ),
);
const IndexWithImages = loadable(
  () =>
    import(/* webpackChunkName: "IndexWithImages" */ "./JSSIndexWithImages"),
);
const InfoBanner = loadable(
  () => import(/* webpackChunkName: "InfoBanner" */ "./JSSInfoBanner"),
);
const InlineMessage = loadable(
  () => import(/* webpackChunkName: "InlineMessage" */ "./JSSInlineMessage"),
);
const Intro = loadable(
  () => import(/* webpackChunkName: "Intro" */ "./JSSIntro"),
);
const ItemListWithOverlay = loadable(
  () =>
    import(
      /* webpackChunkName: "ItemListWithOverlay" */ "./JSSItemListWithOverlay"
    ),
);
const LevelComparisonCce = loadable(
  () =>
    import(
      /* webpackChunkName: "LevelComparisonCce" */ "./JSSLevelComparisonCce"
    ),
);
const LightSavingRangeOrCharging = loadable(
  () =>
    import(
      /* webpackChunkName: "LightSavingRangeOrCharging" */ "./JSSLightSavingRangeOrCharging"
    ),
);
const LinkedinMedia = loadable(
  () => import(/* webpackChunkName: "LinkedinMedia" */ "./JSSLinkedinMedia"),
);
const ListCarousel = loadable(
  () => import(/* webpackChunkName: "ListCarousel" */ "./JSSListCarousel"),
);
const LocalSubmenu = loadable(
  () => import(/* webpackChunkName: "LocalSubmenu" */ "./JSSLocalSubmenu"),
);
const MarketingLinks = loadable(
  () => import(/* webpackChunkName: "MarketingLinks" */ "./JSSMarketingLinks"),
);
const MediaHighlights = loadable(
  () =>
    import(/* webpackChunkName: "MediaHighlights" */ "./JSSMediaHighlights"),
);
const ModelIntro = loadable(
  () => import(/* webpackChunkName: "ModelIntro" */ "./JSSModelIntro"),
);
const ModelRatings = loadable(
  () => import(/* webpackChunkName: "ModelRatings" */ "./JSSModelRatings"),
);
const ModelStats = loadable(
  () => import(/* webpackChunkName: "ModelStats" */ "./JSSModelStats"),
);
const ModelTitle = loadable(
  () => import(/* webpackChunkName: "ModelTitle" */ "./JSSModelTitle"),
);
const NewsFeaturedArticles = loadable(
  () =>
    import(
      /* webpackChunkName: "NewsFeaturedArticles" */ "./JSSNewsFeaturedArticles"
    ),
);
const OfferCards = loadable(
  () => import(/* webpackChunkName: "OfferCards" */ "./JSSOfferCards"),
);
const OfferDetailsWithImage = loadable(
  () =>
    import(
      /* webpackChunkName: "OfferDetailsWithImage" */ "./JSSOfferDetailsWithImage"
    ),
);
const OffersAndPromotions = loadable(
  () =>
    import(
      /* webpackChunkName: "OffersAndPromotions" */ "./JSSOffersAndPromotions"
    ),
);
const OffersWithTabs = loadable(
  () => import(/* webpackChunkName: "OffersWithTabs" */ "./JSSOffersWithTabs"),
);
const PdpSubmenu = loadable(
  () => import(/* webpackChunkName: "PdpSubmenu" */ "./JSSPdpSubmenu"),
);
const ProductList = loadable(
  () => import(/* webpackChunkName: "ProductList" */ "./JSSProductList"),
);
const ProductListCarousel = loadable(
  () =>
    import(
      /* webpackChunkName: "ProductListCarousel" */ "./JSSProductListCarousel"
    ),
);
const ProductListCarouselWithFilters = loadable(
  () =>
    import(
      /* webpackChunkName: "ProductListCarouselWithFilters" */ "./JSSProductListCarouselWithFilters"
    ),
);
const ProductWithHighlights = loadable(
  () =>
    import(
      /* webpackChunkName: "ProductWithHighlights" */ "./JSSProductWithHighlights"
    ),
);
const PromotedVehicle = loadable(
  () =>
    import(/* webpackChunkName: "PromotedVehicle" */ "./JSSPromotedVehicle"),
);
const PromotionBackgroundImage = loadable(
  () =>
    import(
      /* webpackChunkName: "PromotionBackgroundImage" */ "./JSSPromotionBackgroundImage"
    ),
);
const PromotionalHero = loadable(
  () =>
    import(/* webpackChunkName: "PromotionalHero" */ "./JSSPromotionalHero"),
);
const PromotionalHeroWithoutCtas = loadable(
  () =>
    import(
      /* webpackChunkName: "PromotionalHeroWithoutCtas" */ "./JSSPromotionalHeroWithoutCtas"
    ),
);
const PromotionsTextImage = loadable(
  () =>
    import(
      /* webpackChunkName: "PromotionsTextImage" */ "./JSSPromotionsTextImage"
    ),
);
const PromotionsTextOnly = loadable(
  () =>
    import(
      /* webpackChunkName: "PromotionsTextOnly" */ "./JSSPromotionsTextOnly"
    ),
);
const RangeCalculator = loadable(
  () =>
    import(/* webpackChunkName: "RangeCalculator" */ "./JSSRangeCalculator"),
);
const ScatteredGallery = loadable(
  () =>
    import(/* webpackChunkName: "ScatteredGallery" */ "./JSSScatteredGallery"),
);
const ScrollableAccordion = loadable(
  () =>
    import(
      /* webpackChunkName: "ScrollableAccordion" */ "./JSSScrollableAccordion"
    ),
);
const ServicePromotion = loadable(
  () =>
    import(/* webpackChunkName: "ServicePromotion" */ "./JSSServicePromotion"),
);
const SimpleColorPicker = loadable(
  () =>
    import(
      /* webpackChunkName: "SimpleColorPicker" */ "./JSSSimpleColorPicker"
    ),
);
const SliderWithIcons = loadable(
  () =>
    import(/* webpackChunkName: "SliderWithIcons" */ "./JSSSliderWithIcons"),
);
const SmallCardList = loadable(
  () => import(/* webpackChunkName: "SmallCardList" */ "./JSSSmallCardList"),
);
const StaticGalleryGrid = loadable(
  () =>
    import(
      /* webpackChunkName: "StaticGalleryGrid" */ "./JSSStaticGalleryGrid"
    ),
);
const TextStatement = loadable(
  () => import(/* webpackChunkName: "TextStatement" */ "./JSSTextStatement"),
);
const TextStatementWithLine = loadable(
  () =>
    import(
      /* webpackChunkName: "TextStatementWithLine" */ "./JSSTextStatementWithLine"
    ),
);
const Timeline = loadable(
  () => import(/* webpackChunkName: "Timeline" */ "./JSSTimeline"),
);
const TwoTilesGrid = loadable(
  () => import(/* webpackChunkName: "TwoTilesGrid" */ "./JSSTwoTilesGrid"),
);
const VariantComparison = loadable(
  () =>
    import(
      /* webpackChunkName: "VariantComparison" */ "./JSSVariantComparison"
    ),
);
const VariantComparisonCce = loadable(
  () =>
    import(
      /* webpackChunkName: "VariantComparisonCce" */ "./JSSVariantComparisonCce"
    ),
);
const Video = loadable(
  () => import(/* webpackChunkName: "Video" */ "./JSSVideo"),
);
const VideoWithPreview = loadable(
  () =>
    import(/* webpackChunkName: "VideoWithPreview" */ "./JSSVideoWithPreview"),
);

const components = new Map<string, React.ComponentType<SitecoreProps>>();
components.set("ARButton", ARButton);
components.set("AdvancedFaq", AdvancedFaq);
components.set("AnimatedHero", AnimatedHero);
components.set("AnimatedStatement", AnimatedStatement);
components.set("AppDownload", AppDownload);
components.set("AppDownloadSection", AppDownloadSection);
components.set("AppList", AppList);
components.set("ArticleSection", ArticleSection);
components.set("ArticleTeaser", ArticleTeaser);
components.set("CBVFooter", CBVFooter);
components.set("CTALink", CTALink);
components.set("Callouts", Callouts);
components.set("CalloutsVideo", CalloutsVideo);
components.set("Campaign", Campaign);
components.set("CarComparisonCards", CarComparisonCards);
components.set("CarouselTimeline", CarouselTimeline);
components.set("ChargeCalculator", ChargeCalculator);
components.set("ChargingCost", ChargingCost);
components.set("ChargingTypeComparison", ChargingTypeComparison);
components.set("ChinaTestDriveForm", ChinaTestDriveForm);
components.set("ChinaTestDriveFormFooter", ChinaTestDriveFormFooter);
components.set("ChinaTestDriveSectionCover", ChinaTestDriveSectionCover);
components.set("ClientCarousel", ClientCarousel);
components.set("ContactModalAndOverlay", ContactModalAndOverlay);
components.set("Disclaimer", Disclaimer);
components.set("DocumentDownload", DocumentDownload);
components.set("DocumentList", DocumentList);
components.set("EnergyEfficiencyLabel", EnergyEfficiencyLabel);
components.set("ExperienceOverlay", ExperienceOverlay);
components.set("ExteriorColorSelector", ExteriorColorSelector);
components.set("ExteriorColorSelectorCce", ExteriorColorSelectorCce);
components.set("Faqs", Faqs);
components.set("FlatCardList", FlatCardList);
components.set("FullscreenMessage", FullscreenMessage);
components.set("GalleryGrid", GalleryGrid);
components.set("GeoRedirect", GeoRedirect);
components.set("Hero", Hero);
components.set("HighlightFeatures", HighlightFeatures);
components.set("HighlightsPartnerLogos", HighlightsPartnerLogos);
components.set("ILSafetyAndPollution", ILSafetyAndPollution);
components.set("IconCallouts", IconCallouts);
components.set("IconCalloutsWithTabs", IconCalloutsWithTabs);
components.set("IconStatement", IconStatement);
components.set("ImageWithText", ImageWithText);
components.set("ImageWithTextAndIconCallouts", ImageWithTextAndIconCallouts);
components.set(
  "ImageWithTextAndMarketingLinks",
  ImageWithTextAndMarketingLinks,
);
components.set("IndexWithImages", IndexWithImages);
components.set("InfoBanner", InfoBanner);
components.set("InlineMessage", InlineMessage);
components.set("Intro", Intro);
components.set("ItemListWithOverlay", ItemListWithOverlay);
components.set("LevelComparisonCce", LevelComparisonCce);
components.set("LightSavingRangeOrCharging", LightSavingRangeOrCharging);
components.set("LinkedinMedia", LinkedinMedia);
components.set("ListCarousel", ListCarousel);
components.set("LocalSubmenu", LocalSubmenu);
components.set("MarketingLinks", MarketingLinks);
components.set("MediaHighlights", MediaHighlights);
components.set("ModelIntro", ModelIntro);
components.set("ModelRatings", ModelRatings);
components.set("ModelStats", ModelStats);
components.set("ModelTitle", ModelTitle);
components.set("NewsFeaturedArticles", NewsFeaturedArticles);
components.set("OfferCards", OfferCards);
components.set("OfferDetailsWithImage", OfferDetailsWithImage);
components.set("OffersAndPromotions", OffersAndPromotions);
components.set("OffersWithTabs", OffersWithTabs);
components.set("PdpSubmenu", PdpSubmenu);
components.set("ProductList", ProductList);
components.set("ProductListCarousel", ProductListCarousel);
components.set(
  "ProductListCarouselWithFilters",
  ProductListCarouselWithFilters,
);
components.set("ProductWithHighlights", ProductWithHighlights);
components.set("PromotedVehicle", PromotedVehicle);
components.set("PromotionBackgroundImage", PromotionBackgroundImage);
components.set("PromotionalHero", PromotionalHero);
components.set("PromotionalHeroWithoutCtas", PromotionalHeroWithoutCtas);
components.set("PromotionsTextImage", PromotionsTextImage);
components.set("PromotionsTextOnly", PromotionsTextOnly);
components.set("RangeCalculator", RangeCalculator);
components.set("ScatteredGallery", ScatteredGallery);
components.set("ScrollableAccordion", ScrollableAccordion);
components.set("ServicePromotion", ServicePromotion);
components.set("SimpleColorPicker", SimpleColorPicker);
components.set("SliderWithIcons", SliderWithIcons);
components.set("SmallCardList", SmallCardList);
components.set("StaticGalleryGrid", StaticGalleryGrid);
components.set("TextStatement", TextStatement);
components.set("TextStatementWithLine", TextStatementWithLine);
components.set("Timeline", Timeline);
components.set("TwoTilesGrid", TwoTilesGrid);
components.set("VariantComparison", VariantComparison);
components.set("VariantComparisonCce", VariantComparisonCce);
components.set("Video", Video);
components.set("VideoWithPreview", VideoWithPreview);

export default function componentResolver(componentName: string) {
  return components.get(componentName);
}
