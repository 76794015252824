import React, { ComponentType } from 'react';
import { ComponentRendering, SitecoreProps } from './SitecoreTypes';
import layoutServiceAdapter from './layoutServiceAdapter';

/**
 * Returns a component with `fields` un-nested in a `content` object,
 * and `params` spread over the component.
 */
export default function withSitecoreLayoutServiceAdapter<
  C extends ComponentType<React.PropsWithChildren<any>>,
>(WrappedComponent: C) {
  const SitecoreUnwrapped: React.FC<
    React.PropsWithChildren<ComponentRendering | SitecoreProps>
  > = (props) => {
    const { fields, params, ...otherProps } = props;
    if (isEmpty(fields)) {
      return null;
    }
    const transformedProps = layoutServiceAdapter(fields, params);
    return <WrappedComponent {...transformedProps} {...(otherProps as any)} />;
  };

  SitecoreUnwrapped.displayName = `SitecoreLayoutServiceAdapter(${getDisplayName(
    WrappedComponent,
  )})`;
  return SitecoreUnwrapped;
}

function getDisplayName(
  WrappedComponent: ComponentType<React.PropsWithChildren<any>>,
) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function isEmpty(obj: unknown) {
  return (
    typeof obj !== 'object' ||
    !obj ||
    Object.values(obj).every((val) => val == null || val === '')
  );
}
