import React from 'react';
import SVG from './svg';

const Pinterest: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2C6.479 2 2 6.477 2 12c0 4.094 2.463 7.613 5.987 9.16-.028-.698-.005-1.538.173-2.297.193-.812 1.286-5.449 1.286-5.449s-.318-.638-.318-1.583c0-1.481.86-2.588 1.928-2.588.91 0 1.35.683 1.35 1.502 0 .913-.584 2.281-.884 3.548-.25 1.062.531 1.926 1.578 1.926 1.895 0 3.17-2.433 3.17-5.317 0-2.19-1.475-3.831-4.16-3.831-3.033 0-4.923 2.261-4.923 4.787 0 .873.257 1.487.659 1.962.186.22.21.307.143.558-.047.183-.157.627-.203.803-.067.252-.272.344-.5.25-1.398-.57-2.049-2.1-2.049-3.82 0-2.84 2.396-6.249 7.147-6.249 3.818 0 6.331 2.765 6.331 5.73 0 3.923-2.18 6.853-5.396 6.853-1.079 0-2.095-.583-2.443-1.245 0 0-.58 2.304-.703 2.748-.212.77-.627 1.542-1.007 2.142.9.265 1.85.41 2.835.41C17.523 22 22 17.523 22 12S17.523 2 12 2z"
      clipRule="evenodd"
    />
  </SVG>
);

export default Pinterest;
