import React from 'react';
import SVG from './svg';

const ChargingAcEu: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SVG viewBox="0 0 48 48" fill="none">
      <rect width="47.9999" height="48" fill="none" />
      <path
        d="M20.0164 8.88158C20.825 8.88158 21.4805 8.22608 21.4805 7.41747C21.4805 6.60887 20.825 5.95337 20.0164 5.95337C19.2077 5.95337 18.5522 6.60887 18.5522 7.41747C18.5522 8.22608 19.2077 8.88158 20.0164 8.88158Z"
        stroke="#707070"
      />
      <path
        d="M28.3464 8.88158C29.155 8.88158 29.8105 8.22608 29.8105 7.41747C29.8105 6.60887 29.155 5.95337 28.3464 5.95337C27.5378 5.95337 26.8823 6.60887 26.8823 7.41747C26.8823 8.22608 27.5378 8.88158 28.3464 8.88158Z"
        stroke="#707070"
      />
      <path
        d="M20.0779 23.7571C21.3369 23.7571 22.3575 22.7365 22.3575 21.4776C22.3575 20.2186 21.3369 19.198 20.0779 19.198C18.8189 19.198 17.7983 20.2186 17.7983 21.4776C17.7983 22.7365 18.8189 23.7571 20.0779 23.7571Z"
        stroke="#707070"
      />
      <path
        d="M28.3689 23.7571C29.6279 23.7571 30.6485 22.7365 30.6485 21.4776C30.6485 20.2186 29.6279 19.198 28.3689 19.198C27.1099 19.198 26.0894 20.2186 26.0894 21.4776C26.0894 22.7365 27.1099 23.7571 28.3689 23.7571Z"
        stroke="#707070"
      />
      <path
        d="M15.7708 16.2195C17.0297 16.2195 18.0503 15.1989 18.0503 13.94C18.0503 12.681 17.0297 11.6604 15.7708 11.6604C14.5118 11.6604 13.4912 12.681 13.4912 13.94C13.4912 15.1989 14.5118 16.2195 15.7708 16.2195Z"
        stroke="#707070"
      />
      <path
        d="M32.5681 16.2195C33.8271 16.2195 34.8477 15.1989 34.8477 13.94C34.8477 12.681 33.8271 11.6604 32.5681 11.6604C31.3092 11.6604 30.2886 12.681 30.2886 13.94C30.2886 15.1989 31.3092 16.2195 32.5681 16.2195Z"
        stroke="#707070"
      />
      <path
        d="M24.1697 15.6812C25.4287 15.6812 26.4493 14.6606 26.4493 13.4016C26.4493 12.1427 25.4287 11.1221 24.1697 11.1221C22.9107 11.1221 21.8901 12.1427 21.8901 13.4016C21.8901 14.6606 22.9107 15.6812 24.1697 15.6812Z"
        stroke="#707070"
      />
      <path
        d="M14.9051 1V0.5H14.7476L14.6185 0.590276L14.9051 1ZM33.3985 1L33.6851 0.590276L33.556 0.5H33.3985V1ZM8.5 14.2448C8.5 8.93365 11.1449 4.2402 15.1917 1.40972L14.6185 0.590276C10.3157 3.59983 7.5 8.59345 7.5 14.2448H8.5ZM24.1518 29.8966C15.5076 29.8966 8.5 22.889 8.5 14.2448H7.5C7.5 23.4413 14.9553 30.8966 24.1518 30.8966V29.8966ZM39.8036 14.2448C39.8036 22.889 32.7961 29.8966 24.1518 29.8966V30.8966C33.3483 30.8966 40.8036 23.4413 40.8036 14.2448H39.8036ZM33.1119 1.40972C37.1587 4.2402 39.8036 8.93365 39.8036 14.2448H40.8036C40.8036 8.59345 37.9879 3.59983 33.6851 0.590276L33.1119 1.40972ZM33.3985 0.5H14.9051V1.5H33.3985V0.5Z"
        fill="#707070"
      />
      <path
        d="M16.5069 3.58398V3.08398H16.3494L16.2203 3.17426L16.5069 3.58398ZM31.7951 3.58398L32.0817 3.17426L31.9526 3.08398H31.7951V3.58398ZM11.2988 14.5329C11.2988 10.1719 13.4704 6.31805 16.7935 3.9937L16.2203 3.17426C12.6412 5.67769 10.2988 9.83171 10.2988 14.5329H11.2988ZM24.151 27.3851C17.0529 27.3851 11.2988 21.6309 11.2988 14.5329H10.2988C10.2988 22.1832 16.5007 28.3851 24.151 28.3851V27.3851ZM37.0031 14.5329C37.0031 21.6309 31.249 27.3851 24.151 27.3851V28.3851C31.8013 28.3851 38.0031 22.1832 38.0031 14.5329H37.0031ZM31.5085 3.9937C34.8316 6.31805 37.0031 10.1719 37.0031 14.5329H38.0031C38.0031 9.83171 35.6608 5.67769 32.0817 3.17426L31.5085 3.9937ZM31.7951 3.08398H16.5069V4.08398H31.7951V3.08398Z"
        fill="#707070"
      />
    </SVG>
  );
};

export default ChargingAcEu;
