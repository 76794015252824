import React from 'react';
import SVG from './svg';

const Marker = () => {
  return (
    <SVG viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.23 12.3549C11.6989 10.5819 13 8.39077 13 6.30303C13 4.56853 12.414 3.25726 11.5364 2.38176C10.6546 1.50207 9.41634 1 8 1C6.58366 1 5.34542 1.50207 4.46362 2.38176C3.58601 3.25726 3 4.56853 3 6.30303C3 8.39077 4.30108 10.5819 5.77004 12.3549C6.48611 13.2191 7.20481 13.9398 7.74515 14.4446C7.83557 14.5291 7.92081 14.6073 8 14.679C8.07919 14.6073 8.16443 14.5291 8.25485 14.4446C8.79519 13.9398 9.51389 13.2191 10.23 12.3549ZM14 6.30303C14 11.2133 8 16 8 16C8 16 2 11.2133 2 6.30303C2 2.36246 4.68629 0 8 0C11.3137 0 14 2.36246 14 6.30303Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8ZM8 9C9.65685 9 11 7.65685 11 6C11 4.34315 9.65685 3 8 3C6.34315 3 5 4.34315 5 6C5 7.65685 6.34315 9 8 9Z"
        fill="currentColor"
      />
    </SVG>
  );
};
export default Marker;
