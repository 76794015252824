export const CLIENT_NAME = 'cce';

const ENVS: Record<string, string> = {
  staging: 'qagraph',
  prod: 'graph',
};

const CCE_URL = 'https://{env}.volvocars.com';

export const getCceUrl = (env: string = 'prod') => {
  return CCE_URL.replace('{env}', ENVS[env]);
};
