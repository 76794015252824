import React from 'react';
import SVG from './svg';

const Weibo: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17,11.7c-.28-.09-.47-.14-.32-.51a2.07,2.07,0,0,0,0-2c-.65-.93-2.43-.88-4.46,0,0,0-.64.28-.48-.23a2.26,2.26,0,0,0-.22-2.33c-1.1-1.11-4,0-6.56,2.56-1.88,1.88-3,3.88-3,5.61,0,3.31,4.24,5.32,8.39,5.32,5.44,0,9.06-3.16,9.06-5.67A3,3,0,0,0,17,11.7ZM10.4,18.91c-3.31.33-6.17-1.17-6.38-3.34s2.29-4.2,5.6-4.53,6.17,1.17,6.39,3.35S13.71,18.59,10.4,18.91Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.64,5.65A5.27,5.27,0,0,0,15.6,4h0a.75.75,0,0,0-.59.9.76.76,0,0,0,.91.59,3.77,3.77,0,0,1,4.37,4.84h0a.76.76,0,1,0,1.45.47h0a5.26,5.26,0,0,0-1.1-5.17"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.62,7.47a2.56,2.56,0,0,0-2.45-.79A.65.65,0,1,0,16.44,8h0a1.28,1.28,0,0,1,1.2.39,1.25,1.25,0,0,1,.26,1.23h0a.66.66,0,0,0,1.26.41,2.58,2.58,0,0,0-.54-2.52"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.59,15a.49.49,0,0,1-.58.21.33.33,0,0,1-.14-.5.49.49,0,0,1,.56-.21.34.34,0,0,1,.16.5M9.53,16.33a1.26,1.26,0,0,1-1.53.5.88.88,0,0,1-.33-1.32A1.26,1.26,0,0,1,9.16,15a.89.89,0,0,1,.37,1.33m1.2-3.61a3.61,3.61,0,0,0-4,1.76A2.45,2.45,0,0,0,8.26,18a3.58,3.58,0,0,0,4.27-1.81,2.47,2.47,0,0,0-1.8-3.45"
      clipRule="evenodd"
    />
  </SVG>
);

export default Weibo;
