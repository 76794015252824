import type { LDClient } from 'launchdarkly-js-client-sdk';
import { initialize } from 'launchdarkly-js-client-sdk';
import { getMarketSite } from '@volvo-cars/market-sites';
import { VolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import {
  launchDarklyClientSideId,
  launchDarklyClientSideGlobalId,
} from '@vcc-www/constants';

export function initializeLDClient(
  url: VolvoCarsUrl,
  carName?: string,
): Promise<LDClient> {
  return new Promise<LDClient>((resolve) => {
    if (!launchDarklyClientSideId) return undefined;
    const { marketName: country, siteSlug } = getMarketSite(url.siteSlug);
    const ldClient = initialize(
      String(launchDarklyClientSideId),
      {
        anonymous: true,
        country,
        custom: {
          carName: carName || '',
          siteSlug: siteSlug || '',
          path: url.pathname,
        },
      },
      {
        sendEvents: false,
        allAttributesPrivate: true,
      },
    );
    ldClient.on('ready', () => resolve(ldClient));
  });
}

export function initializeLDClientGlobal(
  url: VolvoCarsUrl,
  carName?: string,
): Promise<LDClient> {
  return new Promise<LDClient>((resolve) => {
    if (!launchDarklyClientSideGlobalId) return undefined;
    const { marketName: country, siteSlug } = getMarketSite(url.siteSlug);
    const ldClient = initialize(
      String(launchDarklyClientSideGlobalId),
      {
        anonymous: true,
        country,
        custom: {
          carName: carName || '',
          siteSlug: siteSlug || '',
          path: url.pathname,
          ssr: false,
        },
      },
      {
        sendEvents: false,
        allAttributesPrivate: true,
      },
    );
    ldClient.on('ready', () => resolve(ldClient));
  });
}
