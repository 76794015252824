import React from 'react';
import SVG from './svg';

const ChargingDcCh: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SVG viewBox="0 0 48 48" fill="none">
      <rect width="47.9999" height="48" fill="none" />
      <path
        d="M15.6429 28.5041C18.0291 28.5041 19.9635 26.5696 19.9635 24.1834C19.9635 21.7972 18.0291 19.8628 15.6429 19.8628C13.2567 19.8628 11.3223 21.7972 11.3223 24.1834C11.3223 26.5696 13.2567 28.5041 15.6429 28.5041Z"
        stroke="#707070"
      />
      <path
        d="M33.1141 28.5041C35.5003 28.5041 37.4347 26.5696 37.4347 24.1834C37.4347 21.7972 35.5003 19.8628 33.1141 19.8628C30.7279 19.8628 28.7935 21.7972 28.7935 24.1834C28.7935 26.5696 30.7279 28.5041 33.1141 28.5041Z"
        stroke="#707070"
      />
      <path
        d="M24.3501 20.4926C25.3123 20.4926 26.0922 19.7126 26.0922 18.7505C26.0922 17.7883 25.3123 17.0083 24.3501 17.0083C23.3879 17.0083 22.6079 17.7883 22.6079 18.7505C22.6079 19.7126 23.3879 20.4926 24.3501 20.4926Z"
        stroke="#707070"
      />
      <path
        d="M24.354 35.5979C25.676 35.5979 26.7476 34.5263 26.7476 33.2044C26.7476 31.8824 25.676 30.8108 24.354 30.8108C23.0321 30.8108 21.9604 31.8824 21.9604 33.2044C21.9604 34.5263 23.0321 35.5979 24.354 35.5979Z"
        stroke="#707070"
      />
      <path
        d="M17.3852 34.9467C18.3474 34.9467 19.1274 34.1667 19.1274 33.2046C19.1274 32.2424 18.3474 31.4624 17.3852 31.4624C16.4231 31.4624 15.6431 32.2424 15.6431 33.2046C15.6431 34.1667 16.4231 34.9467 17.3852 34.9467Z"
        stroke="#707070"
      />
      <path
        d="M31.3179 34.9467C32.28 34.9467 33.06 34.1667 33.06 33.2046C33.06 32.2424 32.28 31.4624 31.3179 31.4624C30.3557 31.4624 29.5757 32.2424 29.5757 33.2046C29.5757 34.1667 30.3557 34.9467 31.3179 34.9467Z"
        stroke="#707070"
      />
      <path
        d="M17.3852 14.521C18.3474 14.521 19.1274 13.741 19.1274 12.7788C19.1274 11.8166 18.3474 11.0366 17.3852 11.0366C16.4231 11.0366 15.6431 11.8166 15.6431 12.7788C15.6431 13.741 16.4231 14.521 17.3852 14.521Z"
        stroke="#707070"
      />
      <path
        d="M24.3501 14.521C25.3123 14.521 26.0922 13.741 26.0922 12.7788C26.0922 11.8166 25.3123 11.0366 24.3501 11.0366C23.3879 11.0366 22.6079 11.8166 22.6079 12.7788C22.6079 13.741 23.3879 14.521 24.3501 14.521Z"
        stroke="#707070"
      />
      <path
        d="M31.3179 14.521C32.28 14.521 33.06 13.741 33.06 12.7788C33.06 11.8166 32.28 11.0366 31.3179 11.0366C30.3557 11.0366 29.5757 11.8166 29.5757 12.7788C29.5757 13.741 30.3557 14.521 31.3179 14.521Z"
        stroke="#707070"
      />
      <path
        d="M11.5053 6V5.5H11.3271L11.189 5.61277L11.5053 6ZM37.3049 6L37.6212 5.61277L37.4831 5.5H37.3049V6ZM4.5 21.8111C4.5 15.5914 7.35208 10.0382 11.8216 6.38723L11.189 5.61277C6.49694 9.44549 3.5 15.2785 3.5 21.8111H4.5ZM24.4051 41.7162C13.4118 41.7162 4.5 32.8044 4.5 21.8111H3.5C3.5 33.3566 12.8595 42.7162 24.4051 42.7162V41.7162ZM44.3102 21.8111C44.3102 32.8044 35.3984 41.7162 24.4051 41.7162V42.7162C35.9507 42.7162 45.3102 33.3566 45.3102 21.8111H44.3102ZM36.9886 6.38723C41.4581 10.0382 44.3102 15.5914 44.3102 21.8111H45.3102C45.3102 15.2785 42.3133 9.44549 37.6212 5.61277L36.9886 6.38723ZM37.3049 5.5H11.5053V6.5H37.3049V5.5Z"
        fill="#707070"
      />
      <path
        d="M13.5311 8.88477V8.38477H13.3528L13.2148 8.49753L13.5311 8.88477ZM35.2783 8.88477L35.5947 8.49753L35.4566 8.38477H35.2783V8.88477ZM7.70459 22.2124C7.70459 16.9942 10.0973 12.3352 13.8474 9.272L13.2148 8.49753C9.24218 11.7425 6.70459 16.6813 6.70459 22.2124H7.70459ZM24.4047 38.9125C15.1815 38.9125 7.70459 31.4356 7.70459 22.2124H6.70459C6.70459 31.9879 14.6292 39.9125 24.4047 39.9125V38.9125ZM41.1048 22.2124C41.1048 31.4356 33.6279 38.9125 24.4047 38.9125V39.9125C34.1802 39.9125 42.1048 31.9879 42.1048 22.2124H41.1048ZM34.962 9.272C38.7121 12.3352 41.1048 16.9942 41.1048 22.2124H42.1048C42.1048 16.6813 39.5672 11.7425 35.5947 8.49753L34.962 9.272ZM35.2783 8.38477H13.5311V9.38477H35.2783V8.38477Z"
        fill="#707070"
      />
    </SVG>
  );
};

export default ChargingDcCh;
