import React from 'react';
import SVG from './svg';

const Mixed: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SVG viewBox="0 0 32 32" fill="none">
      <path
        d="M10.9008 3.99756L20.2048 13.5999H19.0908L10.9008 5.14721L2.71062 13.5999H1.59668L10.9008 3.99756Z"
        fill="#707070"
      />
      <path
        d="M30.4014 13.5998L21.215 4.1189L16.9541 8.51635L17.5111 9.09123L21.215 5.26855L29.2874 13.5998H30.4014Z"
        fill="#707070"
      />
      <path d="M15.6152 17.6V15.2H16.4152V17.6H15.6152Z" fill="#707070" />
      <path d="M15.6152 20V24H16.4152V20H15.6152Z" fill="#707070" />
      <path
        d="M15.6152 31.1999V26.3999H16.4152V31.1999H15.6152Z"
        fill="#707070"
      />
      <path
        d="M4.7998 31.1997L11.6655 15.2H12.536L5.67035 31.1997H4.7998Z"
        fill="#707070"
      />
      <path
        d="M20.3569 15.2H19.4863L26.352 31.1997H27.2226L20.3569 15.2Z"
        fill="#707070"
      />
    </SVG>
  );
};

export default Mixed;
