import React from 'react';
import SVG from './svg';

const Vkontakte: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m20.6 3.4c-1.41-1.4-3.67-1.4-8.18-1.4h-.84c-4.51 0-6.77 0-8.18 1.4s-1.4 3.67-1.4 8.18v.84c0 4.51 0 6.77 1.4 8.18s3.67 1.4 8.18 1.4h.84c4.51 0 6.77 0 8.18-1.4s1.4-3.67 1.4-8.18v-.84c0-4.51 0-6.77-1.4-8.18zm-2.35 12.77h-1.46c-.55 0-.72-.45-1.7-1.44-.87-.83-1.24-.94-1.46-.94s-.38.08-.38.5v1.31c0 .36-.11.57-1 .57a5.69 5.69 0 0 1 -4.49-2.67 11.68 11.68 0 0 1 -2.3-4.84c0-.21.08-.41.5-.41h1.46c.37 0 .51.16.65.57.71 2.07 1.92 3.89 2.41 3.89.19 0 .27-.09.27-.56v-2.15c-.05-1-.58-1.07-.58-1.42a.35.35 0 0 1 .37-.34h2.29c.31 0 .42.17.42.54v2.89c0 .3.14.41.23.41s.34-.11.68-.45a12.1 12.1 0 0 0 1.79-3 .63.63 0 0 1 .64-.41h1.41c.45 0 .54.23.45.54a18.42 18.42 0 0 1 -2 3.35c-.15.25-.21.37 0 .65s.67.65 1 1.05a6.3 6.3 0 0 1 1.23 1.71c.2.48-.01.65-.43.65z"
      clipRule="evenodd"
    />
  </SVG>
);

export default Vkontakte;
