import React from 'react';
import SVG from './svg';

const StarFilled: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.43902 15.0698L0 8.93023L8.78049 7.81395L12.2927 0L15.8049 7.81395L24 8.93023L18.1463 15.0698L19.9024 24L12.2927 19.5349L4.68293 24L6.43902 15.0698Z"
      clipRule="evenodd"
    />
  </SVG>
);

export default StarFilled;
