import React from 'react';
import SVG from './svg';

const ChargingAcJp: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SVG viewBox="0 0 48 48" fill="none">
      <rect width="47.9999" height="48" fill="none" />
      <path
        d="M24.1845 42.6726C34.3113 42.6726 42.5208 34.4632 42.5208 24.3363C42.5208 14.2094 34.3113 6 24.1845 6C14.0576 6 5.84814 14.2094 5.84814 24.3363C5.84814 34.4632 14.0576 42.6726 24.1845 42.6726Z"
        stroke="#707070"
      />
      <path
        d="M24.2167 39.2137C32.4333 39.2137 39.0941 32.5529 39.0941 24.3364C39.0941 16.1198 32.4333 9.45898 24.2167 9.45898C16.0002 9.45898 9.33936 16.1198 9.33936 24.3364C9.33936 32.5529 16.0002 39.2137 24.2167 39.2137Z"
        stroke="#707070"
      />
      <path
        d="M17.6015 21.2816C19.1871 21.2816 20.4725 19.9962 20.4725 18.4105C20.4725 16.8249 19.1871 15.5396 17.6015 15.5396C16.0159 15.5396 14.7305 16.8249 14.7305 18.4105C14.7305 19.9962 16.0159 21.2816 17.6015 21.2816Z"
        stroke="#707070"
      />
      <path
        d="M30.7802 21.2808C32.3658 21.2808 33.6512 19.9954 33.6512 18.4098C33.6512 16.8242 32.3658 15.5388 30.7802 15.5388C29.1946 15.5388 27.9092 16.8242 27.9092 18.4098C27.9092 19.9954 29.1946 21.2808 30.7802 21.2808Z"
        stroke="#707070"
      />
      <path
        d="M24.1908 35.8067C25.7764 35.8067 27.0618 34.5213 27.0618 32.9357C27.0618 31.3501 25.7764 30.0647 24.1908 30.0647C22.6052 30.0647 21.3198 31.3501 21.3198 32.9357C21.3198 34.5213 22.6052 35.8067 24.1908 35.8067Z"
        stroke="#707070"
      />
      <path
        d="M15.4856 30.0777C16.4816 30.0777 17.2891 29.2702 17.2891 28.2742C17.2891 27.2782 16.4816 26.4707 15.4856 26.4707C14.4896 26.4707 13.6821 27.2782 13.6821 28.2742C13.6821 29.2702 14.4896 30.0777 15.4856 30.0777Z"
        stroke="#707070"
      />
      <path
        d="M32.8572 30.0774C33.8532 30.0774 34.6607 29.27 34.6607 28.2739C34.6607 27.2779 33.8532 26.4705 32.8572 26.4705C31.8612 26.4705 31.0537 27.2779 31.0537 28.2739C31.0537 29.27 31.8612 30.0774 32.8572 30.0774Z"
        stroke="#707070"
      />
    </SVG>
  );
};

export default ChargingAcJp;
