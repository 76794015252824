import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const CAR_CONFIG_DATA_QUERY = gql`
  query CarConfigDataQuery($market: String!, $model: String!) {
    featureFlags(market: $market, model: $model)
    translations(market: $market)
  }
`;

export const CAR_CONFIG_CLIENT_NAME = 'car-config';

export const useCarConfigDataQuery = ({
  market,
  model,
}: {
  market: string;
  model: string;
}) => {
  const { data, loading: carConfigDataLoading } = useQuery(
    CAR_CONFIG_DATA_QUERY,
    {
      variables: {
        market,
        model,
      },
      skip: !model || !market,
      fetchPolicy: 'cache-and-network', // to force reload in client
      context: { clientName: CAR_CONFIG_CLIENT_NAME },
    },
  );

  const { featureFlags, translations } = data || {};

  return useMemo(
    () => ({ featureFlags, translations, carConfigDataLoading }),
    [translations, featureFlags, carConfigDataLoading],
  );
};
