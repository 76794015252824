import { sizes, Spacing, SpacingParams } from './getSpacing';

type WithDesktopSizes<T> = {
  [Property in keyof T as `${string & Property}Desktop`]?: T[Property];
};

export type DesktopSpacings = WithDesktopSizes<SpacingParams>;
export type Spacings = DesktopSpacings & SpacingParams;

export const calculateSpacing: <T extends number>({
  params,
  baselineGrid,
}: {
  params: Spacings;
  baselineGrid: T;
}) => Spacing = ({ params, baselineGrid }) => {
  const {
    topOuterSpacing,
    bottomOuterSpacing,
    topInnerSpacing,
    bottomInnerSpacing,

    topOuterSpacingDesktop,
    bottomOuterSpacingDesktop,
    topInnerSpacingDesktop,
    bottomInnerSpacingDesktop,
  } = params;
  return {
    untilM: {
      marginTop: sizes[topOuterSpacing] * baselineGrid,
      marginBottom: sizes[bottomOuterSpacing] * baselineGrid,
      paddingTop: sizes[topInnerSpacing] * baselineGrid,
      paddingBottom: sizes[bottomInnerSpacing] * baselineGrid,
    },
    fromM: {
      marginTop:
        sizes[topOuterSpacingDesktop || topOuterSpacing] * baselineGrid,
      marginBottom:
        sizes[bottomOuterSpacingDesktop || bottomOuterSpacing] * baselineGrid,
      paddingTop:
        sizes[topInnerSpacingDesktop || topInnerSpacing] * baselineGrid,
      paddingBottom:
        sizes[bottomInnerSpacingDesktop || bottomInnerSpacing] * baselineGrid,
    },
  };
};
