import React from 'react';
import SVG from './svg';

const ARAndroid: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 48 48">
    <path
      fill="currentColor"
      d="M38 30.3399V17.6599C38 16.5799 37.42 15.5999 36.5 15.0599L25.5 8.69992C24.58 8.15992 23.42 8.15992 22.5 8.69992L11.5 15.0599C10.58 15.5799 10 16.5599 10 17.6599V30.3599C10 31.4399 10.58 32.4199 11.5 32.9599L22.5 39.3199C23.42 39.8599 24.58 39.8599 25.5 39.3199L36.5 32.9599C37.42 32.3999 38 31.4199 38 30.3399ZM22 34.3999L14 29.7799V20.5399L22 25.1799V34.3999ZM24 21.6999L16 17.0599L24 12.4399L32 17.0599L24 21.6999ZM34 29.7799L26 34.3999V25.1799L34 20.5399V29.7799Z"
    />
    <path
      fill="currentColor"
      d="M6 8C6 6.9 6.9 6 8 6H12V2H8C4.68 2 2 4.68 2 8V12H6V8Z"
    />
    <path
      fill="currentColor"
      d="M6 40V36H2V40C2 43.32 4.68 46 8 46H12V42H8C6.9 42 6 41.1 6 40Z"
    />
    <path
      fill="currentColor"
      d="M40 2H36V6H40C41.1 6 42 6.9 42 8V12H46V8C46 4.68 43.32 2 40 2Z"
    />
    <path
      fill="currentColor"
      d="M42 40C42 41.1 41.1 42 40 42H36V46H40C43.32 46 46 43.32 46 40V36H42V40Z"
    />
  </SVG>
);

export default ARAndroid;
