'use client';

// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable no-restricted-imports */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import FocusTrap from 'focus-trap-react';
import { Transition } from 'react-transition-group';
import { type ExtendCSS, Flex, Text } from 'vcc-ui';
import { useElementDimensions } from '@volvo-cars/react-layout-utils';
import { Click } from '@vcc-www/buttons';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries/DictionariesProvider';
import { Icon } from '@volvo-cars/react-icons';
import {
  useBreakpoints,
  useScrollLock,
  useHasScrolledDown,
} from '@vcc-www/hooks';
import { usePrevious, useKeyPress } from '@volvo-cars/react-utils';
import SectionContained from '@vcc-www/page-section/DEPRECATED_BROKEN_SectionContained';
import { setFocus } from '@vcc-www/utils/set-focus';
import { useOverlayCounterDEPRECATED } from '../useOverlayCounterDEPRECATED';

type Props = {
  extend?: ExtendCSS;
  extendCloseButton?: ExtendCSS;
  extendContent?: ExtendCSS;
  isOpen?: boolean;
  close?: () => void;
  hideCloseButton?: boolean;
  showFloatingCloseButton?: boolean;
  onExited?: () => void;
  title?: string;
  closeTrackLabel?: string;
  resetScroll?: boolean;
  disableResizeEventListener?: boolean;
  magnifyCloseButton?: boolean;
  disableFocusTrap?: boolean;
};

const transitionTime = 300;

export const CloseButtonOverlayDEPRECATED = ({
  extendCloseButton,
  floatingButton,
  close,
  trackLabel,
  showSmall = false,
  showLarge = false,
}: {
  extendCloseButton?: ExtendCSS;
  floatingButton?: boolean;
  close?: () => void;
  trackLabel?: string;
  showSmall?: boolean;
  showLarge?: boolean;
}) => {
  const { fromM, untilM } = useBreakpoints(); // eslint-disable-line vcc-www/use-breakpoints

  const getIconType = () => {
    if (showSmall) {
      return 'navigation-close-16';
    }
    if (floatingButton || showLarge || fromM) {
      return 'navigation-close-24';
    }
    return 'navigation-close-16';
  };
  const translate = useSharedComponentsTranslate();

  return (
    <Flex
      extend={
        floatingButton && untilM
          ? floatingCloseCSS
          : [closeButtonCSS, extendCloseButton]
      }
    >
      <Click
        onClick={close}
        data-testid="overlay:closeButton"
        data-autoid="overlay:closeButton"
        aria-label={translate('ReactOverlay.labels.close')}
        trackEventLabel={trackLabel || 'close'}
        // @ts-ignore Check if this is correct value to track for ga4
        trackEventAction="overlay|close"
        trackGA3={{ eventAction: 'click' }}
        extend={{
          extend: {
            condition: floatingButton,
            style: {
              untilM: closeButtonInternalCSS,
            },
          },
        }}
      >
        <Icon
          color="primary"
          data-testid={getIconType()}
          type={getIconType()}
        />
      </Click>
    </Flex>
  );
};

/**
 *  @deprecated
 *  Use overlay component from '@volvo-cars/react-overlay' instead
 *  https://vcc-ui.vercel.app/docs/packages/react-overlay
 **/
const OverlayDEPRECATED: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onExited,
  extend,
  extendCloseButton,
  extendContent,
  children,
  close,
  hideCloseButton,
  showFloatingCloseButton,
  title,
  closeTrackLabel,
  resetScroll = false,
  disableResizeEventListener,
  magnifyCloseButton,
  disableFocusTrap = false,
}) => {
  const defaultZIndex = 1001;
  const [customZIndex, setCustomZIndex] = useState(defaultZIndex);
  const {
    openedOverlayCount,
    increaseOpenedOverlayCount,
    decreaseOpenedOverlayCount,
  } = useOverlayCounterDEPRECATED();
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const previousIsOpen = usePrevious(isOpen);
  const { fromM, untilM } = useBreakpoints(); // eslint-disable-line vcc-www/use-breakpoints
  const closeOverlay = useCallback(() => close && close(), [close]);
  const [headerRef, { height: headerHeight }] = useElementDimensions({
    disabled: disableResizeEventListener,
  });
  const isScrolling = useHasScrolledDown({
    scrollElement: overlayRef?.current,
    offsetThreshold: DESKTOP_HEIGHT_INITIAL - DESKTOP_HEIGHT_COMPACT,
    disabled: !isOpen,
  });

  useKeyPress('Escape', closeOverlay);

  useScrollLock(openedOverlayCount > 0);

  useEffect(() => {
    if (!previousIsOpen && isOpen) {
      setCustomZIndex(defaultZIndex + openedOverlayCount);
      setFocus(overlayRef);
      increaseOpenedOverlayCount();
    }
    if (previousIsOpen && !isOpen) {
      decreaseOpenedOverlayCount();
    }
    if (resetScroll && isOpen) {
      overlayRef?.current?.scrollTo?.(0, 0);
    }
  }, [isOpen]); // eslint-disable-line

  const hideHeader = hideCloseButton && !title;

  return (
    <Transition in={isOpen} timeout={transitionTime} appear onExited={onExited}>
      {(state) => {
        return (
          <FocusTrap
            active={isOpen === true && !disableFocusTrap}
            focusTrapOptions={{ allowOutsideClick: true }}
          >
            <Flex
              aria-hidden={!isOpen}
              extend={[
                rootCSS(isOpen, state, customZIndex, transitionTime),
                extend,
              ]}
              ref={overlayRef}
              data-autoid="overlay:wrapper"
              data-testid="overlay:wrapper"
            >
              {hideHeader ? null : showFloatingCloseButton && untilM ? (
                <CloseButtonOverlayDEPRECATED
                  extendCloseButton={extendCloseButton}
                  floatingButton
                  close={close}
                  trackLabel={closeTrackLabel}
                  showLarge={magnifyCloseButton || false}
                />
              ) : (
                <Flex extend={headerCSS({ isScrolling })} ref={headerRef}>
                  <SectionContained
                    topInnerSpacing="none"
                    bottomInnerSpacing={
                      showFloatingCloseButton && untilM ? 'xsmall' : 'none'
                    }
                    extend={{ fromM: { margin: 0, maxWidth: '100%' } }}
                  >
                    <>
                      <Flex extend={containerCSS}>
                        <Flex extend={sideCSS} />
                        <Flex extend={titleCSS}>
                          {title && (
                            <Text
                              as="h2"
                              subStyle="emphasis"
                              extend={titleTextCSS({ isScrolling })}
                              data-autoid="overlay:title"
                            >
                              {title}
                            </Text>
                          )}
                        </Flex>
                        <Flex extend={sideCSS}>
                          {!hideCloseButton && (
                            <CloseButtonOverlayDEPRECATED
                              extendCloseButton={extendCloseButton}
                              close={close}
                              showSmall={fromM && isScrolling}
                              trackLabel={closeTrackLabel}
                              showLarge={magnifyCloseButton || false}
                            />
                          )}
                        </Flex>
                      </Flex>
                    </>
                  </SectionContained>
                </Flex>
              )}
              <Flex
                extend={[
                  contentCSS({
                    headerHeight: hideHeader ? 0 : headerHeight,
                    showFloatingCloseButton,
                  }),
                  extendContent,
                ]}
              >
                {children}
              </Flex>
            </Flex>
          </FocusTrap>
        );
      }}
    </Transition>
  );
};

const DESKTOP_HEIGHT_INITIAL = 150;
const DESKTOP_HEIGHT_COMPACT = 70;

const rootCSS: (
  isOpen: boolean | undefined,
  state: string,
  customZIndex: number,
  transitionTime: number,
) => ExtendCSS =
  (isOpen, state, customZIndex, transitionTime) =>
  ({ theme: { color } }) => ({
    position: 'fixed',
    top:
      state === 'exiting' || state === 'exited'
        ? // IntersectionObserver incorrectly marks intersection with elements
          // in case they are in view but not visible. Thus, in order for
          // IntersectionObserver to work as expected for Overlay, need to
          // move it outside screen in case it's in "closed" state.
          'calc(200vh + 100%)'
        : 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: state === 'exiting' || state === 'exited' ? 0 : 1,
    visibility: state === 'exited' && !isOpen ? 'hidden' : 'visible',
    pointerEvents: state === 'exiting' || state === 'exited' ? 'none' : 'all',
    transition: `opacity ${transitionTime}ms ease-out`,
    zIndex: customZIndex,
    background: color.background.primary,
    overflowY: state === 'exiting' ? 'hidden' : 'auto',
  });

const headerCSS =
  ({ isScrolling }: { isScrolling: boolean }): ExtendCSS =>
  ({ theme: { color } }) => ({
    position: 'fixed',
    top: 0,
    zIndex: 10,
    width: '100%',
    flexDirection: 'row',
    borderBottom: `1px solid ${color.ornament.divider}`,
    fromM: {
      background: color.background.primary,
      transition: 'height 300ms ease-in-out',
      height: isScrolling ? DESKTOP_HEIGHT_COMPACT : DESKTOP_HEIGHT_INITIAL,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

const containerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  flexDirection: 'row',
  padding: `${baselineGrid}px ${2 * baselineGrid}px`,
});

const sideCSS: ExtendCSS = {
  width: 40,
};

const closeButtonCSS: ExtendCSS = {
  width: 40,
  height: 40,
  alignSelf: 'flex-start',
  zIndex: 10,
  cursor: 'pointer',
};

const closeButtonInternalCSS: ExtendCSS = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const floatingCloseCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  position: 'fixed',
  zIndex: 1,
  right: 3 * baselineGrid,
  top: 3 * baselineGrid,
});

const titleCSS: ExtendCSS = {
  textAlign: 'center',
  justifyContent: 'center',
  flex: '1 0 0',
};

const titleTextCSS =
  ({ isScrolling }: { isScrolling: boolean }): ExtendCSS =>
  ({ theme: { typeScale } }) => {
    const fontTransitionCSS = () => {
      return {
        ...typeScale[isScrolling ? 'hillary' : 'ootah'].standard.styles,
        transition: 'all 300ms ease-in-out',
        fontWeight: 500,
      };
    };
    return {
      margin: 0,
      fromL: fontTransitionCSS(),
      fromXL: fontTransitionCSS(),
    };
  };

const contentCSS =
  ({
    headerHeight,
    showFloatingCloseButton,
  }: {
    headerHeight: number;
    showFloatingCloseButton?: boolean;
  }): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    paddingTop: headerHeight + 2 * baselineGrid,
    extend: {
      condition: showFloatingCloseButton,
      style: {
        paddingTop: 2 * baselineGrid,
      },
    },
    fromM: { paddingTop: headerHeight },
  });

export default OverlayDEPRECATED;
