import React from 'react';
import SVG from './svg';

const ChargingAcNa: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SVG viewBox="0 0 48 48" fill="none">
      <rect width="47.9999" height="48" fill="none" />
      <path
        d="M24.1844 27.3688C31.466 27.3688 37.3688 21.4659 37.3688 14.1844C37.3688 6.90286 31.466 1 24.1844 1C16.9029 1 11 6.90286 11 14.1844C11 21.4659 16.9029 27.3688 24.1844 27.3688Z"
        stroke="#707070"
      />
      <path
        d="M24.2076 24.8817C30.1155 24.8817 34.9049 20.0923 34.9049 14.1844C34.9049 8.27641 30.1155 3.48706 24.2076 3.48706C18.2996 3.48706 13.5103 8.27641 13.5103 14.1844C13.5103 20.0923 18.2996 24.8817 24.2076 24.8817Z"
        stroke="#707070"
      />
      <path
        d="M19.4511 11.9873C20.5912 11.9873 21.5154 11.0631 21.5154 9.92298C21.5154 8.78288 20.5912 7.85864 19.4511 7.85864C18.311 7.85864 17.3867 8.78288 17.3867 9.92298C17.3867 11.0631 18.311 11.9873 19.4511 11.9873Z"
        stroke="#707070"
      />
      <path
        d="M28.9267 11.9873C30.0668 11.9873 30.991 11.0631 30.991 9.92298C30.991 8.78288 30.0668 7.85864 28.9267 7.85864C27.7865 7.85864 26.8623 8.78288 26.8623 9.92298C26.8623 11.0631 27.7865 11.9873 28.9267 11.9873Z"
        stroke="#707070"
      />
      <path
        d="M24.1889 22.4319C25.329 22.4319 26.2532 21.5077 26.2532 20.3676C26.2532 19.2275 25.329 18.3032 24.1889 18.3032C23.0488 18.3032 22.1245 19.2275 22.1245 20.3676C22.1245 21.5077 23.0488 22.4319 24.1889 22.4319Z"
        stroke="#707070"
      />
      <path
        d="M17.9296 18.3128C18.6458 18.3128 19.2263 17.7322 19.2263 17.016C19.2263 16.2998 18.6458 15.7192 17.9296 15.7192C17.2134 15.7192 16.6328 16.2998 16.6328 17.016C16.6328 17.7322 17.2134 18.3128 17.9296 18.3128Z"
        stroke="#707070"
      />
      <path
        d="M30.4208 18.3125C31.137 18.3125 31.7175 17.7319 31.7175 17.0158C31.7175 16.2996 31.137 15.719 30.4208 15.719C29.7046 15.719 29.124 16.2996 29.124 17.0158C29.124 17.7319 29.7046 18.3125 30.4208 18.3125Z"
        stroke="#707070"
      />
    </SVG>
  );
};

export default ChargingAcNa;
