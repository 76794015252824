'use client';

import React from 'react';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { IconButton } from '@volvo-cars/react-icons';
import { Track } from '@volvo-cars/tracking';

export type DialogCloseProps = {
  onClose: (event: any) => void;
  hideCloseButton?: boolean;
};

export const DialogClose = ({ onClose, hideCloseButton }: DialogCloseProps) => {
  const translate = useSharedComponentsTranslate();

  return (
    <div slot="close">
      {!hideCloseButton ? (
        <Track
          eventLabel="close"
          eventAction="overlay|close"
          ga3={{ eventAction: 'close' }}
        >
          <IconButton
            aria-label={translate('ReactOverlay.labels.close')}
            iconName="navigation-close"
            onClick={onClose}
            variant="clear"
            data-bleed="true"
            className="fade-in"
          />
        </Track>
      ) : null}
    </div>
  );
};
