import { VolvoCarsUrl } from '@vcc-www/volvo-cars-url';

export const shouldHavePrices = (market?: string) => market !== 'intl';

export const getMostExpensiveSalesVersion = (salesVersions: any) =>
  salesVersions?.reduce(
    (prevSalesVersion: any, salesVersion: any) =>
      prevSalesVersion?.pricingDetails?.price <
      salesVersion.pricingDetails.price
        ? salesVersion
        : prevSalesVersion,
    salesVersions?.[0],
  );

export const getCceEnv = (url: VolvoCarsUrl) =>
  url.searchParams.get('cceEnv') || undefined;
