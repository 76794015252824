// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable react/no-unknown-property */
import React from 'react';
import SVG from './svg';

const Touch: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 40 40" fill="none">
    <path
      d="M12.7812 22.8125L14 22V11.5C14 10.1193 15.1193 9 16.5 9C17.8807 9 19 10.1193 19 11.5V18.4798C19.4178 18.1785 19.9371 18 20.4999 18C21.8805 18 22.9998 19.0745 22.9998 20.4V20.48C23.4176 20.1786 23.937 20 24.4999 20C25.8805 20 26.9998 21.0745 26.9998 22.4V22.48C27.4176 22.1786 27.937 22 28.4999 22C29.7386 22 30.7668 22.8649 30.9652 24H31V29.6039C31 29.8673 30.9751 30.1294 30.9067 30.3837C30.7024 31.1425 30.14 32.86 29 34V37H28C28 37 25 38 22 38C19 38 16 37 16 37H15V36.2111C15 35.4214 14.7662 34.6494 14.3282 33.9923L11.6718 30.0077C11.2338 29.3506 11 28.5786 11 27.7889V26.1407C11 24.8033 11.6684 23.5544 12.7812 22.8125Z"
      fill="currentColor"
      fillOpacity="0.4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7812 22.8125L14 22V11.5C14 10.1193 15.1193 9 16.5 9C17.8807 9 19 10.1193 19 11.5V16V18.4798C19.4178 18.1785 19.9371 18 20.4999 18C21.8805 18 22.9998 19.0745 22.9998 20.4V20.48C23.4176 20.1786 23.937 20 24.4999 20C25.8805 20 26.9998 21.0745 26.9998 22.4V22.48C27.4176 22.1786 27.937 22 28.4999 22C29.7386 22 30.7668 22.8649 30.9652 24H31V29.6039C31 29.8673 30.9751 30.1294 30.9067 30.3837C30.7024 31.1425 30.14 32.86 29 34V37H28V36V33.5858L28.2929 33.2929C29.2356 32.3501 29.7443 30.8546 29.9411 30.1237C29.9807 29.9765 30 29.8062 30 29.6039V25.1155L29.9998 25.1154V24.4C29.9998 23.6648 29.3671 23 28.4999 23C27.7996 23 27.2522 23.4336 27.0675 23.9876L25.9998 23.5769V22.4C25.9998 21.6648 25.3671 21 24.4999 21C23.6327 21 23 21.6648 23 22.4L23 22.4232L21.9998 22.0385V20.4C21.9998 19.6648 21.3671 19 20.4999 19C19.6327 19 19 19.6648 19 20.4L19 20.5058V20.5215L19 20.8847L18 20.5001L18 20.4C18 20.371 18.0005 20.3422 18.0016 20.3135L18 20.3131V11.5C18 10.6716 17.3284 10 16.5 10C15.6716 10 15 10.6716 15 11.5V22.5352L13.3359 23.6446C12.5013 24.201 12 25.1377 12 26.1407V27.7889C12 28.3812 12.1753 28.9602 12.5038 29.453L15.1603 33.4376C15.6687 34.2003 15.957 35.0869 15.9955 36H16V37H15V36.2111C15 35.4214 14.7662 34.6494 14.3282 33.9923L11.6718 30.0077C11.2338 29.3506 11 28.5786 11 27.7889V26.1407C11 24.8033 11.6684 23.5544 12.7812 22.8125Z"
      fill="currentColor"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="8"
      y="2"
      width="18"
      height="16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 2H8V18H13V9H20V18H26V2Z"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0)">
      <circle cx="16.5" cy="10.5" r="7" stroke="currentColor" />
      <circle cx="16.5" cy="10.5" r="5" stroke="currentColor" />
    </g>
  </SVG>
);

export default Touch;
