import { useMemo } from 'react';
import gql from 'graphql-tag';
import useQuery from './useQuery';

export type PriceConfigInput = null | {
  name: string;
  variableReferences: {
    name: string;
    value: string;
  }[];
  taxation?: any;
};

// If you edit this query, run `yarn apollo-codegen` to update types
export const STARTING_POINT_QUERY = gql`
  query StartingPointQuery(
    $locale: String
    $modelFamily: String
    $policy: String
    $modelId: String
    $salesVersion: String
    $priceConfigInput: PRICE_CONFIGURATION_INPUT
  ) {
    startingpointQuery(
      LOCALE: $locale
      POLICY: $policy
      PRICE_CONFIG_IN: $priceConfigInput
    ) {
      children(type: "MODEL_FAMILY", id: $modelFamily) {
        id
        model: children(type: "MODEL", id: $modelId) {
          id
          salesVersionFamilies: children(type: "FAMILY", id: $salesVersion) {
            id
            displayName
            pricingDetails {
              price
              msrpDisplayPrice
            }
            salesVersions: children(type: "SALES_VERSION") {
              id
              pricingDetails {
                price
              }
              seats: children(type: "SEATS") {
                modelyears: children(type: "MODEL_YEAR") {
                  displayCarData {
                    carConfigurationDecoded {
                      modelYear
                    }
                  }
                  powertrains: children(type: "DRIVELINE") {
                    id
                    displayCarData {
                      token
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const STARTING_POINT_QUERY_WITHOUT_PRICES = gql`
  query StartingPointQueryWithoutPrices(
    $locale: String
    $modelFamily: String
    $policy: String
    $modelId: String
    $salesVersion: String
  ) {
    startingpointQuery(LOCALE: $locale, POLICY: $policy) {
      children(type: "MODEL_FAMILY", id: $modelFamily) {
        id
        model: children(type: "MODEL", id: $modelId) {
          id
          salesVersionFamilies: children(type: "FAMILY", id: $salesVersion) {
            id
            displayName
            salesVersions: children(type: "SALES_VERSION") {
              id
              seats: children(type: "SEATS") {
                modelyears: children(type: "MODEL_YEAR") {
                  displayCarData {
                    carConfigurationDecoded {
                      modelYear
                    }
                  }
                  powertrains: children(type: "DRIVELINE") {
                    id
                    displayCarData {
                      token
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useStartingPointQuery = ({
  locale,
  modelFamily,
  modelId,
  policy,
  priceConfigInput,
  salesVersion,
}: {
  locale?: string;
  modelFamily?: string | null;
  modelId?: string | null;
  policy?: string | null;
  priceConfigInput?: PriceConfigInput;
  salesVersion?: string | null;
}) => {
  const { data, loading } = useQuery(
    priceConfigInput
      ? STARTING_POINT_QUERY
      : STARTING_POINT_QUERY_WITHOUT_PRICES,
    {
      variables: {
        locale,
        modelFamily,
        modelId,
        policy,
        priceConfigInput,
        salesVersion,
      },
      skip: !locale || !policy,
      fetchPolicy: 'no-cache',
    },
  );

  const { startingpointQuery } = data || {};

  return useMemo(
    () => ({ startingPoint: startingpointQuery, loading }),
    [startingpointQuery, loading],
  );
};
