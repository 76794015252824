import React from 'react';
import { Text, Block, Flex, ExtendCSS } from 'vcc-ui';
import { Link } from '@vcc-www/buttons'; // eslint-disable-line no-restricted-imports
import { Markdown_DEPRECATED_VCCUI } from '@vcc-www/markdown';
import Icon from '@vcc-www/large-icon';
import IconTextListProps, { IconText } from './IconTextList.props';

type Props = Readonly<
  Pick<
    IconTextListProps,
    | 'textVariant'
    | 'titleVariant'
    | 'hideIcons'
    | 'variant'
    | 'orientation'
    | 'iconHeight'
    | 'iconColor'
  >
>;

const DEFAULT_ICON_SIZE = 40;

const IconTextItem: React.FC<React.PropsWithChildren<Props & IconText>> = ({
  icon,
  iconHeight = DEFAULT_ICON_SIZE,
  header,
  unit,
  title,
  text,
  cta,
  hideIcons,
  ctaTrackLabel,
  orientation,
  textVariant,
  titleVariant,
  variant,
  iconColor,
  downloadCta,
}) => (
  <Flex
    extend={wrapperCSS(orientation, variant)}
    data-testid="iconCallouts:iconTextItem"
    data-autoid="iconCallouts:iconTextItem"
  >
    {!hideIcons && icon && (
      <Block extend={iconCSS(variant, iconHeight, iconColor)}>
        <Icon name={icon} />
      </Block>
    )}
    <div>
      {(header || unit) && (
        <Flex extend={headerContainerCSS}>
          {header && (
            <Text variant="cook" subStyle="emphasis">
              {header}
            </Text>
          )}
          {unit && (
            <Text variant="columbus" subStyle="emphasis">
              {unit}
            </Text>
          )}
        </Flex>
      )}
      {title && (
        <Text
          variant={titleVariant || textVariant}
          subStyle="emphasis"
          extend={titleCSS(variant)}
        >
          {title}
        </Text>
      )}
      {text && (
        <Markdown_DEPRECATED_VCCUI
          markdown={text}
          variant={textVariant}
          extend={textCSS(variant, titleVariant)}
          data-autoid="IconTextItem:text"
        />
      )}
      {cta && cta.text && (
        <Block extend={ctaCSS(variant)}>
          <Link
            href={cta.href}
            target={cta.target}
            aria-label={cta.title}
            trackEventLabel={ctaTrackLabel || `${cta.text} | ${cta.href}`}
            download={downloadCta}
          >
            {cta.text}
          </Link>
        </Block>
      )}
    </div>
  </Flex>
);

export default IconTextItem;

const wrapperCSS =
  (orientation: Props['orientation'], variant: Props['variant']): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    paddingRight: baselineGrid * 2,
    paddingLeft: baselineGrid * 2,
    marginBottom: orientation === 'vertical' ? baselineGrid * 4 : 0,
    width: '100%',
    ':last-child': {
      marginBottom: 0,
    },
    fromL: {
      maxWidth: orientation === 'vertical' ? 'auto' : '75%',
    },
    untilM: {
      marginBottom: baselineGrid * 4,
      width: '100%',
    },
    ...(variant === 'cbv' && {
      flexDirection: 'row',
    }),
  });

const iconCSS =
  (
    variant: Props['variant'],
    height: Props['iconHeight'],
    iconColor: Props['iconColor'],
  ): ExtendCSS =>
  ({ theme: { baselineGrid, color } }) => ({
    marginBottom: baselineGrid * 2,
    flexShrink: 0,
    ...(variant === 'center-from-m'
      ? {
          alignSelf: 'flex-start',
          height: DEFAULT_ICON_SIZE,
          fromM: {
            alignSelf: 'center',
            height,
          },
        }
      : {
          height,
          alignSelf: variant === 'center' ? 'center' : 'flex-start',
        }),
    ...(iconColor && {
      '> svg': {
        color: (color as any).primitive[iconColor],
      },
    }),
    ...(variant === 'cbv' && {
      alignSelf: 'flex-start',
      height,
      width: 1.5 * (height ?? baselineGrid),
      marginTop: baselineGrid,
      marginBottom: 0,
      marginRight: baselineGrid * 3,
      '> svg': {
        margin: 'auto',
        color: color.foreground.primary,
      },
    }),
  });

const headerContainerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  flexDirection: 'row',
  alignItems: 'flex-end',
  marginBottom: 2 * baselineGrid,
});

const titleCSS = (variant: Props['variant']): ExtendCSS =>
  variant === 'center-from-m'
    ? {
        textAlign: 'left',
        fromM: {
          textAlign: 'center',
        },
      }
    : {
        textAlign: variant === 'center' ? 'center' : 'left',
      };

const textCSS = (
  variant: Props['variant'],
  titleVariant: Props['titleVariant'],
): ExtendCSS => [
  ({ theme: { color, baselineGrid } }) => ({
    color: color.foreground.secondary,
    ...(titleVariant === 'hillary' && {
      paddingTop: baselineGrid,
    }),
    ...(variant === 'cbv' && {
      color: color.foreground.primary,
      paddingTop: baselineGrid * 1.5,
    }),
  }),
  titleCSS(variant),
];

const ctaCSS = (variant: Props['variant']): ExtendCSS => [
  ({ theme: { baselineGrid } }) => ({
    marginTop: baselineGrid,
  }),
  titleCSS(variant),
];
