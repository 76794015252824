// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable eqeqeq */
import React from 'react';
import { ExtendCSS, Flex, Spacer, Icon } from 'vcc-ui';
import { Click } from '@vcc-www/buttons';
import { BackgroundColor } from '@vcc-www/page-section/params';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries/DictionariesProvider';
import { useSpringCarousel } from '.';

type Props = {
  color?: BackgroundColor;
  size?: 'default' | 'small';
  forceSupportRTL?: boolean;
  extend?: ExtendCSS;
  noSpacing?: boolean;
};

const SIZES = {
  default: '40',
  small: '24',
} as const;

export const SpringCarouselPagination: React.FC<
  React.PropsWithChildren<Props>
> = ({ color, size = 'default', forceSupportRTL, extend, noSpacing }) => {
  const { total, current, setCurrent, emitEvent } = useSpringCarousel();
  const isFirst = current <= 1;
  const isLast = current >= total;
  const translate = useSharedComponentsTranslate();
  const nextButtonAriaLabel = translate(
    'SpringCarousel.springCarousel.nextButtonLabel',
  );
  const previousButtonAriaLabel = translate(
    'SpringCarousel.springCarousel.previousButtonLabel',
  );

  const arrowLeft = (
    <Icon
      color={color === 'black' || color == 'dark' ? 'secondary' : 'primary'}
      type={`mediacircled-previous-${SIZES[size]}` as const}
    />
  );

  const arrowRight = (
    <Icon
      color={color === 'black' || color == 'dark' ? 'secondary' : 'primary'}
      type={`mediacircled-next-${SIZES[size]}` as const}
    />
  );

  return (
    <Flex extend={[rootCSS, extend]}>
      {total > 1 && (
        <>
          <Click
            data-autoid="springCarouselPreviousButton"
            tabIndex={isFirst ? -1 : 0}
            extend={arrowCSS({
              forceSupportRTL: forceSupportRTL ?? false,
              inActive: isFirst,
            })}
            aria-label={previousButtonAriaLabel}
            trackEventAction="arrow|previous"
            trackEventLabel="previous"
            trackGA3={{ eventAction: 'click', eventLabel: 'left arrow' }}
            onClick={() => {
              setCurrent(isFirst ? 0 : current - 1, () =>
                emitEvent('click-arrow-previous'),
              );
            }}
            aria-disabled={isFirst}
          >
            {arrowLeft}
          </Click>
          {!noSpacing && <Spacer />}
          <Click
            data-autoid="springCarouselNextButton"
            extend={arrowCSS({
              forceSupportRTL: forceSupportRTL ?? false,
              inActive: isLast,
            })}
            tabIndex={isLast ? -1 : 0}
            aria-label={nextButtonAriaLabel}
            trackEventLabel="next"
            trackEventAction="arrow|next"
            trackGA3={{ eventAction: 'click', eventLabel: 'right arrow' }}
            onClick={() => {
              setCurrent(isLast ? total : current + 1, () =>
                emitEvent('click-arrow-next'),
              );
            }}
            aria-disabled={isLast}
          >
            {arrowRight}
          </Click>
        </>
      )}
    </Flex>
  );
};

const rootCSS: ExtendCSS = ({ theme: { color } }) => ({
  flexDirection: 'row',
  alignSelf: 'auto',
  '& button': {
    color: color.foreground.primary,
    display: 'flex',
    alignItems: 'center',
  },
});

const arrowCSS =
  ({
    forceSupportRTL,
    inActive,
  }: {
    forceSupportRTL: boolean;
    inActive: boolean;
  }): ExtendCSS =>
  ({ theme: { direction } }) => ({
    cursor: inActive ? 'auto' : 'pointer',
    transition: `opacity 1s`,
    opacity: inActive ? 0.4 : 1,
    ...(forceSupportRTL &&
      direction === 'rtl' && { transform: 'rotate(180deg)' }),
  });
