import React from 'react';
import SVG from './svg';

const InfoCircled: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 27.7 27.8">
    <circle
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      cx="13.8"
      cy="13.8"
      r="12.9"
    />
    <path
      fill="currentColor"
      d="M15.1,7.8c-0.6,0-1.3,0.7-1.3,1.4c0,0.4,0.3,0.7,0.8,0.7c0.6,0,1.3-0.7,1.3-1.3C15.9,8.2,15.6,7.8,15.1,7.8z"
    />
    <path
      fill="currentColor"
      d="M12.6,19.4c0.9,0,2.2-0.8,3-1.5l-0.2-0.3c-0.4,0.3-0.9,0.6-1.1,0.6c-0.3,0-0.5-0.2-0.5-0.6 c0-0.2,0.1-0.5,0.2-0.7c0.1-0.2,0.1-0.5,0.2-0.6c0.1-0.4,0.2-0.7,0.3-1.1c0.1-0.3,0.2-0.7,0.3-1c0-0.1,0.1-0.3,0.2-0.5 c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.4,0.2-0.7,0.2-1c0-0.4-0.4-0.6-0.7-0.6c-0.9,0-2.3,0.9-3.1,1.5l0.3,0.3c0.4-0.3,0.7-0.5,0.9-0.5 c0.3,0,0.6,0.2,0.6,0.6c0,0.1,0,0.2-0.1,0.5l0,0.2c-0.1,0.2-0.1,0.4-0.2,0.5l-0.2,0.5c-0.2,0.6-0.4,1.2-0.5,1.7 c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.4-0.3,0.8-0.3,1.2C11.8,19,12.1,19.4,12.6,19.4z"
    />
  </SVG>
);

export default InfoCircled;
