import React from 'react';
import { Block, ExtendCSS } from 'vcc-ui';
import { topbarHeightFromM } from '@volvo-cars/site-nav-embed';
import { TrackingProvider } from '@volvo-cars/tracking';
import * as config from '@vcc-www/constants/config';
import { maxContentWidth12Columns } from '@vcc-www/constants/layout';

type Props = {
  componentName: string;
  extend?: ExtendCSS;
  /**
   * Will set `display:contents` on the parent element to allow children to be sticky
   */
  withStickyChildren?: boolean;
  /**
   * Index to distinguish between multiple components on a page with the same name.
   */
  componentIndex?: number;
  /**
   * Function for generating custom category label
   */
  customCategoryFn?: (componentName: string, index: number) => string;
  children: React.ReactNode;
};

type State = {
  error: Error | null;
};

class EditorialComponentWrapper extends React.Component<Props, State> {
  state: State = {
    error: null,
  };

  componentDidCatch(error: Error) {
    console.error(error);
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    const {
      componentName,
      componentIndex = 0,
      children,
      customCategoryFn,
      withStickyChildren,
    } = this.props;
    const { error } = this.state;
    const trackingComponentName = componentName
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .toLowerCase();
    const trackingCategory = customCategoryFn
      ? customCategoryFn(trackingComponentName, componentIndex + 1)
      : `${trackingComponentName} ${componentIndex + 1}`;

    let content = children;
    if (error) {
      if (config.deployEnv === 'dev' || config.deployEnv === 'test') {
        content = <ErrorMessage componentName={componentName} error={error} />;
      } else {
        content = null;
      }
    }

    return (
      <TrackingProvider eventCategory={trackingCategory}>
        <Block
          data-component={componentName}
          data-autoid={`${componentName}-${componentIndex + 1}`}
          id={`${componentName}-${componentIndex + 1}`}
          extend={[
            scrollMargin,
            this.props.extend,
            withStickyChildren ? { display: 'contents' } : undefined,
          ]}
        >
          {content}
        </Block>
      </TrackingProvider>
    );
  }
}

const scrollMargin: ExtendCSS = {
  scrollMarginTop: topbarHeightFromM,
};

const ErrorMessage: React.FC<{ componentName: string; error: Error }> = ({
  componentName,
  error,
}) => {
  return (
    <Block extend={containerCSS}>
      <h3 className="heading-3">
        {componentName} error: {error.message}
      </h3>
      <Block as="pre" extend={stackTraceCSS}>
        {error.stack}
      </Block>
    </Block>
  );
};

const containerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  padding: baselineGrid * 2,
  maxWidth: maxContentWidth12Columns,
  border: `1px solid red`,
});

const stackTraceCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-all',
  marginTop: baselineGrid,
  maxWidth: '100%',
});

export default EditorialComponentWrapper;
