import { IconType } from './LegacyIcon';

const EASING = {
  easeOutQuint: `cubic-bezier(0.230, 1.000, 0.320, 1.000)`,
  easeInOutQuint: `cubic-bezier(0.860, 0.000, 0.070, 1.000)`,
};

const TIMING = {
  menu: `400ms`,
  overlay: `400ms`,
  overlayHide: `200ms`,
  shelf: `400ms`,
  carousel: `500ms`,
  ctaIcon: `250ms`,
  ctaHover: `350ms`,
  toggle: `300ms`,
  imageFadeIn: `300ms`,
  carouselArrows: `200ms`,
  loadingBarTransition: `300ms`,
  loadingBarAnimation: `1500ms`,
  categoryModelTransition: `500ms`,
  categoryModelEnterDelay: `150ms`,
  expandable: `200ms`,
  pageTransition: `200ms`,
  transitionBlock: `400ms`,
  trimCardTransition: `500ms`,
  trimCardEnterDelay: `150ms`,
  widgetTabTransition: `600ms`,
  widgetPanelTransition: `600ms`,
  enginesFilterTransition: `250ms`,
};

const defaultIconDimensions = {
  width: `15px`,
  height: `15px`,
};

const iconCustomStylesMap: any = {
  chat: {
    width: 24,
    height: 24,
  },
  close: {
    width: 24,
    height: 24,
  },
  cross: {
    width: 18,
    height: 18,
  },
  'close-circled-large': {
    width: 38,
    height: 38,
  },
  'chevron-right': {
    width: 12,
    height: 12,
  },
  'chevron-up': {
    width: 12,
    height: 12,
  },
  'chevron-down': {
    width: 12,
    height: 12,
  },
  'chevron-left-medium': {
    width: 24,
    height: 24,
  },
  'chevron-up-medium': {
    width: 24,
    height: 24,
  },
  'chevron-down-medium': {
    width: 24,
    height: 24,
  },
  download: {
    width: 24,
    height: 24,
  },
  facebook: {
    width: 24,
    height: 24,
  },
  instagram: {
    width: 24,
    height: 24,
  },
  link: {
    width: 24,
    height: 24,
  },
  linkedin: {
    width: 24,
    height: 24,
  },
  pinterest: {
    width: 24,
    height: 24,
  },
  'star-filled': {
    width: 24,
    height: 24,
  },
  'star-outlined': {
    width: 24,
    height: 24,
  },
  'tick-thin': {
    width: 24,
    height: 24,
  },
  tick: {
    width: 24,
    height: 24,
  },
  touch: {
    width: 40,
    height: 40,
  },
  twitter: {
    width: 24,
    height: 24,
  },
  vkontakte: {
    width: 24,
    height: 24,
  },
  weibo: {
    width: 24,
    height: 24,
  },
  wechat: {
    width: 24,
    height: 24,
  },
  youtube: {
    width: 24,
    height: 24,
  },
  blogger: {
    width: 24,
    height: 24,
  },
  defaultIconDimensions,
};

const iconCustomStyles = (
  iconType: IconType,
  dimensions?: [
    number | 'auto' | '100%' | string | null,
    number | string | null,
  ],
) =>
  dimensions
    ? {
        ...(dimensions[0] && { width: dimensions[0] }),
        ...(dimensions[1] && { height: dimensions[1] }),
      }
    : iconCustomStylesMap[iconType] ||
      iconCustomStylesMap.defaultIconDimensions;

export default function IconStyles(
  iconType: IconType,
  hovered?: boolean,
  color?: string,
  dimensions?: [
    number | 'auto' | '100%' | string | null,
    number | string | null,
  ],
) {
  return {
    display: `inline-flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    position: `relative`,
    transition: `transform ${TIMING.ctaIcon} ${EASING.easeOutQuint}`,
    ...(hovered &&
    (iconType.includes('chevron-left') || iconType.includes('chevron-right'))
      ? {
          transform: `translateX(${
            iconType.includes('chevron-left') ? '-8px' : '8px'
          })`,
        }
      : {}),
    color,
    ...iconCustomStyles(iconType, dimensions),
  };
}
