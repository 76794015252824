import React from 'react';
import { ExtendCSS, Inline } from 'vcc-ui';
import * as config from '@vcc-www/constants/config'; // eslint-disable-line
import styles from './Icon.styles';
import ARAndroid from './Icons/ar-android';
import ARIOS from './Icons/ar-ios';
import ChargingAcEu from './Icons/charging_ac_eu';
import ChargingDcEu from './Icons/charging_dc_eu';
import ChargingAcNa from './Icons/charging_ac_na';
import ChargingDcNa from './Icons/charging_dc_na';
import ChargingAcCh from './Icons/charging_ac_ch';
import ChargingDcCh from './Icons/charging_dc_ch';
import ChargingAcJp from './Icons/charging_ac_jp';
import ChargingDcJp from './Icons/charging_dc_jp';
import ChatIcon from './Icons/chat';
import Check from './Icons/check';
import Chevron from './Icons/chevron';
import City from './Icons/city';
import Close from './Icons/close';
import CloseCircled from './Icons/close-circled';
import Document from './Icons/document';
import Email from './Icons/email';
import Facebook from './Icons/facebook';
import Highway from './Icons/highway';
import InfoCircled from './Icons/info-circled';
import Instagram from './Icons/instagram';
import Link from './Icons/link';
import Linkedin from './Icons/linkedin';
import List from './Icons/list';
import MagnifyingGlass from './Icons/magnifying-glass';
import GetLocation from './Icons/get-location';
import GetLocationHiglighted from './Icons/get-location-highlighted';
import Marker from './Icons/marker';
import Minus from './Icons/minus';
import Mixed from './Icons/mixed';
import Phone from './Icons/phone';
import Pinterest from './Icons/pinterest';
import Plus from './Icons/plus';
import StarFilled from './Icons/star-filled';
import StarOutlined from './Icons/star-outlined';
import SuggestionPin from './Icons/suggestion-pin';
import Tick from './Icons/tick';
import Touch from './Icons/touch';
import Twitter from './Icons/twitter';
import Vkontakte from './Icons/vkontakte';
import Weibo from './Icons/weibo';
import Wechat from './Icons/wechat';
import Youtube from './Icons/youtube';
import Blogger from './Icons/blogger';
import Exit from './Icons/exit';
import CarFront from './Icons/car-front';
import FuelTypeElectric from './Icons/fuel-type-electric';
import FuelTypeFuel from './Icons/fuel-type-fuel';
import BatteryCharge from './Icons/battery-charge';
import Charging from './Icons/charging';

/** ********************** STOP **********************
 * Do you really need to add a custom icon?
 * Consider using Icon from '@vcc-ui' instead https://vcc-ui.vercel.app/docs/components/icon
 */
export const icons = {
  'ar-android': [ARAndroid],
  'ar-ios': [ARIOS],
  blogger: [Blogger],
  chat: [ChatIcon],
  check: [Check],
  'chevron-right': [Chevron, { strokeWidth: 1.5, rotate: -90 }],
  'chevron-up': [Chevron, { strokeWidth: 1.5, rotate: 180 }],
  'chevron-down': [Chevron, { strokeWidth: 1.5 }],
  'chevron-left-medium': [Chevron, { strokeWidth: 1, rotate: 90 }],
  'chevron-up-medium': [Chevron, { strokeWidth: 1, rotate: 180 }],
  'chevron-down-medium': [Chevron, { strokeWidth: 0.5 }],
  city: [City],
  close: [Close],
  'close-circled-large': [CloseCircled, { strokeWidth: 1 }],
  'close-small': [Close, { strokeWidth: 2 }],
  cross: [Close, { strokeWidth: 2 }],
  document: [Document],
  email: [Email],
  exit: [Exit],
  facebook: [Facebook],
  highway: [Highway],
  instagram: [Instagram],
  'info-circled': [InfoCircled],
  link: [Link],
  linkedin: [Linkedin],
  list: [List],
  'magnifying-glass': [MagnifyingGlass],
  'get-location': [GetLocation],
  'get-location-highlighted': [GetLocationHiglighted],
  marker: [Marker],
  minus: [Minus],
  mixed: [Mixed],
  phone: [Phone],
  pinterest: [Pinterest],
  plus: [Plus],
  'star-filled': [StarFilled],
  'star-outlined': [StarOutlined],
  'suggestion-pin': [SuggestionPin],
  'tick-thin': [Tick, { strokeWidth: 1 }],
  tick: [Tick],
  touch: [Touch],
  twitter: [Twitter],
  vkontakte: [Vkontakte],
  weibo: [Weibo],
  wechat: [Wechat],
  youtube: [Youtube],
  charging_ac_eu: [ChargingAcEu],
  charging_ac_na: [ChargingAcNa],
  charging_ac_ch: [ChargingAcCh],
  charging_ac_jp: [ChargingAcJp],
  charging_dc_eu: [ChargingDcEu],
  charging_dc_na: [ChargingDcNa],
  charging_dc_ch: [ChargingDcCh],
  charging_dc_jp: [ChargingDcJp],
  'car-front': [CarFront],
  'fuel-type-electric': [FuelTypeElectric],
  'fuel-type-electric-left': [FuelTypeElectric, { rotate: -90 }],
  'fuel-type-fuel': [FuelTypeFuel],
  'battery-charge': [BatteryCharge],
  charging: [Charging],
};

export type IconType = keyof typeof icons;

type Props = {
  type: IconType;
  hovered?: boolean;
  color?: string;
  extend?: ExtendCSS;

  /**
   * Format: `[width, height]`
   *
   * If width is set to null, it keep the aspect ratio with the set height - and
   * vice versa.
   */
  dimensions?: [
    number | 'auto' | '100%' | string | null,
    number | string | null,
  ];
  preserveAspectRatio?: string;
};

/**
 * @deprecated
 *
 * Instead use https://vcc-ui.vercel.app/docs/components/icon
 */
const Icon: React.FC<React.PropsWithChildren<Props>> = ({
  type = 'chevron-right',
  hovered,
  color,
  dimensions,
  preserveAspectRatio,
  extend,
}) => {
  if (!(type in icons)) {
    if (config.deployEnv === 'dev' || config.deployEnv === 'test') {
      console.log(`Icon type ${type} is not valid`);
    }
    return null;
  }

  const [Icon, props = {}] = icons[type] as [
    React.FC<React.PropsWithChildren<any>>,
    any,
  ];

  return (
    <Inline
      aria-hidden="true"
      data-testid={type}
      extend={[
        styles(type, hovered, color, dimensions) as ExtendCSS,
        extend as ExtendCSS,
      ]}
    >
      {Icon && <Icon {...props} preserveAspectRatio={preserveAspectRatio} />}
    </Inline>
  );
};

export default Icon;
