import React from 'react';
import SVG from './svg';

const Document: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 20 26" fill="none">
    <path
      clipRule="evenodd"
      d="M.5 25.5V.5h12l7 6.5v18.5H.5z"
      stroke="#707070"
    />
    <mask id="a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6H3v1h7V6H4zm0 8H3v1h14v-1H4zm0 3H3v1h14v-1H4zm-1 3h7v1H3v-1zM3 9h7v1H3V9z"
      />
    </mask>
    <path
      d="M3 6V5H2v1h1zm0 1H2v1h1V7zm7 0v1h1V7h-1zm0-1h1V5h-1v1zm-7 8v-1H2v1h1zm0 1H2v1h1v-1zm14 0v1h1v-1h-1zm0-1h1v-1h-1v1zM3 17v-1H2v1h1zm0 1H2v1h1v-1zm14 0v1h1v-1h-1zm0-1h1v-1h-1v1zM3 20v-1H2v1h1zm7 0h1v-1h-1v1zm0 1v1h1v-1h-1zm-7 0H2v1h1v-1zM3 9V8H2v1h1zm7 0h1V8h-1v1zm0 1v1h1v-1h-1zm-7 0H2v1h1v-1zm0-3h1V5H3v2zm1 0V6H2v1h2zm0-1H3v2h1V6zm5 0H4v2h5V6zm1 0H9v2h1V6zM9 6v1h2V6H9zm0 1h1V5H9v2zM4 7h5V5H4v2zm-1 8h1v-2H3v2zm1 0v-1H2v1h2zm0-1H3v2h1v-2zm12 0H4v2h12v-2zm1 0h-1v2h1v-2zm-1 0v1h2v-1h-2zm0 1h1v-2h-1v2zM4 15h12v-2H4v2zm-1 3h1v-2H3v2zm1 0v-1H2v1h2zm0-1H3v2h1v-2zm12 0H4v2h12v-2zm1 0h-1v2h1v-2zm-1 0v1h2v-1h-2zm0 1h1v-2h-1v2zM4 18h12v-2H4v2zm0 1H3v2h1v-2zm5 0H4v2h5v-2zm1 0H9v2h1v-2zm1 2v-1H9v1h2zm-2 1h1v-2H9v2zm-5 0h5v-2H4v2zm-1 0h1v-2H3v2zm-1-2v1h2v-1H2zM4 8H3v2h1V8zm5 0H4v2h5V8zm1 0H9v2h1V8zm1 2V9H9v1h2zm-2 1h1V9H9v2zm-5 0h5V9H4v2zm-1 0h1V9H3v2zM2 9v1h2V9H2z"
      fill="#707070"
      mask="url(#a)"
    />
    <path d="M12.5.5v5a2 2 0 002 2h5" stroke="#707070" />
  </SVG>
);

export default Document;
