// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @volvo-cars/css/no-custom-class */
import React from 'react';
import SVG from './svg';

const ARIOS: React.FC<React.PropsWithChildren<unknown>> = () => (
  <SVG viewBox="0 0 78 78">
    <defs>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .cls-1{fill:none;}
          .cls-2{clip-path:url(#clip-path);}
          .cls-3{opacity:0.6;}
          .cls-4{clip-path:url(#clip-path-3);}
          .cls-5{opacity:0.5;}
          .cls-6{fill:#fff;}
          .cls-7{opacity:0.2;}
          .cls-8{clip-path:url(#clip-path-5);}
          .cls-9{clip-path:url(#clip-path-6);}
          .cls-10{clip-path:url(#clip-path-7);}
          .cls-11{clip-path:url(#clip-path-8);}
          .cls-12{clip-path:url(#clip-path-9);}
          .cls-13{clip-path:url(#clip-path-10);}
          .cls-14{clip-path:url(#clip-path-11);}
          .cls-15{clip-path:url(#clip-path-12);}
          .cls-16{clip-path:url(#clip-path-13);}`,
        }}
      />
      <clipPath id="clip-path" transform="translate(0 0)">
        <rect className="cls-1" width="78" height="78" />
      </clipPath>
      <clipPath id="clip-path-3" transform="translate(0 0)">
        <rect className="cls-1" width="78" height="78" />
      </clipPath>
      <clipPath id="clip-path-5" transform="translate(0 0)">
        <rect
          className="cls-1"
          x="15.48"
          y="12.49"
          width="23.67"
          height="14.2"
        />
      </clipPath>
      <clipPath id="clip-path-6" transform="translate(0 0)">
        <rect
          className="cls-1"
          x="15.48"
          y="25.41"
          width="46.03"
          height="27.16"
        />
      </clipPath>
      <clipPath id="clip-path-7" transform="translate(0 0)">
        <rect
          className="cls-1"
          x="37.87"
          y="12.49"
          width="23.64"
          height="14.2"
        />
      </clipPath>
      <clipPath id="clip-path-8" transform="translate(0 0)">
        <rect
          className="cls-1"
          x="15.48"
          y="25.41"
          width="46.03"
          height="27.16"
        />
      </clipPath>
      <clipPath id="clip-path-9" transform="translate(0 0)">
        <rect
          className="cls-1"
          x="15.48"
          y="51.28"
          width="23.67"
          height="14.24"
        />
      </clipPath>
      <clipPath id="clip-path-10" transform="translate(0 0)">
        <rect
          className="cls-1"
          x="37.87"
          y="51.28"
          width="23.64"
          height="14.24"
        />
      </clipPath>
      <clipPath id="clip-path-11" transform="translate(0 0)">
        <rect
          className="cls-1"
          x="15.48"
          y="25.41"
          width="1.28"
          height="27.16"
        />
      </clipPath>
      <clipPath id="clip-path-12" transform="translate(0 0)">
        <rect
          className="cls-1"
          x="60.23"
          y="25.41"
          width="1.28"
          height="27.16"
        />
      </clipPath>
      <clipPath id="clip-path-13" transform="translate(0 0)">
        <rect
          className="cls-1"
          x="37.87"
          y="12.49"
          width="1.28"
          height="52.96"
        />
      </clipPath>
    </defs>
    <g className="cls-2">
      <g className="cls-2">
        <g className="cls-3">
          <g className="cls-4">
            <g className="cls-5">
              <g className="cls-4">
                <path
                  className="cls-6"
                  d="M69,78H9a9,9,0,0,1-9-9V9A9,9,0,0,1,9,0H69a9,9,0,0,1,9,9V69a9,9,0,0,1-9,9"
                  transform="translate(0 0)"
                />
              </g>
            </g>
            <path
              d="M45,18a1.18,1.18,0,0,1-.57-.15l-6-3.44-6,3.44A1.11,1.11,0,0,1,31,17.46a1.13,1.13,0,0,1,.41-1.54L38,12.15a1.14,1.14,0,0,1,1.13,0l6.51,3.77A1.13,1.13,0,0,1,45,18"
              transform="translate(0 0)"
            />
            <path
              d="M38.51,47.65a1.12,1.12,0,0,1-1.12-1.12V39a1.13,1.13,0,1,1,2.25,0v7.54a1.13,1.13,0,0,1-1.13,1.12"
              transform="translate(0 0)"
            />
            <path
              d="M16.12,34.71A1.12,1.12,0,0,1,15,33.58V26.05a1.13,1.13,0,1,1,2.25,0v7.53a1.13,1.13,0,0,1-1.13,1.13"
              transform="translate(0 0)"
            />
            <path
              d="M60.88,34.71a1.13,1.13,0,0,1-1.13-1.13V26.05a1.13,1.13,0,1,1,2.25,0v7.53a1.13,1.13,0,0,1-1.12,1.13"
              transform="translate(0 0)"
            />
            <path
              d="M60.88,53.05a1.13,1.13,0,0,1-1.13-1.12V44.39a1.13,1.13,0,0,1,1.12-1.13h0A1.13,1.13,0,0,1,62,44.39v7.53a1.12,1.12,0,0,1-1.12,1.13Z"
              transform="translate(0 0)"
            />
            <path
              d="M16.12,53.05A1.12,1.12,0,0,1,15,51.93V44.39a1.13,1.13,0,1,1,2.25,0v7.54a1.13,1.13,0,0,1-1.13,1.12"
              transform="translate(0 0)"
            />
            <path
              d="M38.51,22.47a1.12,1.12,0,0,1-1.12-1.13V13.13a1.13,1.13,0,1,1,2.25,0v8.21a1.13,1.13,0,0,1-1.13,1.13"
              transform="translate(0 0)"
            />
            <path
              d="M38.51,66a1.12,1.12,0,0,1-1.12-1.12V57.34a1.13,1.13,0,0,1,2.25,0v7.54A1.13,1.13,0,0,1,38.51,66"
              transform="translate(0 0)"
            />
            <path
              d="M38.51,66a1.15,1.15,0,0,1-.56-.15l-6.56-3.79a1.13,1.13,0,1,1,1.13-2l6,3.47,5.92-3.43a1.12,1.12,0,0,1,1.12,2l-6.47,3.75a1.16,1.16,0,0,1-.57.15"
              transform="translate(0 0)"
            />
            <path
              d="M38.51,40.11A1.15,1.15,0,0,1,38,40l-6.53-3.77A1.13,1.13,0,0,1,31,34.65a1.11,1.11,0,0,1,1.53-.41l6,3.45,6-3.45a1.13,1.13,0,1,1,1.13,1.95L39.08,40a1.16,1.16,0,0,1-.57.15"
              transform="translate(0 0)"
            />
            <path
              d="M54.35,30.94a1.13,1.13,0,0,1-.56-2.1l6.52-3.77A1.13,1.13,0,0,1,61.44,27l-6.52,3.77a1.16,1.16,0,0,1-.57.15"
              transform="translate(0 0)"
            />
            <path
              d="M60.87,27.17a1.15,1.15,0,0,1-.56-.15l-6.53-3.77A1.12,1.12,0,0,1,54.9,21.3l6.53,3.77a1.12,1.12,0,0,1-.56,2.1"
              transform="translate(0 0)"
            />
            <path
              d="M22.65,30.94a1.12,1.12,0,0,1-.56-.15L15.56,27a1.12,1.12,0,0,1,1.12-1.95l6.53,3.77a1.13,1.13,0,0,1-.56,2.1"
              transform="translate(0 0)"
            />
            <path
              d="M16.12,27.17a1.13,1.13,0,0,1-.56-2.1l6.52-3.76a1.12,1.12,0,0,1,1.12,2L16.68,27a1.12,1.12,0,0,1-.56.15"
              transform="translate(0 0)"
            />
            <path
              d="M54.34,56.82a1.1,1.1,0,0,1-1-.56,1.13,1.13,0,0,1,.41-1.54L60.32,51a1.12,1.12,0,0,1,1.12,1.95L54.9,56.67a1.15,1.15,0,0,1-.56.15"
              transform="translate(0 0)"
            />
            <path
              d="M60.88,53.05a1.16,1.16,0,0,1-.57-.15l-6.52-3.77a1.12,1.12,0,0,1,1.12-2L61.44,51a1.13,1.13,0,0,1-.56,2.1"
              transform="translate(0 0)"
            />
            <path
              d="M22.65,56.82a1,1,0,0,1-.56-.15L15.56,52.9A1.13,1.13,0,1,1,16.69,51l6.52,3.78a1.12,1.12,0,0,1-.56,2.09"
              transform="translate(0 0)"
            />
            <path
              d="M16.12,53.05a1.13,1.13,0,0,1-.56-2.1l6.51-3.77a1.13,1.13,0,0,1,1.13,2L16.69,52.9a1.16,1.16,0,0,1-.57.15"
              transform="translate(0 0)"
            />
            <g className="cls-7">
              <g className="cls-8">
                <path
                  d="M16.12,26.69a.64.64,0,0,1-.32-1.2L38.19,12.57a.64.64,0,0,1,.88.24.63.63,0,0,1-.24.87L16.44,26.6a.62.62,0,0,1-.32.09"
                  transform="translate(0 0)"
                />
              </g>
            </g>
            <g className="cls-7">
              <g className="cls-9">
                <path
                  d="M16.12,52.57a.64.64,0,0,1-.32-1.2L60.56,25.49a.64.64,0,0,1,.63,1.11L16.44,52.48a.62.62,0,0,1-.32.09"
                  transform="translate(0 0)"
                />
              </g>
            </g>
            <g className="cls-7">
              <g className="cls-10">
                <path
                  d="M60.87,26.69a.59.59,0,0,1-.31-.09L38.19,13.68a.64.64,0,0,1,.64-1.11L61.19,25.49a.64.64,0,0,1,.24.88.66.66,0,0,1-.56.32"
                  transform="translate(0 0)"
                />
              </g>
            </g>
            <g className="cls-7">
              <g className="cls-11">
                <path
                  d="M60.88,52.57a.62.62,0,0,1-.32-.09L15.8,26.6a.64.64,0,0,1,.64-1.11L61.2,51.37a.64.64,0,0,1-.32,1.2"
                  transform="translate(0 0)"
                />
              </g>
            </g>
            <g className="cls-7">
              <g className="cls-12">
                <path
                  d="M38.51,65.52a.62.62,0,0,1-.32-.09l-22.39-13a.65.65,0,0,1-.23-.88.64.64,0,0,1,.87-.23L38.83,64.32a.64.64,0,0,1-.32,1.2"
                  transform="translate(0 0)"
                />
              </g>
            </g>
            <g className="cls-7">
              <g className="cls-13">
                <path
                  d="M38.51,65.52A.63.63,0,0,1,38,65.2a.65.65,0,0,1,.23-.88L60.56,51.37a.64.64,0,0,1,.87.23.65.65,0,0,1-.23.88l-22.37,13a.62.62,0,0,1-.32.09"
                  transform="translate(0 0)"
                />
              </g>
            </g>
            <g className="cls-7">
              <g className="cls-14">
                <path
                  d="M16.12,52.57a.64.64,0,0,1-.64-.64V26.05a.64.64,0,0,1,1.28,0V51.93a.64.64,0,0,1-.64.64"
                  transform="translate(0 0)"
                />
              </g>
            </g>
            <g className="cls-7">
              <g className="cls-15">
                <path
                  d="M60.88,52.57a.64.64,0,0,1-.64-.64V26.05a.64.64,0,0,1,1.28,0V51.93a.64.64,0,0,1-.64.64"
                  transform="translate(0 0)"
                />
              </g>
            </g>
            <g className="cls-7">
              <g className="cls-16">
                <path
                  d="M38.51,65.45a.64.64,0,0,1-.64-.64V13.13a.64.64,0,0,1,1.28,0V64.81a.64.64,0,0,1-.64.64"
                  transform="translate(0 0)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SVG>
);

export default ARIOS;
