import React from 'react';
import SVG from './svg';

const ChargingDcEu: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SVG viewBox="0 0 48 48" fill="none">
      <rect width="47.9999" height="48" fill="none" />
      <path
        d="M15.7385 31.5632H32.4931C36.0613 31.5632 38.9552 34.4571 38.9552 38.0254C38.9552 41.5936 36.0613 44.4875 32.4931 44.4875H15.7385C12.1703 44.4875 9.27637 41.5936 9.27637 38.0254C9.27637 34.4571 12.1703 31.5632 15.7385 31.5632Z"
        stroke="#707070"
      />
      <path
        d="M16.2167 40.9885C17.8753 40.9885 19.2199 39.6439 19.2199 37.9852C19.2199 36.3265 17.8753 34.9819 16.2167 34.9819C14.558 34.9819 13.2134 36.3265 13.2134 37.9852C13.2134 39.6439 14.558 40.9885 16.2167 40.9885Z"
        stroke="#707070"
      />
      <path
        d="M32.0677 40.9882C33.7264 40.9882 35.071 39.6436 35.071 37.985C35.071 36.3263 33.7264 34.9817 32.0677 34.9817C30.4091 34.9817 29.0645 36.3263 29.0645 37.985C29.0645 39.6436 30.4091 40.9882 32.0677 40.9882Z"
        stroke="#707070"
      />
      <path
        d="M20.2178 8.58325C20.9958 8.58325 21.6265 7.95256 21.6265 7.17456C21.6265 6.39656 20.9958 5.76587 20.2178 5.76587C19.4398 5.76587 18.8091 6.39656 18.8091 7.17456C18.8091 7.95256 19.4398 8.58325 20.2178 8.58325Z"
        stroke="#707070"
      />
      <path
        d="M28.2319 8.58325C29.0099 8.58325 29.6406 7.95256 29.6406 7.17456C29.6406 6.39656 29.0099 5.76587 28.2319 5.76587C27.4539 5.76587 26.8232 6.39656 26.8232 7.17456C26.8232 7.95256 27.4539 8.58325 28.2319 8.58325Z"
        stroke="#707070"
      />
      <path
        d="M20.2763 22.8953C21.4876 22.8953 22.4696 21.9134 22.4696 20.7021C22.4696 19.4908 21.4876 18.5088 20.2763 18.5088C19.065 18.5088 18.083 19.4908 18.083 20.7021C18.083 21.9134 19.065 22.8953 20.2763 22.8953Z"
        stroke="#707070"
      />
      <path
        d="M28.2538 22.8953C29.4651 22.8953 30.4471 21.9134 30.4471 20.7021C30.4471 19.4908 29.4651 18.5088 28.2538 18.5088C27.0425 18.5088 26.0605 19.4908 26.0605 20.7021C26.0605 21.9134 27.0425 22.8953 28.2538 22.8953Z"
        stroke="#707070"
      />
      <path
        d="M16.1327 15.6432C17.344 15.6432 18.326 14.6612 18.326 13.4499C18.326 12.2386 17.344 11.2566 16.1327 11.2566C14.9214 11.2566 13.9395 12.2386 13.9395 13.4499C13.9395 14.6612 14.9214 15.6432 16.1327 15.6432Z"
        stroke="#707070"
      />
      <path
        d="M32.2944 15.6432C33.5057 15.6432 34.4876 14.6612 34.4876 13.4499C34.4876 12.2386 33.5057 11.2566 32.2944 11.2566C31.083 11.2566 30.1011 12.2386 30.1011 13.4499C30.1011 14.6612 31.083 15.6432 32.2944 15.6432Z"
        stroke="#707070"
      />
      <path
        d="M24.2133 15.1253C25.4246 15.1253 26.4066 14.1434 26.4066 12.932C26.4066 11.7207 25.4246 10.7388 24.2133 10.7388C23.002 10.7388 22.02 11.7207 22.02 12.932C22.02 14.1434 23.002 15.1253 24.2133 15.1253Z"
        stroke="#707070"
      />
      <path
        d="M16.2051 3.40112V2.90112H16.034L15.8988 3.00593L16.2051 3.40112ZM32.1851 3.40112L32.4914 3.00593L32.3562 2.90112H32.1851V3.40112ZM11.6411 13.725C11.6411 9.68654 13.5475 6.09356 16.5114 3.79632L15.8988 3.00593C12.701 5.48441 10.6411 9.36428 10.6411 13.725H11.6411ZM24.1951 26.279C17.2617 26.279 11.6411 20.6584 11.6411 13.725H10.6411C10.6411 21.2107 16.7094 27.279 24.1951 27.279V26.279ZM36.7491 13.725C36.7491 20.6584 31.1285 26.279 24.1951 26.279V27.279C31.6808 27.279 37.7491 21.2107 37.7491 13.725H36.7491ZM31.8788 3.79632C34.8427 6.09356 36.7491 9.68654 36.7491 13.725H37.7491C37.7491 9.36428 35.6892 5.48441 32.4914 3.00593L31.8788 3.79632ZM32.1851 2.90112H16.2051V3.90112H32.1851V2.90112Z"
        fill="#707070"
      />
      <path
        d="M14.494 1V0.5H14.3229L14.1877 0.604807L14.494 1ZM15.7011 26.9211H16.2011V26.6465L15.9694 26.4992L15.7011 26.9211ZM15.7011 29.2839V29.7839H16.2011V29.2839H15.7011ZM32.692 29.2839H32.192V29.7839H32.692V29.2839ZM32.692 26.9207L32.4236 26.4988L32.192 26.6462V26.9207H32.692ZM33.8985 1L34.2048 0.604807L34.0695 0.5H33.8985V1ZM8.84495 13.5361C8.84495 8.59772 11.1762 4.20412 14.8003 1.39519L14.1877 0.604807C10.3298 3.59498 7.84495 8.27546 7.84495 13.5361H8.84495ZM15.9694 26.4992C11.6855 23.7746 8.84495 18.9869 8.84495 13.5361H7.84495C7.84495 19.3431 10.8724 24.4426 15.4327 27.343L15.9694 26.4992ZM16.2011 29.2839V26.9211H15.2011V29.2839H16.2011ZM15.5446 29.7839H15.7011V28.7839H15.5446V29.7839ZM7.18652 38.142C7.18652 33.526 10.9286 29.7839 15.5446 29.7839V28.7839C10.3763 28.7839 6.18652 32.9737 6.18652 38.142H7.18652ZM15.5446 46.5001C10.9286 46.5001 7.18652 42.758 7.18652 38.142H6.18652C6.18652 43.3103 10.3763 47.5001 15.5446 47.5001V46.5001ZM32.7427 46.5001H15.5446V47.5001H32.7427V46.5001ZM41.1007 38.142C41.1007 42.758 37.3587 46.5001 32.7427 46.5001V47.5001C37.911 47.5001 42.1007 43.3103 42.1007 38.142H41.1007ZM32.7427 29.7839C37.3587 29.7839 41.1007 33.526 41.1007 38.142H42.1007C42.1007 32.9737 37.911 28.7839 32.7427 28.7839V29.7839ZM32.692 29.7839H32.7427V28.7839H32.692V29.7839ZM32.192 26.9207V29.2839H33.192V26.9207H32.192ZM39.5475 13.5361C39.5475 18.9866 36.7072 23.7742 32.4236 26.4988L32.9603 27.3426C37.5203 24.4422 40.5475 19.3428 40.5475 13.5361H39.5475ZM33.5922 1.39519C37.2162 4.20412 39.5475 8.59772 39.5475 13.5361H40.5475C40.5475 8.27546 38.0627 3.59498 34.2048 0.604807L33.5922 1.39519ZM14.494 1.5H33.8985V0.5H14.494V1.5Z"
        fill="#707070"
      />
    </SVG>
  );
};

export default ChargingDcEu;
