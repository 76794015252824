import { getMarketSite } from '@volvo-cars/market-sites';
import { CarPriceProviderProps } from '../car-price.types';
import { getCookie } from '@vcc-www/storage/cookies';

const PRICES_API_ENDPOINT = '/api/order/prices';

export const fetchPrices = async ({
  siteSlug,
  clientName,
  isInStore,
  taxTerritory,
  deployEnv,
  contentSource,
  qawwwHeader,
  testwwwHeader,
}: Pick<
  CarPriceProviderProps,
  | 'siteSlug'
  | 'clientName'
  | 'isInStore'
  | 'taxTerritory'
  | 'deployEnv'
  | 'contentSource'
  | 'testwwwHeader'
  | 'qawwwHeader'
>) => {
  const locale = getMarketSite(siteSlug).locale;
  const sessionId = getCookie('vcc-session') ?? ''; // For client-side testing purposes only
  let baseUrl = '';
  switch (deployEnv) {
    case 'test':
      baseUrl = 'https://testwww.volvocars.com';
      break;
    case 'qa':
      baseUrl = 'https://qawww.volvocars.com';
      break;
    case 'dev':
      baseUrl = 'http://localhost:3000';
      break;
    default:
      baseUrl = 'https://www.volvocars.com';
      break;
  }
  return fetch(`${baseUrl}${PRICES_API_ENDPOINT}`, {
    method: 'POST',
    body: JSON.stringify({
      market: siteSlug,
      locale,
      clientName,
      isInStore,
      ...(taxTerritory ? { taxTerritory } : {}),
      ...(contentSource ? { contentSource } : {}),
      ...(sessionId ? { sessionId } : {}),
    }),
    headers: {
      ...(deployEnv !== 'prod' && (testwwwHeader || qawwwHeader)
        ? {
            testwww: testwwwHeader ?? '',
            qawww: qawwwHeader ?? '',
          }
        : {}),
      'x-client-name': clientName,
    },
  }).then((response) => response.json());
};
