import React from 'react';
import { Text, ExtendCSS, DesignSystemColors } from 'vcc-ui';

type Props = {
  /**
   * VCC-UI text variant
   *
   * default `undefined`
   */
  variant?: 'hillary' | 'columbus' | 'kelly' | 'bates';

  /**
   * VCC-UI text sub style. This only affects prefix and suffix.
   * default `"standard"`
   */
  subStyle?: 'emphasis' | 'standard';

  /**
   * Pricing sub style
   *
   * default `"emphasis"`
   */
  priceSubStyle?: 'emphasis' | 'standard';

  /**
   * Pricing prefix
   *
   * Example: `"From"`
   */
  prefix?: string;

  /**
   * Pricing suffix
   *
   * Example: `"MSRP"`
   */
  suffix?: string;
  extend?: ExtendCSS;
  priceColor?: `foreground.${keyof DesignSystemColors['foreground']}`;
};

const PricingText: React.FC<React.PropsWithChildren<Props>> = ({
  variant,
  subStyle = 'standard',
  priceSubStyle = 'emphasis',
  extend,
  prefix,
  suffix,
  children,
  priceColor,
  ...props
}) => (
  <Text
    variant={variant}
    subStyle={subStyle}
    extend={extend}
    data-testid="pricing-text"
    data-autoid="pricing-text"
    {...props}
  >
    {prefix && `${prefix}${children || suffix ? ' ' : ''}`}
    {children && (
      <Text
        subStyle={priceSubStyle}
        extend={{
          color: priceColor ? priceColor : 'currentColor',
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </Text>
    )}
    {suffix && `${children ? ' ' : ''}${suffix}`}
  </Text>
);

export default PricingText;
